import { Box, Grid } from "@mui/material";

type Props = {
  content: any;
};
const HelpCenterM = (props: Props) => {
  const { content } = props;
  const {
    online_faq_txt,
    online_faq_desc,
    cust_service_txt,
    cust_service_desc,
    call_center_pic,
  } = content || {};
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      sx={{
        alignItems: "center",
        backgroundColor: "var(--white)",
        // height: "394px",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          width: { xs: "90%", md: "60%" },
          gap: "30px",
          marginTop: { xs: "20px", md: "100px" },
        }}
      >
        <Box>
          <Box
            component="img"
            src={call_center_pic?.data?.attributes?.url}
            alt={"call_center"}
          ></Box>
        </Box>
        <Box>
          <Box
            sx={{
              fontSize: "24px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "40px",
              textAlign: "start",
              mb: "10px",
              color: "var(--black)",
            }}
          >
            {online_faq_txt}
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Regular)",
              lineHeight: "28px",
              textAlign: "start",
              mb: "10px",
              color: "var(--text_color)",
            }}
          >
            {online_faq_desc}
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Semibold)",
              lineHeight: "28px",
              textAlign: "start",
              mb: "20px",
              color: "var(--primary_color)",
              cursor: "pointer",
              width: "fit-content",
            }}
            onClick={() =>
              window.open("https://support.flightmobile.com", "_blank")
            }
          >
            {online_faq_txt}
          </Box>
          <Box
            sx={{
              fontSize: "24px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "40px",
              textAlign: "start",
              mb: "10px",
              color: "var(--black)",
            }}
          >
            {cust_service_txt}
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Regular)",
              lineHeight: "28px",
              textAlign: "start",
              color: "var(--text_color)",
            }}
          >
            {cust_service_desc}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default HelpCenterM;
