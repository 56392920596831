import appConfig from "../../../../config/app.config.json";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
const AccountLockModalTemplate = isTemplateExisits(
  appConfig.FCCAccountLock.AccountLockModal
)
  ? require(`./${appConfig.FCCAccountLock.AccountLockModal.template}_AccountLockModal`)
      .default
  : null;

const AccountLockModal = (props: any) => {
  return <AccountLockModalTemplate {...props} />;
};
export default AccountLockModal;
