import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  SelectProps,
  FormHelperText,
  Typography,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import DoneIcon from "@mui/icons-material/Done";
import "./SelectCust.scss";
import { displayPhoneNumberFormat } from "../../../utils/commonFunctions/ReusableFunctions";

export type SelectCustProps = SelectProps & {
  options: any;
  helperText?: any;
  error?: boolean;
  helperTextstyles?: any;
  formStyles?: any;
  selectFieldStyles?: any;
  className?: string;
  secondary?: boolean;
  icons?: any;
  width?: any;
};

export const SelectCust = (props: SelectCustProps) => {
  const {
    label,
    onChange,
    options,
    value,
    disabled,
    variant,
    selectFieldStyles,
    placeholder,
    helperText,
    error,
    formStyles,
    secondary,
    icons,
    width,
  } = props;
  return (
    <FormControl
      error={error}
      sx={{ width: "100%", ...formStyles }}
      className={props?.className + " " + "select_field"}
      style={{ width: width }}
    >
      {!value && (
        <InputLabel shrink={false}>{label ? label : placeholder}</InputLabel>
      )}
      <Select
        name={props?.name}
        disabled={disabled}
        value={value || ""}
        onChange={onChange}
        variant={variant}
        sx={selectFieldStyles}
        error={error}
        IconComponent={icons ? () => icons : ArrowDownIcon}
      >
        <MenuItem
          disabled
          sx={{
            fontFamily: "var(--font_family_Medium)",
            color: "var(--grey_shade)",
          }}
          className="menu_placeholder"
          value=""
        >
          {placeholder}
        </MenuItem>
        {
          !secondary && options?.length > 0
            ? options?.map((item: any) => (
                <MenuItem
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    color: "var(--grey_shade)",
                  }}
                  key={item?.value ? item?.value : item?.code}
                  value={item?.value ? item?.value : item}
                >
                  {item?.label ? item?.label : item?.name}
                </MenuItem>
              ))
            : null
          // <Box textAlign={"center"}>
          //   <CircularProgress sx={{ color: "var(--primary_color)" }} />
          // </Box>
        }
        {secondary &&
          options?.length > 0 &&
          options?.map((item: any) => (
            <MenuItem
              sx={{
                fontFamily: "var(--font_family_Medium)",
                color: "var(--grey_shade)",
              }}
              key={item?.value}
              value={item?.value}
              className={`menu_item_class ${
                value === item?.value && "selected_menu_item"
              }`}
            >
              <div className={"select_option_comp"}>
                <div className={"select_opt_inner"}>
                  <Typography className={"opt_name"}>
                    {item?.label?.name}
                  </Typography>
                  <Typography className="opt_num">
                    {displayPhoneNumberFormat(
                      JSON.stringify(item?.label?.phone)
                    )}
                  </Typography>
                </div>
                {value === item?.value && (
                  <DoneIcon className="selected_menu_img" />
                )}
              </div>
            </MenuItem>
          ))}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
