import { useState } from "react";
import { Divider, Container, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getInTouchApi } from "../../../features/homepage/homepageSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../config/env.config.json";
import { SignUpComponent, LoginComponent, NotificationCust } from "../../";

import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";

type Props = {
  plan: any;
  line: any;
  setUserDetails: any;
};

export const SignUpAndLogin = (props: Props) => {
  const { plan, line, setUserDetails } = props;
  const dispatch = useAppDispatch();
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const [isSignUp, setIsSignUp] = useState(true);

  const commonSocialRedirect = (
    user: any,
    provider: string,
    notification?: string
  ) => {
    let user1 = user;

    notification &&
      NotificationCust({
        message: notification,
        duration: 2000,
        id: "socialLoginNotification",
      });

    let name: string = user1 && user1.displayName;
    let firstName: string =
      name && name.split(" ") && name.split(" ")[0] ? name.split(" ")[0] : "";
    let lastName: string =
      name && name.split(" ") && name.split(" ")[1] ? name.split(" ")[1] : "";
    const hubspotPayload: any = {
      emailId: user1 && user1.email,
      hubspotMap: {
        email: user1 && user1.email,
        is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
        is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
        website_logged_in: true,
        website_login_date: new Date().getTime(),
        website_journey_score: 3,
        firstname: firstName,
        lastname: lastName,
        // brand_name: globalVal?.short_name?.toLowerCase(), //brand name is not being used in hubspot this property is overriding hubsport config.
      },
    };
    isSignUp
      ? dispatch(
          getInTouchApi({
            data: {
              ...hubspotPayload,
              hubspotMap: {
                ...hubspotPayload.hubspotMap,
                login_provider: provider, // (“email, facebook, google, apple”)
                // plan_selected: EnvConfig?.TENANT_ID
                //   ? plan?.displayName
                //   : plan?.name,
                website_number_of_lines: line,
              },
            },
          })
        )
      : dispatch(
          getInTouchApi({
            data: hubspotPayload,
          })
        );

    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.checkout,
      category: gaCategory.swiftPurchase,
      buttonValue:
        provider === "Email"
          ? isSignUp
            ? "Create Account"
            : "Login"
          : provider,
    });
    setUserDetails(user1);
  };

  return (
    <Box>
      {isSignUp ? (
        <SignUpComponent
          isCheckout={true}
          toggleForm={() => setIsSignUp((previousState) => !previousState)}
          commonSocialRedirect={commonSocialRedirect}
        />
      ) : (
        <LoginComponent
          isCheckout={true}
          toggleForm={() => setIsSignUp((previousState) => !previousState)}
          commonSocialRedirect={commonSocialRedirect}
        />
      )}
    </Box>
  );
};
