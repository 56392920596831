import styles from "./SecondaryInfoCard.module.scss";
import { ButtonCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { Box, Grid } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

type initialProps = {
  is_right?: boolean;
  image: any;
  info: string;
  title: string;
  onclick?: any;
  btnTxt?: string;
  btnTxt1?: string;
  maxImgWidth?: string;
  maxImgHeight?: string;
  btnWidth?: string;
  btn1Width?: string;
  subInfo?: string;
  onClick2?: any;
  alt?: string;
  className?: string;
  isSecondSecondary?: boolean;
};

export const SecondaryInfoCard = (props: initialProps) => {
  return (
    <Grid
      container
      sx={{
        px: { sm: 8 },
        py: { sm: 4 },
        display: "flex",
        flexDirection: {
          xs: "row",
          md: props.is_right ? "row-reverse" : "row",
        },
      }}
      className={props.className ? props.className : ""}
      gap={{ xs: 3, md: 3, lg: 0 }}
    >
      <Grid
        item
        container
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid
          item
          container
          xs={12}
          lg={11}
          sx={{
            px: 2,
            py: { xs: 1, md: 2 },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            flexDirection: "column",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Box
            className={styles.infocard_detail_title}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {props.title}
          </Box>
          <Box
            sx={{ textAlign: { xs: "center", md: "start" } }}
            className={styles.infocard_detail_data}
          >
            {BrandNameUpdate(props.info)}
          </Box>
          {props.subInfo && (
            <Box className={styles.infocard_detail_subdata}>
              {props.subInfo}
            </Box>
          )}

          <Box>
            <Box>
              {props.btnTxt && (
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onclick}
                  sx={{ width: "250px" }}
                >
                  {props.btnTxt}
                </ButtonCust>
              )}
            </Box>
            <Box>
              {props.btnTxt1 && (
                <ButtonCust
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={props.onClick2}
                  sx={{ width: { xs: "250px !important" } }}
                >
                  {props.btnTxt1}
                </ButtonCust>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { md: "0px 57px" },
        }}
      >
        <img
          src={props.image}
          style={{
            maxHeight: props.maxImgHeight ? props.maxImgHeight : "",
            maxWidth: props.maxImgWidth ? props.maxImgWidth : "",
          }}
          className={styles.img_container}
          alt={props.alt ? BrandNameUpdate(props.alt) : ""}
        />
      </Grid>
    </Grid>
  );
};
