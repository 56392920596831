import { Typography, Box } from "@mui/material";
import { useState } from "react";
import styles from "./SelectCardDialog.module.scss";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getBillingData,
  manageCardsData,
} from "../../features/billing/billingSlice";
import { useNonInitialEffect } from "../../utils/commonFunctions/UseNonInitialEffectHook";
import {
  NotificationCust,
  ButtonCust,
  SimpleModal,
  SwitchCust,
} from "../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EnvConfig from "../../config/env.config.json";
import { RoutingURLs } from "../../config/RoutingURLs";
import { getPurchaseHistory } from "../../features/billing/billingSlice";
import { CardTypes } from "../../enums";
import Config from "../../config/env.config.json";
import { autoTopUp } from "../../features/account/accountSlice";
import { GetCustomer } from "../../features/checkout/checkoutSlice";

type dialogProps = {
  handleClose: any;
  open: boolean;
  dialogData?: any;
  cardHeading?: any;
  icon?: any;
  cardData?: any;
  payConfirmHandler?: any;
  ProceedPayment?: any;
  setShowCardChangeModal?: any;
  pageName?: string;
  backHandler: any;
  handleDisplay?: any;
  selectCardDialogContent?: any;
  isAutoPay?: boolean;
  payType?:boolean;
};

export const SelectCardDialog = (props: dialogProps) => {
  const [autoBillPay, setAutoBillPay] = useState(false);
  const {
    handleClose,
    open,
    cardHeading,
    icon,
    cardData,
    dialogData,
    payConfirmHandler,
    ProceedPayment,
    setShowCardChangeModal,
    pageName,
    backHandler,
    handleDisplay,
    selectCardDialogContent,
    isAutoPay,
    payType
  } = props;
  const dispatch = useAppDispatch();
  const {
    planChange,
    errorPlanChange,
    planChangeLoader,
    customerTopUpLoader,
    customerTopUp,
    errorCustomerTopUp,
    addLineLoader,
  } = useAppSelector((state: any) => state.account || {});
  const {
    creditCardData,
    updateAutoPayCard,
    errorUpdateAutoPayCard,
    removeCard,
    errorRemoveCard,
    billAutoPay,
    errorBillAutoPay,
  } = useAppSelector((state: any) => state.billing);
  let globalVal: any = localStorage.getItem("globalValues");
  globalVal = JSON.parse(globalVal);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);
  const [toggle, settoggle] = useState(true);
  const [position, setposition] = useState();
  const handleConfirm = () => {
    setAutoBillPay(true);
    if (payConfirmHandler) {
      if (cardData.isAutoPay || !payType) {
        ProceedPayment && ProceedPayment();
      } else {
        dispatch(
          manageCardsData({
            ...creditCardData,
            ccuuid: cardData?.uuid,
            isLoading: true,
            setAsDefault: true,
          })
        );
      }
    } else {
      if (
        EnvConfig.IS_AUTOPAY_ENABLED &&
        cardHeading === dialogData?.set_default
      ) {
        handleDisplay(toggle);
      } else {
        const payload = {
          customerId :currentdetails.id
        }
        if(isAutoPay && cardHeading === dialogData?.set_default){
          dispatch(autoTopUp(payload))
        }
        dispatch(
          manageCardsData({
            ...creditCardData,
            ccuuid: cardData?.uuid,
            isLoading: true,
          })
        );
      }
    }
  };

  const { card_fail_msg, card_dlt_msg, suc_pay_msg, default_crd, cnfrm_btn } =
    selectCardDialogContent || {};

  let card_delete_msg = card_dlt_msg?.replace(
    "{ccNumberMask}",
    cardData?.ccNumberMask
  );
  let default_card_msg = default_crd?.replace(
    "{ccNumberMask}",
    cardData?.ccNumberMask
  );
  useNonInitialEffect(() => {
    // handleClose();
    if (
      errorUpdateAutoPayCard?.status === "FAILURE" &&
      creditCardData?.setAsDefault
    ) {
      NotificationCust({
        message: errorUpdateAutoPayCard?.message,
        type: "danger",
        duration: 5000,
        id: "UpdateAutoPayCardFailure",
        showIcon: true,
      });
    } else if (updateAutoPayCard && creditCardData?.setAsDefault) {
      if (payConfirmHandler && ProceedPayment) {
        ProceedPayment();
      } else {
        NotificationCust({
          message: default_card_msg,
          type: "info",
          duration: 5000,
          id: "UpdateAutoPayCardSuccess",
          showIcon: true,
        });
      }
    } else if (
      errorRemoveCard?.status === "FAILURE" &&
      creditCardData?.removeCreditCard
    ) {
      NotificationCust({
        message: errorRemoveCard?.message,
        type: "danger",
        duration: 5000,
        id: "RemoveCardFailure",
        showIcon: true,
      });
    } else if (
      removeCard?.data?.status === "SUCCESS" &&
      creditCardData?.removeCreditCard
    ) {
      NotificationCust({
        message: card_delete_msg,
        type: "info",
        duration: 5000,
        id: "RemoveCardSuccess",
        showIcon: true,
      });
    }
    if (billAutoPay?.status === "SUCCESS") {
      setAutoBillPay(false);
      const customerObj = JSON.parse(
        localStorage?.getItem("customerDetail") || "null"
      );
      let startDate = new Date(customerObj?.createdDate).toLocaleDateString(
        "fr-CA"
      );
      let endDate = new Date().toLocaleDateString("fr-CA");
      dispatch(
        getPurchaseHistory({ customerId: customerObj?.id, startDate, endDate })
      );
      dispatch(getBillingData({ customerId: customerObj?.id }));
      dispatch(GetCustomer({ username: encodeURIComponent(customerObj?.emailId)}));
      NotificationCust({
        message: suc_pay_msg,
        type: "info",
        duration: 5000,
        id: "BillAutoPaySuccess",
        showIcon: true,
      });
    } else if (billAutoPay?.status === "FAILURE") {
      setAutoBillPay(false);
      NotificationCust({
        message: card_fail_msg,
        type: "danger",
        duration: 5000,
        id: "BillAutoPayFailure",
        showIcon: true,
      });
    }

    dispatch(
      manageCardsData({
        ...creditCardData,
        ccuuid: null,
        ...(!payConfirmHandler && { isLoading: false }),
        removeCreditCard: false,
        setAsDefault: false,
      })
    );
    !payConfirmHandler && pageName !== "addlines" && handleClose();
    payConfirmHandler && pageName === "billing" && handleClose();
    !payConfirmHandler &&
      pageName === "addlines" &&
      addLineLoader === false &&
      handleClose();
  }, [
    updateAutoPayCard,
    errorUpdateAutoPayCard,
    removeCard,
    errorRemoveCard,
    addLineLoader,
    billAutoPay,
    errorBillAutoPay,
  ]);
  const handleToggle = (i?: any) => {
    i !== 1 && settoggle(!toggle);

    setposition(i);
  };
  useNonInitialEffect(() => {
    pageName !== "addlines" && handleClose();
    pageName === "addlines" && addLineLoader === false && handleClose();

    dispatch(
      manageCardsData({
        ...creditCardData,
        isLoading: false,
      })
    );
  }, [
    planChange,
    errorPlanChange,
    customerTopUp,
    errorCustomerTopUp,
    addLineLoader,
  ]);

  return (
    <>
      {open && (
        <SimpleModal
          isShowModal={open}
          onCloseModal={() => {
            handleClose();
            payConfirmHandler && setShowCardChangeModal(true);
          }}
          showClose={pageName === "addlines" ? false : true}
          style={{ borderRadius: "0.3rem" }}
          sx={{
            width: { xs: "95%", sm: "500px", lg: "550px" },
            borderRadius: "4.8px",
          }}
          icon={icon ? icon : null}
        >
          {pageName === "addlines" && (
            <ArrowBackIcon
              className={styles.arrow_icon}
              onClick={() => backHandler()}
            />
          )}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            paddingBottom="15px"
          >
            {cardHeading && (
              <Typography className={styles.heading}>{cardHeading}</Typography>
            )}
            <img
              src={
                cardData?.type?.toUpperCase() === CardTypes?.VISA
                  ? dialogData?.visa?.data?.attributes?.url
                  : cardData?.type?.toUpperCase() === CardTypes?.MASTERCARD
                  ? dialogData?.master_card?.data?.attributes?.url
                  : cardData?.type?.toUpperCase() === CardTypes?.JCB
                  ? dialogData?.jcb?.data?.attributes?.url
                  : cardData?.type?.toUpperCase() === CardTypes?.AMERICANEXPRESS
                  ? dialogData?.american_express?.data?.attributes?.url
                  : dialogData?.discover?.data?.attributes?.url
              }
              className={styles.visa_img}
            />
            <Typography className={styles.card_name}>
              {cardData.type?.toUpperCase()}
            </Typography>
            <Typography className={styles.card_number}>
              {dialogData?.num_mask}{cardData.ccNumberMask}
            </Typography>
            <Typography className={styles.expiry}>
              {cardData.nameOnCard} | {dialogData?.exp_date}
              {cardData.dateOfExpiry.slice(0, 2)}/
              {cardData.dateOfExpiry.slice(2)}
            </Typography>
            {EnvConfig.IS_AUTOPAY_ENABLED &&
            cardHeading === dialogData?.set_default ? (
              <Box>
                <Typography
                  pt="20px"
                  mb="10px"
                  sx={{
                    fontFamily: "var(--font_family_Medium)",
                    color: "var(--text_color)",
                    fontSize: "15px",
                    fontWeight: "var(--font_weight_2)",
                  }}
                >
                  {dialogData?.save_my_card}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  {dialogData.autopay_tog.map((each: any, i: any) => (
                    <Box display="flex" alignItems="center" sx={{ mb: "15px" }}>
                      <SwitchCust
                        checked={i === 1 || 0 ? true : toggle}
                        handleSwitchToggle={() => handleToggle(i)}
                      />
                      <Typography
                        sx={{
                          cursor: "Cursor",
                          fontSize: "14px",
                          color: "var(--text_color)",
                          fontFamily: "var(--font_family_Medium)",
                        }}
                      >
                        {each.text} &nbsp;
                        <span
                          onClick={() =>
                            window.open(RoutingURLs.terms, "_blank")
                          }
                          style={{
                            color: "var(--primary_brand_color)",
                            fontSize: "14px",
                            cursor: "pointer",
                          }}
                        >
                          {each?.span_text}
                        </span>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}
            <ButtonCust
              onClick={handleConfirm}
              loading={
                creditCardData?.isLoading ||
                planChangeLoader ||
                customerTopUpLoader ||
                addLineLoader ||
                autoBillPay
              }
              variantType={Config.PRIMARY_BUTTON_TYPE}
            >
              {cnfrm_btn}
            </ButtonCust>
          </Box>
        </SimpleModal>
      )}
    </>
  );
};
