import { Box, ClickAwayListener } from "@mui/material";

interface PopperToolTipProps {
  text: string;
  onClose?: any;
  sx?: any;
  className?: any;
}
export const PopperToolTip = (props: PopperToolTipProps) => {
  const { text, onClose, sx, className } = props;
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        component="a"
        sx={{
          color: "var(--primary_brand_color)",
          fontFamily: "var(--font_family_Medium)",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: "var(--font_weight_2)",
          ...sx,
        }}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        className={className}
      />
    </ClickAwayListener>
  );
};
