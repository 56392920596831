import { Box, Stack, Typography, makeStyles } from "@mui/material";
import { SupportTempProps } from "./Support";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";

const SupportComponent = (props: SupportTempProps) => {
  const { content } = props.content;
  const navigate = useNavigate();

  return (
    <Stack sx={{ background: "var(--bright-grey)" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: { xs: "center", md: "center" },
          width: "70%",
          margin: "0 auto",
          p: "35px",
          gap: { xs: "20px", md: "unset" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          component={"span"}
          sx={{
            fontFamily: "var(--font_family_Bold)",
            color: "var(--title-color_1)",
            fontSize: "18px",
          }}
        >
          {content.title}
        </Typography>
        <Typography
          component={"span"}
          sx={{ display: "flex", gap: { xs: "25px", sm: "60px" } }}
        >
          <Typography
            component={"span"}
            sx={{
              fontFamily: {
                xs: "var(--font_family_Medium)",
                md: "var(--font_family_Semibold)",
              },
              color: "var(--title-color_1)",
              fontSize: { xs: "14px", sm: "18px" },
            }}
          >
            {content.support}
          </Typography>
          <Typography
            component={"span"}
            sx={{
              fontFamily: {
                xs: "var(--font_family_Medium)",
                md: "var(--font_family_Semibold)",
              },
              color: "var(--title-color_1)",
              fontSize: { xs: "14px", sm: "18px" },
            }}
          >
            {content.contact}
          </Typography>
        </Typography>
        <ButtonCust
          sx={{ width: { xs: "250px", md: "200px" } }}
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
          onClick={() => navigate(RoutingURLs.help)}
        >
          {content.button}
        </ButtonCust>
      </Box>
    </Stack>
  );
};

export default SupportComponent;
