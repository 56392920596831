import { Box, Stack, Typography } from "@mui/material";
import styles from "./S1_FAQ.module.scss";
import { FAQTemplateProps } from "./FAQ";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const FAQComponent = (props: FAQTemplateProps) => {
  const { FAQ_title, QandA } = props.content || {};
  const [showAnswer, setShowAnswer] = useState<any>();

  const handleAnswer = (i: any) => {
    if (showAnswer === i) return setShowAnswer(null);
    return setShowAnswer(i);
  };

  return (
    <Stack
      sx={{
        backgroundColor: "var(--faq-background)",
        flexWrap: "wrap",
        alignItems: "center",
        py: "30px",
      }}
    >
      <Box
        sx={{
          width: { xs: "80%", md: "60%" },
        }}
      >
        <Typography
          sx={{
            color: "var(--faq-title)",
            fontSize: { xs: "20px", md: "30px" },
            fontFamily: "var(--font_family_Bold)",
            textAlign: "center",
            py: "30px",
          }}
        >
          {FAQ_title}
        </Typography>
        {QandA?.map((item: any, i: any) => {
          return (
            <Box key={item.Q} sx={{ minHeight: "65px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleAnswer(i);
                }}
              >
                <Typography
                  sx={{
                    color: "var(--faq-question)",
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: { xs: "14px", md: "18px" },
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.Q}
                </Typography>
                {i !== showAnswer && (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "var(--primary_color)",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  />
                )}
                {i === showAnswer && (
                  <KeyboardArrowUpIcon
                    sx={{
                      color: "var(--primary_color)",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Box>
              {i === showAnswer && (
                <Box
                  sx={{
                    background: "var(--faq-answer-bg)",
                    p: { xs: "10px", sm: "45px" },
                    my: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--faq-answer)",
                      fontFamily: "var(--font_family_Regular) !important",
                      fontWeight: "var(--font_weight_0) !important",
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item.A,
                    }}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export default FAQComponent;
