import { isTemplateExisits } from "../../../../utils/commonFunctions";
import Config from "../../../../config/app.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import { gaEventTypes, gaScreenNames } from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import {
  activateLine,
  monthInAdvance,
  monthInAdvancePost,
  SIMIdCheck,
} from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useFormik } from "formik";
import { NotificationCust } from "../../../widgets";
import { getCC } from "../../../../features/account/accountSlice";
import { Store } from "react-notifications-component";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import {
  GetCustomer,
  resetGetCustomerInRedux,
  updateSecondaryUser,
} from "../../../../features/checkout/checkoutSlice";

const Step3Component = isTemplateExisits(Config.Activation.ActivationFlow)
  ? require(`./${Config.Activation.ActivationStep3.template}_ActivationStep3`)
      .default
  : null;

export const ActivationStep3 = (props: any) => {
  const {
    handleStep,
    gaHandler,
    currentLine,
    updatingCustomer,
    numChangeHandler,
    content,
    modalContent,
    step4Content,
    setPaymentDetails,
  } = props;

  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [showContent, setShowContent] = useState("main");
  const [payBtnClicked, setPayBtnClicked] = useState(false);
  const [payBtnLoader, setPayBtnLoader] = useState(false);
  const [payNowFailedModal, setPayNowFailedModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [showEsimPaySuccess, setShowEsimPaySuccess] = useState(false);
  const [actBtnLoader, setActBtnLoader] = useState(false);
  const [activatedUser, setActivatedUser] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState<boolean>(false);

  const [isRefreshCustomerTriggered, setIsRefreshCustomerTriggered] =
    useState(false);
  const paymentStussFg =
    Config.Activation.ActivationStep3.template === "T2" ? true : false;
  const {
    simIdCheckData,
    errorMonthInAdvance,
    monthInAdvancePostData,
    errorMonthInAdvancePostData,
  } = useAppSelector((state: any) => state?.activation || {});
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { msgs } = content;
  const { isValid } = simIdCheckData || {};
  const simID = localStorage.getItem("simId");
  interface formikintitaltypes {
    simId: string;
  }
  const initialformstep1data: formikintitaltypes = {
    simId: simID || currentLine?.simId || "",
  };
  const { esim, id, reachPlanId, imei, make, model, isMNP, mnpInfo, simId } =
    currentLine || {};
  const { operator, oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  const activationStep3Schema = object({
    simId: string()
      .trim()
      .required(msgs?.empty_msg)
      .min(EnvConfig.MAX_ALLOWED_SIM_NUMBER_LENGTH, msgs?.valid_msg),
  });
  const navigate = useNavigate();
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: initialformstep1data,
    validationSchema: activationStep3Schema,
    onSubmit: (values: any) => {
      setBtnLoader(true);
      setBtnClicked(true);
      const py: any = {
        customerId: currentLine?.id,
        iccid: values.simId,
      };
      localStorage.setItem("simId", values.simId);

      gaHandler("SIM ID check");
      dispatch(SIMIdCheck({ payload: py })).then((res) => {
        setBtnLoader(false);
        if (res?.payload?.data?.data?.isValid) {
          window.scroll(0, 0);
          if (!currentLine?.isMonthInAdvancePaid) {
            setShowContent("pay");
            setPaymentDetails(4);
          } else {
            handleStep(true);
            setPaymentDetails(5);
          }
        } else {
          setShowContent("main");
        }
      });
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (simIdCheckData) {
      if (isValid && btnClicked) {
        setBtnLoader(false);
        setBtnClicked(false);
        NotificationCust({
          message: msgs?.simId_noti1,
          type: "success",
          duration: 5000,
          id: "activationSimCardSuccess",
          showIcon: true,
        });
        const py: any = {
          customerId: currentLine?.id,
        };
        dispatch(monthInAdvance({ payload: py }));
        const customerStoreData: any = JSON.parse(
          localStorage?.getItem("customerDetail") || "null"
        );
        if (customerStoreData)
          dispatch(getCC({ customerId: customerStoreData?.id }));
      }
      if (!isValid && btnClicked) {
        setBtnLoader(false);
        setBtnClicked(false);
        NotificationCust({
          message: msgs?.simId_noti2,
          type: "danger",
          duration: 2000,
          id: "activationSimCardInvalid",
          showIcon: true,
        });
      }
    }
    return () => {
      Store.removeNotification("activationSimCardSuccess");
      Store.removeNotification("activationSimCardInvalid");
    };
  }, [simIdCheckData, isValid, currentLine]);
  useEffect(() => {
    const { esim, isSimInHand } = currentLine || {};
    if (esim || isSimInHand) {
      setShowContent("pay");
    } else {
      setShowContent("main");
    }
  }, []);
  useEffect(() => {
    let timer: any;
    if (isPaymentDone) {
      timer = setInterval(() => {
        refreshCustomer();
      }, 5000);
    }
    if (paymentFailed) {
      clearInterval(timer);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isPaymentDone, showContent]);
  const refreshCustomer = () => {
    dispatch(
      GetCustomer({
        username: encodeURIComponent(currentLine?.emailId),
      })
    ).then((response) => {
      if (response?.payload) {
        setIsRefreshCustomerTriggered(true);
      }
    });
  };
  useEffect(() => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    if (addCardActivation) {
      if (currentLine?.zipcode) {
        setShowContent("pay");
      } else {
        setShowContent("main");
      }
    }
  }, []);

  const payNowConfirm = () => {
    setPayBtnLoader(true);
    setPayBtnClicked(true);
    const py: any = {
      customerId: currentLine?.id,
    };
    // if (paymentStussFg) {
    //   setShowContent("paymentStatus");
    // }
    dispatch(resetGetCustomerInRedux());
    dispatch(
      monthInAdvancePost({
        payload: py,
      })
    ).then((res: any) => {
      refreshCustomer();
      if (res?.payload?.status === 200) {
        setPaymentFailed(false);
        setIsPaymentDone(true);
      } else if (esim && res?.payload?.resCode === 40020) {
        if (EnvConfig.ESIM_STEP4SETUP_REDIRECT) {
          // updatingCustomer({ isMonthInAdvancePaid: true });
          handleStep(true);
        } else if (!EnvConfig.ESIM_STEP4SETUP_REDIRECT) {
          activateUser();
        } else {
          setShowEsimPaySuccess(true);
          setPayBtnLoader(false);
        }
      } else if (res?.payload?.resCode === 40020) {
      } else {
        setPayBtnLoader(false);
        setPayNowFailedModal(true);
        setPaymentSuccess(false);
      }
    });
  };
  const activateUser = () => {
    const mnpPy = {
      esim: esim,
      customerId: id,
      reachPlanId: reachPlanId,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
    };
    const skipHoldVal =
      EnvConfig.IS_ACT_SKIP_HOLD_VALUE && isMNP ? true : false;

    const newNumPy: any = {
      esim: esim,
      customerId: id,
      reachPlanId: reachPlanId,
      iccId: simID || simId,
      imei: imei,
      make: make,
      model: model,
      skipHold: true,
      isPort: false,
    };
    const activationPortInNumberPayload = {
      ...newNumPy,
      skipHold: skipHoldVal,
      isPort: true,
      numberToPort: oldNumber,
      oldCarrier: operator,
      oldCarrierAccountNumber: acctNum,
      password: oldPassword,
      oldZip: oldZip,
      portUpdate: false,
    };
    setActBtnLoader(true);
    dispatch(
      activateLine({
        payload: isMNP
          ? activationPortInNumberPayload
          : esim
          ? mnpPy
          : newNumPy,
      })
    )
      .then((res: any) => {
        setPayBtnLoader(false);
        setActBtnLoader(false);
        if (res?.payload?.status === 200) {
          setShowEsimPaySuccess(false);
          navigate(RoutingURLs.postActivationHome);
        } else {
          setShowEsimPaySuccess(false);
          setShowModal(true);
        }
      })
      .catch((error) => {
        if (error) {
          setActBtnLoader(false);
          setShowModal(true);
        }
      });
  };

  useEffect(() => {
    if (
      (monthInAdvancePostData &&
        payBtnClicked &&
        !esim &&
        isPaymentDone &&
        getCustomer &&
        !getCustomer?.operationsInProcess.includes("MIA_IN_PROCESS")) ||
      (payBtnClicked &&
        !esim &&
        isPaymentDone &&
        getCustomer &&
        !getCustomer?.operationsInProcess.includes("MIA_IN_PROCESS"))
    ) {
      if (getCustomer?.isMonthInAdvancePaid) {
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        if (paymentStussFg) {
          setShowContent("paymentStatus");
          setPaymentSuccess(true);
        } else if (!EnvConfig.ESIM_STEP4SETUP_REDIRECT) {
          activateUser();
        } else {
          handleStep(true);
        }
        // activation api if esim true
        if (esim && !EnvConfig.ESIM_STEP4SETUP_REDIRECT) {
          activateUser();
        }
      } else {
        setShowContent("paymentFail");
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        setPayNowFailedModal(true);
        setPaymentSuccess(false);
        setIsPaymentDone(false);
        setPaymentFailed(true);
      }
    } else if (
      (monthInAdvancePostData &&
        payBtnClicked &&
        esim &&
        (EnvConfig.ESIM_STEP4SETUP_REDIRECT || isPaymentDone) &&
        getCustomer &&
        !getCustomer?.operationsInProcess.includes("MIA_IN_PROCESS")) ||
      (payBtnClicked &&
        esim &&
        (isPaymentDone || EnvConfig.ESIM_STEP4SETUP_REDIRECT) &&
        getCustomer &&
        !getCustomer?.operationsInProcess.includes("MIA_IN_PROCESS"))
    ) {
      if (getCustomer?.isMonthInAdvancePaid) {
        // setPayBtnLoader(false);
        setPayBtnClicked(false);
        if (paymentStussFg) {
          setShowContent("paymentStatus");
          setPaymentSuccess(true);
        } else {
          if (!EnvConfig.ESIM_STEP4SETUP_REDIRECT) {
            activateUser();
          } else {
            handleStep(true);
          }
        }
      } else {
        setShowContent("paymentFail");
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        setPayNowFailedModal(true);
        setPaymentSuccess(false);
        setIsPaymentDone(false);
        setPaymentFailed(true);
      }
    }
    if (
      errorMonthInAdvancePostData &&
      errorMonthInAdvancePostData.status === "FAILURE" &&
      payBtnClicked &&
      !esim
    ) {
      if (errorMonthInAdvancePostData.resCode === 40020) {
        setPayNowFailedModal(false);
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        if (paymentStussFg) {
          setPaymentSuccess(false);
        } else {
          handleStep(true);
        }
      } else {
        setPayBtnLoader(false);
        setPayBtnClicked(false);
        setPayNowFailedModal(true);
        setPaymentSuccess(false);
      }
    }
  }, [monthInAdvancePostData, getCustomer, errorMonthInAdvancePostData]);
  const payNowFailed = () => {
    setPayNowFailedModal(false);
  };
  useEffect(() => {
    if (errorMonthInAdvance && errorMonthInAdvance.status === "FAILURE") {
      setShowContent("main");
      NotificationCust({
        message: msgs?.fail_noti,
        type: "danger",
        duration: 2000,
        id: "activationDefaultError",
        showIcon: true,
      });
    }
  }, [errorMonthInAdvance]);
  const handleBack = () => {
    const { esim, isSimInHand } = currentLine || {};
    if (
      (showContent === "pay" && !esim && !isSimInHand) ||
      showContent === "paymentStatus"
    ) {
      setShowContent("main");
      setPaymentDetails(3);
    } else {
      handleStep(false);
    }
  };
  const backToActivation = () => {
    setShowContent("main");
  };

  const onCloseEsimPaySuccess = () => {
    setShowEsimPaySuccess(false);
  };
  return (
    <Step3Component
      currentLine={currentLine}
      content={content}
      modalContent={modalContent}
      handleStep={handleStep}
      step4Content={step4Content}
      gaHandler={gaHandler}
      updatingCustomer={updatingCustomer}
      GAAndFBEventsHandler={GAAndFBEventsHandler}
      numChangeHandler={numChangeHandler}
      formik={formik}
      loader={btnLoader}
      btnClicked={btnClicked}
      displayContent={showContent}
      payNowConfirm={payNowConfirm}
      payLoader={payBtnLoader}
      showActModal={showModal}
      payNowFailModal={payNowFailedModal}
      payNowFailedModal={payNowFailed}
      handleBack={handleBack}
      paymentSuccess={paymentSuccess}
      backToActivation={backToActivation}
      openEsimPaySuccess={showEsimPaySuccess}
      onCloseEsimPaySuccess={onCloseEsimPaySuccess}
      activateUser={activateUser}
      actBtnLoader={actBtnLoader}
      setPayBtnLoader={setPayBtnLoader}
      {...props}
    />
  );
};
