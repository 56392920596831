import styles from "./S1_CompareGraph.module.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { ComparePlanGraph } from "../../widgets";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

export const CompareGraph = (props: any) => {
  let { carrierCost, reachCost, data, pageHandler } = props;
  return (
    <Box>
      <Box
        sx={{ m: { xs: "0 15px", md: "40px 0 0 0px" } }}
        className={styles.main_container}
      >
        <Box className={styles.sub_main_container}>
          <Box sx={{ display: "flex" }}>
            <Box
              className={styles.back_button}
              onClick={() => {
                pageHandler();
              }}
              sx={{
                display: { sm: "flex" },
                justifyContent: { sx: "center" },
                alignItems: { sm: "center" },
              }}
            >
              <ArrowBackIosIcon className={styles.arrow_icon} />
              <Typography
                component={"span"}
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                {data?.back_text}
              </Typography>
            </Box>
            <Box
              className={clsx(styles.title_main, styles.title_common)}
              sx={{ width: "80%", m: "0 auto" }}
            >
              {data?.title1}
              <span>
                {" "}
                $
                {reachCost && carrierCost
                  ? Math.ceil(carrierCost - reachCost) * 12
                  : 0}{" "}
              </span>
              {BrandNameUpdate(data?.title2)}
            </Box>
          </Box>
          <Box className={clsx(styles.sub_title_main, styles.title_common)}>
            {data?.sub_title1}
            <span>
              {" "}
              $
              {reachCost && carrierCost
                ? Math.ceil(carrierCost - reachCost)
                : 0}{" "}
            </span>
            {data?.sub_title2}
          </Box>
          <ComparePlanGraph
            carrierCost={parseInt(carrierCost)}
            reachCost={parseInt(reachCost)}
            data={data}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompareGraph;
