import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import { ButtonCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import {
  Box,
  Grid,
  TextField,
  Theme,
  ThemeProvider,
  createTheme,
  outlinedInputClasses,
  useTheme,
} from "@mui/material";
import { LeadGenProps } from "./LeadGeneration";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-input": {
              color: "var(--text_color)",
            },
            "& .MuiInputBase-root": {
              color: "var(--text_field_text)",
              background: "var(--white)",
            },
            "& .MuiInputLabel-root": {
              color: "var(--text_field_text)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--primary_brand_color)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--primary_brand_color)",
              border: "1px solid var(--primary_brand_color)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--primary_brand_color)",
            },
          },
        },
      },
    },
  });

const LeadGenerationComponent = (props: LeadGenProps) => {
  const outerTheme = useTheme();
  const {
    bg_web,
    bg_mweb,
    heading,
    sub_heading,
    place_holder,
    btn_txt,
    success_text,
    intouch_text,
  } = props.leadContent || {};

  return (
    <Box
      sx={{
        py: "20px",
        background: {
          xs: `url(${bg_mweb?.data?.attributes?.url})`,
          sm: `url(${bg_web?.data?.attributes?.url})`,
        },
        backgroundSize: "cover !important",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "unset" },
          py: { xs: "", md: 8.75 },
          px: { md: "40px" },
        }}
      >
        <Box
          data-testid="title"
          sx={{
            fontSize: { xs: "20px", md: "30px" },
            fontFamily: "var(--font_family_Bold)",
            lineHeight: "50px",
            color: "var(--white)",
            mb: { xs: "4px", md: "30px" },
          }}
        >
          {BrandNameUpdate(heading)}
        </Box>
        <Box
          sx={{
            fontSize: { xs: "14px", md: "16px" },
            fontFamily: "var(--font_family_Regular)",
            color: "var(--white)",
            mb: { xs: "10px", md: "30px" },
            textAlign: { xs: "center", md: "left" },
            width: { xs: "80%", md: "100%" },
          }}
        >
          {BrandNameUpdate(sub_heading)}
        </Box>
        {props.emailSharedCoverage ? (
          <>
            <Grid
              container
              xs={11}
              sm={6}
              md={4.5}
              lg={3.8}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ fontSize: "18px", color: "var(--white)" }}>
                  {success_text}
                </Box>
              </Box>
              <Box
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "var(--white)",
                }}
              >
                {intouch_text}
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "unset" },
              flexDirection: "row",
              mb: { xs: "10px", md: "30px" },
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                props.submitHandler(e);
              }
            }}
          >
            <Grid
              sx={{
                mb: { xs: 2, md: 0 },
                width: { xs: "70%", sm: "442px" },
              }}
            >
              <ThemeProvider theme={customTheme(outerTheme)}>
                <TextField
                  InputProps={{
                    sx: {
                      borderRadius: "30px 0px 0px 30px",
                      height: { xs: "45px", md: "unset" },
                      paddingLeft: "15px",
                    },
                  }}
                  variant="outlined"
                  value={props.email}
                  placeholder={place_holder}
                  onChange={props.emailHandler}
                  sx={{
                    input: {
                      color: "var(--text_color) !important",
                      fontFamily: "var(--font_family_Regular)",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    width: "100%",
                    borderRadius: "30px 0px 0px 30px",
                    color: "white",
                  }}
                  error={
                    (props.email.length >= 1 && !validateEmail(props.email)) ||
                    (props.submitBtnClick && !props.email) ||
                    (props.submitBtnClick &&
                      props.email !== "" &&
                      !validateEmail(props.email))
                  }
                  helperText={
                    (props.email.length >= 1 || props.submitBtnClick) &&
                    props.helperTextHandler()
                  }
                />
              </ThemeProvider>
            </Grid>
            <Grid>
              <ButtonCust
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                onClick={(e: any) => props.submitHandler(e)}
                loading={props.submitClick}
                sx={{
                  py: { xs: "15px", md: "27px" },
                  borderRadius: "0 30px 30px 0 !important",
                  minWidth: { xs: "50px !important", md: "200px !important" },
                }}
              >
                {btn_txt}
              </ButtonCust>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default LeadGenerationComponent;
