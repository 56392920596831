import { Box, Container, Typography } from "@mui/material";
import { AutoComplete, ButtonCust, SimpleModal } from "../../widgets";
import styles from "./S1_EsimCoverageModal.module.scss";
import Config from "../../../config/env.config.json";
import { EsimCoverageComponentProps } from "./EsimCoverageModal";
import { addressDisplayPattern } from "../../../utils/regexPatterns";

const T1_EsimCoverageModal = (props: EsimCoverageComponentProps) => {
  const {
    coverageModal,
    coverageLoading,
    checkDisableHandler,
    closeModalHandler,
    setIsSuccess,
    coverageAddress,
    checkCoverageHandler,
    checkAddress,
    checkAddressHandler,
    handleSelect,
    checkAgain,
    signal4G,
    signal5G,
    coverageError,
    isSuccess,
    fourGlineWidth,
    fourGlineColor,
    fiveGlineWidth,
    fiveGlineColor,
    esimCoverageContent,
    imeiCheckHandler,
  } = props;
  const {
    heading,
    sub_heading,
    place_holder,
    coverage_err,
    no_4G_coverage,
    no_5G_coverage,
    Coverage_4G,
    Coverage_5G,
    button_1,
    button_2,
    button_3,
  } = esimCoverageContent || {};
  const {
    coverage_modal,
    line_status,
    fourG_line,
    fiveG_line,
    coverage_msg,
    fourG_msg,
    coverage_check_heading,
    coverage_check_subheading,
    coverage_error,
    greenline,
  } = styles;
  const isCompleteAddressDisplayFormat =
    addressDisplayPattern.test(checkAddress);
  const displayAddressValue = isCompleteAddressDisplayFormat
    ? checkAddress.replace(addressDisplayPattern, "$1")
    : checkAddress;
  return (
    <SimpleModal
      isShowModal={coverageModal}
      onCloseModal={closeModalHandler}
      showClose={true}
      className={coverage_modal}
    >
      <Typography
        variant="h5"
        sx={{ mb: 0.75, fontSize: "22px", mt: "20px" }}
        className={coverage_check_heading}
      >
        {heading}
      </Typography>
      <Typography
        variant="subtitle2"
        className={coverage_check_subheading}
        sx={{ textAlign: "center" }}
      >
        {sub_heading}
      </Typography>
      <Typography
        sx={{ width: "25%", my: 2.75 }}
        className={greenline}
      ></Typography>

      <AutoComplete
        value={displayAddressValue}
        onChange={checkAddressHandler}
        onSelect={(e: any, placeId: string) => {
          handleSelect(placeId);
        }}
        placeholder={place_holder}
        name="aadress"
      />

      {coverageError && (
        <Container
          style={{ padding: 0 }}
          className={coverage_error}
          dangerouslySetInnerHTML={{ __html: coverage_err }}
        ></Container>
      )}

      {isSuccess && (
        <>
          <Box className={line_status}>
            <Box className={fourG_line}>
              <Box
                style={{
                  width: fourGlineWidth,
                  backgroundColor: fourGlineColor,
                  height: "3px",
                }}
              ></Box>
            </Box>
            <Box className={fiveG_line}>
              <Box
                style={{
                  width: fiveGlineWidth,
                  backgroundColor: fiveGlineColor,
                  height: "3px",
                }}
              ></Box>
            </Box>
          </Box>
          <Box className={coverage_msg}>
            <Box className={fourG_msg} style={{ color: fourGlineColor }}>
              {signal4G === "no service"
                ? no_4G_coverage
                : `${Coverage_4G} ${signal4G}`}
            </Box>
            <Box className={fourG_msg} style={{ color: fiveGlineColor }}>
              {signal5G === "no service"
                ? no_5G_coverage
                : `${Coverage_5G} ${signal5G}`}
            </Box>
          </Box>
        </>
      )}
      {isSuccess && signal4G !== "no service" ? (
        <ButtonCust
          sx={{ my: 1 }}
          variantType={Config.PRIMARY_BUTTON_TYPE}
          onClick={imeiCheckHandler}
        >
          {button_1}
        </ButtonCust>
      ) : isSuccess && signal4G === "no service" ? (
        <ButtonCust
          sx={{ my: 1 }}
          variantType={Config.PRIMARY_BUTTON_TYPE}
          onClick={() => {
            checkAgain;
            setIsSuccess(false);
          }}
        >
          {button_2}
        </ButtonCust>
      ) : (
        <ButtonCust
          sx={{ my: 1 }}
          variantType={Config.PRIMARY_BUTTON_TYPE}
          onClick={() => checkCoverageHandler(coverageAddress)}
          disabled={checkDisableHandler()}
          loading={coverageLoading}
        >
          {button_3}
        </ButtonCust>
      )}
    </SimpleModal>
  );
};

export default T1_EsimCoverageModal;
