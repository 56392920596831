import { Box, Dialog, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTypes } from "./ProfileDialogTypes";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { AutoComplete, TextFieldCust, ButtonCust } from "../../widgets";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useFormik } from "formik";
import { object, string, ref } from "yup";
import { passwordPattern } from "../../../utils/regexPatterns";
import { addressType } from "../../../features/checkout/Types";
import {
  GetCustomer,
  UpdateCustomerSource,
  getZipcodes,
} from "../../../features/checkout/checkoutSlice";
import firebase from "../../../adapters/firebase";
import {
  ProfileErrorNotifications,
  ProfileSuccessNotifications,
} from "./T1_ProfileDetails";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import EnvConfig from "../../../config/env.config.json";
import { checkCoverage } from "../../../features/homepage/homepageSlice";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../utils";
import { getZipcodeContent } from "../../../features/strapi/checkoutSlice";
import { ZipcodeModal } from "../../checkout/ZipcodeModal";

type IprofileDialogProps = {
  onClick: any;
  content: any;
  dialogType: string;
  open: boolean;
  onClose: any;
  setState?: any;
};

const StyledDialog = styled(Dialog)(({ theme }: any) => ({
  "& .MuiDialog-paper": {
    margin: "8px",
    maxWidth: "100%",
  },
}));

export const ProfileDetailsDialog = (props: IprofileDialogProps) => {
  const { onClick, content, dialogType, open, onClose, setState } = props;
  const [fullAddress, setFullAddress] = useState("");
  const [addError, setAddError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const { profileDetails } = useAppSelector(
    (state: any) => state.profileDetails
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const dispatch = useAppDispatch();
  const [zipcodeOpen, setZipcodeOpen] = useState(false);
  const [showBox, setShowBox] = useState(false);
  const [zipcodeCacheContent, setZipcodeCacheContent] = useState();
  const { zipcodeContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );

  const verifyPwdSchema = object({
    password: string().trim().required(content?.prf_err?.pwd_req),
  });
  useEffect(() => {
    getCache(
      "zipcodeContent",
      getZipcodeContent,
      setZipcodeCacheContent,
      dispatch
    );
  }, []);
  useEffect(() => {
    dispatch(getZipcodes());
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("zipcodeContent", zipcodeContent);
  }, [zipcodeContent]);
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.profile,
      category: category,
      buttonValue: value,
    });
  };

  const verifyPwdForm = useFormik({
    initialValues: { password: "" },
    validationSchema: verifyPwdSchema,
    validateOnChange: true,
    onSubmit: (values: any) => {
      setLoadingBtn(true);
      handleChangeEmail(values);
    },
    enableReinitialize: true,
  });
  const closeZipModal = () => {
    setZipcodeOpen(false);
    setShowBox(false);
  };
  const clickHandler = () => {
    setZipcodeOpen(false);
    setShowBox(false);
    setState({
      open: true,
      setNewEmail: false,
      dialogType: DialogTypes.changeAddress,
    });
  };

  const handleChangeEmail = (values: any) => {
    const { password } = values;
    let user = firebase.auth().currentUser;
    let credential = firebase.auth.EmailAuthProvider.credential(
      profileDetails?.email,
      password
    );
    user
      ?.reauthenticateWithCredential(credential)
      .then(() => {
        setLoadingBtn(false);
        handleCloseDialog();
        onClick(dialogType);
      })
      .catch((error: any) => {
        setLoadingBtn(false);
        if (error.code === "auth/too-many-requests") {
          handleCloseDialog();
          ProfileErrorNotifications(content?.notifications?.too_many);
        } else {
          setPwdError(content?.prf_err?.pwd_incrct);
        }
      });
  };

  const changePwdSchema = object({
    password: string().trim().required(content?.prf_err?.pwd_req),
    newPwd: string()
      .trim()
      .required(content?.prf_err?.pwd_req)
      .matches(passwordPattern, content?.prf_err?.pwd_len),
    confirmPwd: string()
      .trim()
      .required(content?.prf_err?.pwd_req)
      .oneOf([ref("newPwd"), null], content?.prf_err?.pwd_match),
  });

  const changePwdForm = useFormik({
    initialValues: { password: "", newPwd: "", confirmPwd: "" },
    validationSchema: changePwdSchema,
    onSubmit: (values: any) => {
      setLoadingBtn(true);
      handleChangePwd(values);
    },
    enableReinitialize: true,
  });

  const handleChangePwd = (values: any) => {
    const { password, newPwd } = values;
    let user = firebase.auth().currentUser;
    let credential = firebase.auth.EmailAuthProvider.credential(
      profileDetails?.email,
      password
    );
    user
      ?.reauthenticateWithCredential(credential)
      .then(() => {
        firebase
          .auth()
          .currentUser?.updatePassword(newPwd)
          .then(() => {
            setLoadingBtn(false);
            handleCloseDialog();
            ProfileSuccessNotifications(
              content?.notifications?.profile_updated
            );
          })
          .catch(() => {
            setLoadingBtn(false);
            ProfileErrorNotifications(content?.notifications?.try_again);
          });
      })
      .catch((error: any) => {
        setLoadingBtn(false);
        if (error.code === "auth/too-many-requests") {
          handleCloseDialog();
          ProfileErrorNotifications(content?.notifications?.too_many);
        } else {
          setPwdError(content?.prf_err?.pwd_incrct);
        }
      });
  };

  const addressForm = useFormik({
    initialValues: profileDetails,
    onSubmit: (values: any) => {
      setLoadingBtn(true);
      const { address1, city, zip, state, country, address2 } = values.address;
      const address = profileDetails?.customerDetails?.addresses?.find(
        (item: any) => item.type === addressType.billing
      );
      const shippingAddress = profileDetails?.customerDetails?.addresses?.find(
        (item: any) => item.type === addressType.shipping
      );
      const payload = {
        address1,
        address2,
        city,
        zip,
        state,
        country,
        type: addressType.billing,
        name: address?.name,
        phone: address?.phone,
      };
      dispatch(checkCoverage(payload)).then((res: any) => {
        if (res?.payload?.data?.data?.isValid) {
          updateAddress([payload, shippingAddress]);
        } else {
          setZipcodeOpen(true);
          setLoadingBtn(false);
          onClose();
          setFullAddress("");
        }
      });
    },
    enableReinitialize: true,
  });

  const updateAddress = (payload: any) => {
    dispatch(
      UpdateCustomerSource({
        customerId: profileDetails?.customerDetails?.id,
        data: { addresses: payload },
      })
    )
      .then((res: any) => {
        setLoadingBtn(false);
        if (res.payload.status === 200) {
          handleCloseDialog();
          ProfileSuccessNotifications(content?.notifications?.profile_updated);
          dispatch(
            GetCustomer({ username: encodeURIComponent(profileDetails.email) })
          );
        } else {
          setAddError(content?.prf_err?.add_error);
        }
      })
      .catch(() => {
        setLoadingBtn(false);
        setAddError(content?.prf_err?.add_error);
      });
  };

  const handleAddressChange = (value: any, name: string) => {
    addressForm.setFieldValue(`address.${name}`, value);
    setFullAddress(value);
    addressForm.setFieldValue("address.city", "");
    addressForm.setFieldValue("address.state", "");
    addressForm.setFieldValue("address.zip", "");
    addressForm.setFieldValue("address.country", "");
    setAddError("");
  };
  const handleSelect = async (address: any) => {
    const results = await geocodeByPlaceId(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : el.types.includes('neighborhood')
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    const addressData = {
      address1: `${streetNumber} ${route}`,
      city: city,
      state: state,
      zip: zip,
      country: "USA",
    };
    if (country === "US") {
      addressForm.setFieldValue("address.address1", addressData?.address1);
      addressForm.setFieldValue("address.city", addressData?.city);
      addressForm.setFieldValue("address.state", addressData?.state);
      addressForm.setFieldValue("address.zip", addressData?.zip);
      addressForm.setFieldValue("address.country", addressData?.country);
      setFullAddress(
        `${addressData?.address1}, ${city}, ${state}, ${addressData?.country}, ${zip}`
      );
    }
  };

  const handleCloseDialog = () => {
    verifyPwdForm.resetForm();
    addressForm.resetForm();
    changePwdForm.resetForm();
    setFullAddress("");
    setAddError("");
    setPwdError("");
    onClose();
  };

  const handleSubmit = () => {
    GAAndFBEventsHandler(
      gaCategory.editProfile,
      (dialogType === DialogTypes.changeEmail && content?.email_dlg_btn) ||
        (dialogType === DialogTypes.changeAddress && content?.btn1) ||
        (dialogType === DialogTypes.changePassword && content?.btn1)
    );
    if (dialogType === DialogTypes.changeEmail) {
      verifyPwdForm.handleSubmit();
    } else if (dialogType === DialogTypes.changeAddress) {
      addressForm.handleSubmit();
    } else {
      changePwdForm.handleSubmit();
    }
  };

  return (
    <>
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {open && (
        <Box
          maxWidth={"600px"}
          width={{ xs: "calc(100%)", sm: "500px", md: "600px" }}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon
            onClick={handleCloseDialog}
            sx={{
              left: 0,
              top: 0,
              position: "absolute",
              margin: 1,
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
          <Box width="75%" mx="auto" p="16px">
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 22,
                fontFamily: "var(--font_family_Bold)",
                mb: `${dialogType === DialogTypes.changeAddress && "35px"}`,
                textAlign: "center",
                paddingTop: "20px"
              }}
            >
              {(dialogType === DialogTypes.changeEmail && (content?.p_d_title || content?.pwd))}
                {(dialogType === DialogTypes.changeAddress &&
                  content?.change_address) }
                {(dialogType === DialogTypes.changePassword?
                  content?.change_pwd:null)}
            </Typography>
            {dialogType === DialogTypes.changeEmail && (
              <>
                <Typography
                  textAlign="center"
                  word-wrap="break-word"
                  mt={"20px"}
                  mb={"30px"}
                  sx={{
                    fontFamily: "var(--font_family_medium)",
                    color: "var(--grey_shade)",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {content?.email_dlg_desc}
                </Typography>
                <TextFieldCust
                  name="password"
                  type="password"
                  label={content?.current_pwd}
                  onChange={(e: any) => {
                    verifyPwdForm.handleChange(e), setPwdError("");
                  }}
                  onBlur={verifyPwdForm.handleBlur}
                  fullWidth
                  error={
                    pwdError
                      ? true
                      : verifyPwdForm.touched?.password &&
                        verifyPwdForm.errors?.password
                      ? true
                      : false
                  }
                  helperText={
                    pwdError
                      ? pwdError
                      : verifyPwdForm.touched?.password &&
                        verifyPwdForm.errors?.password
                  }
                />
              </>
            )}
            {dialogType === DialogTypes.changeAddress && (
              <>
                <AutoComplete
                  value={fullAddress || ""}
                  onChange={(e: any) => {
                    handleAddressChange(e, "address1");
                  }}
                  onSelect={(e: any, placeId: string) => {
                    handleSelect(placeId);
                  }}
                  placeholder={content?.address_dlg_plc}
                  helperText={addError}
                  error={addError ? true : false}
                />
                {EnvConfig?.IS_PROFILE_SEC_ADD_FIELD_REQD && (
                  <TextFieldCust
                    label={content?.address2_plc}
                    sx={{ m: "15px 0" }}
                    name="address.address2"
                    onChange={addressForm.handleChange}
                    value={addressForm.values.address2}
                  />
                )}
              </>
            )}
            {dialogType === DialogTypes.changePassword && (
              <>
                <TextFieldCust
                  fullWidth
                  label={content?.current_pwd}
                  type="password"
                  name="password"
                  onChange={(e: any) => {
                    changePwdForm.handleChange(e), setPwdError("");
                  }}
                  onBlur={changePwdForm.handleBlur}
                  sx={{ mb: "30px", mt: "30px" }}
                  error={
                    pwdError
                      ? true
                      : (changePwdForm.values.password ||
                          changePwdForm.touched?.password) &&
                        changePwdForm.errors?.password
                      ? true
                      : false
                  }
                  helperText={
                    pwdError
                      ? pwdError
                      : (changePwdForm.values.password ||
                          changePwdForm.touched?.password) &&
                        changePwdForm.errors?.password
                  }
                />
                <TextFieldCust
                  fullWidth
                  label={content?.new_pwd}
                  type="password"
                  name="newPwd"
                  onChange={changePwdForm.handleChange}
                  onBlur={changePwdForm.handleBlur}
                  sx={{ mb: "30px" }}
                  error={
                    (changePwdForm.touched?.newPwd ||
                      changePwdForm.values.newPwd) &&
                    changePwdForm.errors?.newPwd
                      ? true
                      : false
                  }
                  helperText={
                    (changePwdForm.touched?.newPwd ||
                      changePwdForm.values.newPwd) &&
                    changePwdForm.errors?.newPwd
                  }
                />
                <TextFieldCust
                  fullWidth
                  label={content?.confirm_pwd}
                  type="password"
                  name="confirmPwd"
                  onChange={changePwdForm.handleChange}
                  onBlur={changePwdForm.handleBlur}
                  sx={{ mb: "15px" }}
                  error={
                    (changePwdForm.touched?.confirmPwd ||
                      changePwdForm.values.confirmPwd) &&
                    changePwdForm.errors?.confirmPwd
                      ? true
                      : false
                  }
                  helperText={
                    (changePwdForm.touched?.confirmPwd ||
                      changePwdForm.values.confirmPwd) &&
                    changePwdForm.errors?.confirmPwd
                  }
                />
              </>
            )}
            <Box justifyContent="center" display="flex">
              <ButtonCust
                sx={{
                  mb: "30px",
                  mt: `${
                    dialogType === DialogTypes.changeEmail ? "30px" : "15px"
                  }`,
                }}
                onClick={handleSubmit}
                loading={loadingBtn}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
              >
                {(dialogType === DialogTypes.changeEmail &&
                  content?.email_dlg_btn) ||
                  (dialogType === DialogTypes.changeAddress && content?.btn1) ||
                  (dialogType === DialogTypes.changePassword && content?.btn1)}
              </ButtonCust>
            </Box>
          </Box>
        </Box>
      )}
    </StyledDialog>
    <ZipcodeModal
    modalFg={zipcodeOpen}
    updateFg={false}
    content={zipcodeContent ? zipcodeContent : zipcodeCacheContent}
    closeHandler={closeZipModal}
    address={addressForm?.values?.address}
    showBox={showBox}
    setShowBox={setShowBox}
    clickHandler={clickHandler}
    showcheckcontent = {true}
  />
  </>
  );
};
