import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { getSimPrefernceContent } from "../../../features/strapi/checkoutSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import EnvConfig from "../../../config/env.config.json";

const ReviewPlanComponent = isTemplateExisits(Config.Checkout.ReviewPlanDetails)
  ? require(`./${Config.Checkout.ReviewPlanDetails.template}_ReviewPlanDetails`)
      .default
  : null;

type Props = {
  formik: any;
  shipmentPlans: Array<any>;
  content?: any;
  simPrefenceContent?: any;
  simPreference: any;
  setSimPreference: any;
  simInHandCheck?: any;
  simId?: number | string;
  isAllowedMultipleLineSelectionForSIMInHand?: any;
  checkoutPageOpsMessages?: any;
  isAutoSelect?: boolean;
  setIsAutoSelect?: any;
};
export const ReviewPlanDetails = (props: Props) => {
  const {
    simPreference,
    setSimPreference,
    simInHandCheck,
    simId,
    isAllowedMultipleLineSelectionForSIMInHand,
    isAutoSelect,
    setIsAutoSelect
  } = props;
  const dispatch = useAppDispatch();
  const { simPreferenceContent } = useAppSelector(
    (state: any) => state.strapi.checkout
  );
  const [cacheSimPrefenceContent, setCacheSimPrefenceContent] = useState();
  useEffect(() => {
    getCache(
      "SimPreferenceContent",
      getSimPrefernceContent,
      setCacheSimPrefenceContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("SimPreferenceContent", simPreferenceContent);
  }, [simPreferenceContent]);
  return (
    <ReviewPlanComponent
      content={props.content}
      formik={props.formik}
      shipmentPlans={props.shipmentPlans}
      simPrefenceContent={
        cacheSimPrefenceContent ? cacheSimPrefenceContent : simPreferenceContent
      }
      simPreference={simPreference}
      setSimPreference={setSimPreference}
      simInHandCheck={simInHandCheck}
      simId={simId}
      setIsAutoSelect={setIsAutoSelect}
      isAutoSelect={isAutoSelect}
      isAllowedMultipleLineSelectionForSIMInHand={
        isAllowedMultipleLineSelectionForSIMInHand
      }
      {...(EnvConfig?.IS_MSG_CENTER_ENABLED && {
        checkoutPageOpsMessages: props.checkoutPageOpsMessages,
      })}
    />
  );
};
