import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  billingDetails: any;
  errorBillingDetails: any;
  autoPaymentDetails: any;
  errorAutoPayment: any;
  paymentHistoryDetails: any;
  errorPaymentHistory: any;
  billGenerationDetails: any;
  errorBillGenerationDetails: any;
  currentPlanDetails: any;
  errorCurrentPlanDetails: any;
  bestFitDetails: any;
  errorBestFitDetails: any;
  invoiceDetails: any;
  errorInvoiceDetails: any;
  selectCardDialogContent: any;
  errorSelectCardDialogContent: any;
};

const initialState: InitialState = {
  billingDetails: null,
  errorBillingDetails: null,
  autoPaymentDetails: null,
  errorAutoPayment: null,
  paymentHistoryDetails: null,
  errorPaymentHistory: null,
  billGenerationDetails: null,
  errorBillGenerationDetails: null,
  currentPlanDetails: null,
  errorCurrentPlanDetails: null,
  bestFitDetails: null,
  errorBestFitDetails: null,
  invoiceDetails: null,
  errorInvoiceDetails: null,
  selectCardDialogContent: null,
  errorSelectCardDialogContent: null,
};

// Generates pending, fulfilled and rejected action types
export const billingContent = createAsyncThunk("billing", () => {
  return apiJunction.makeRequest({
    method: "get",
    // url: `/${Config.Billing.BillingSection.template}${StrapiApiEndPoints.billingDetails}`,
    url: `/T1${StrapiApiEndPoints.billingDetails}`,
    isStrapiApi: true,
  });
});
export const autoPayment = createAsyncThunk("autoPayment", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.AutoPayment.template}${StrapiApiEndPoints.autoPayment}`,
    isStrapiApi: true,
  });
});
export const paymentHistory = createAsyncThunk("paymentHistory", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.PaymentHistory.template}${StrapiApiEndPoints.paymentHistory}`,
    isStrapiApi: true,
  });
});
export const currentBill = createAsyncThunk("currentBill", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.BillGeneration.template}${StrapiApiEndPoints.currentBill}`,
    isStrapiApi: true,
  });
});
export const currentPlan = createAsyncThunk("currentPlan", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.PlanDetails.template}${StrapiApiEndPoints.currentPlan}`,
    isStrapiApi: true,
  });
});
export const bestFit = createAsyncThunk("bestFit", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.BillSavings.template}${StrapiApiEndPoints.BillingBestFit}`,
    isStrapiApi: true,
  });
});

export const invoiceContent = createAsyncThunk("invoice", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${Config.Billing.AutoPayment.template}${StrapiApiEndPoints.invoiceDetails}`,
    isStrapiApi: true,
  });
});
export const selectCardDialog = createAsyncThunk("selectCardDialog", () => {
  return apiJunction.makeRequest({
    method: "get",
    url: `/${StrapiApiEndPoints.selectCardDialog}`,
    isStrapiApi: true,
  });
});

const strapiBillingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      billingContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.billingDetails = action.payload?.data?.data?.attributes;
        state.errorBillingDetails = null;
      }
    );
    builder.addCase(
      billingContent.rejected,
      (state: InitialState, action: any) => {
        state.billingDetails = null;
        state.errorBillingDetails = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      autoPayment.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.autoPaymentDetails = action.payload?.data?.data?.attributes;
        state.errorAutoPayment = null;
      }
    );
    builder.addCase(
      autoPayment.rejected,
      (state: InitialState, action: any) => {
        state.autoPaymentDetails = null;
        state.errorAutoPayment =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      paymentHistory.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.paymentHistoryDetails = action.payload?.data?.data?.attributes;
        state.errorPaymentHistory = null;
      }
    );
    builder.addCase(
      paymentHistory.rejected,
      (state: InitialState, action: any) => {
        state.paymentHistoryDetails = null;
        state.errorPaymentHistory = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      currentBill.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.billGenerationDetails = action.payload?.data?.data?.attributes;
        state.errorBillGenerationDetails = null;
      }
    );
    builder.addCase(
      currentBill.rejected,
      (state: InitialState, action: any) => {
        state.billGenerationDetails = null;
        state.errorBillGenerationDetails =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      currentPlan.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.currentPlanDetails = action.payload?.data?.data?.attributes;
        state.errorCurrentPlanDetails = null;
      }
    );
    builder.addCase(
      currentPlan.rejected,
      (state: InitialState, action: any) => {
        state.currentPlanDetails = null;
        state.errorCurrentPlanDetails =
          action?.error?.message || "Something went wrong";
      }
    );
    builder.addCase(
      bestFit.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.bestFitDetails = action.payload?.data?.data?.attributes;
        state.errorBestFitDetails = null;
      }
    );
    builder.addCase(bestFit.rejected, (state: InitialState, action: any) => {
      state.bestFitDetails = null;
      state.errorBestFitDetails =
        action?.error?.message || "Something went wrong";
    });
    builder.addCase(
      invoiceContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.invoiceDetails = action?.payload?.data?.data?.attributes;
        state.errorInvoiceDetails = null;
      }
    );
    builder.addCase(
      invoiceContent.rejected,
      (state: InitialState, action: any) => {
        state.invoiceDetails = null;
        state.errorInvoiceDetails = action.error || "Something went wrong";
      }
    );
    builder.addCase(
      selectCardDialog.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.selectCardDialogContent = action.payload?.data?.data?.attributes;
        state.errorSelectCardDialogContent = null;
      }
    );
    builder.addCase(
      selectCardDialog.rejected,
      (state: InitialState, action: any) => {
        state.selectCardDialogContent = null;
        state.errorSelectCardDialogContent =
          action?.error?.message || "Something went wrong";
      }
    );
  },
});

export default strapiBillingSlice.reducer;
