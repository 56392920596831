import { Typography, Box } from "@mui/material";
import { geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../../../widgets";
import { object, string } from "yup";
import styles from "./AddShippingAddress.module.scss";
import Config from "../../../../../config/env.config.json";
import { useFormik } from "formik";
import { AddressField } from "../../../../checkout/AddressField/AddressField";
import { namePattern } from "../../../../../utils/regexPatterns";
import { addressType } from "../../../../../features/checkout/Types";
import { useAppSelector } from "../../../../../app/hooks";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gtmTagManager } from "../../../../../utils/commonFunctions/GtmTagManager";

type Props = {
  onClose?: any;
  updateAddress: any;
  content?: any;
  showBackArrow: boolean;
  showLabels?: any;
};

export function AddShippingAddress(props: Props) {
  const { onClose, updateAddress, showBackArrow } = props;
  const {
    add_address,
    firstname_err,
    lastname_err,
    save_btn_txt,
    addr_title,
    addr_sub1,
    addr_sub2,
    title_1,
    shipping_logo,
  } = props?.content;

  interface formikintitaltypes {
    firstName: string;
    lastName: string;
    billingAddress: any;
  }
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { firstName, lastName } = getCustomer || {};
  const initialformstep1data: formikintitaltypes = {
    firstName: firstName || "",
    lastName: lastName || "",
    billingAddress: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      state: "",
      zip: "",
      type: addressType.billing,
    },
  };
  const billingAddressVal = object({
    address1: string().trim().required(add_address?.address1_err),
    city: string().trim().required(add_address?.city_err),
    state: string().trim().required(add_address?.state_err),
    zip: string()
      .trim()
      .required(add_address?.zip_err1)
      .matches(/^[0-9]+$/, add_address?.zip_err2)
      .min(5, add_address?.zip_err3)
      .max(5, add_address?.zip_err3),
  });
  const shipmingFormSchema = object({
    firstName: string().trim().required(firstname_err),
    lastName: string().trim().required(lastname_err),
    billingAddress: billingAddressVal,
  });
  const shipmingFormSchema2 = object({
    billingAddress: billingAddressVal,
  });
  const formik: any = useFormik({
    initialValues: initialformstep1data,
    validationSchema: shipmingFormSchema2,
    onSubmit: (values: any) => {
      updateAddress(formik.values);
    },
    enableReinitialize: true,
  });
  const handleBillingAddress = (value: any, name: string) => {
    formik.setFieldValue(`billingAddress.${name}`, value);
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "billingAddress"
  ) => {
    const results = await geocodeByPlaceId(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    if (country === "US") {
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
      const touchedBilling: any = formik.touched.billingAddress;
      formik.setTouched({
        ...formik.touched,
        billingAddress: {
          ...touchedBilling,
          city: "",
          state: "",
          zip: "",
        },
      });
    }
  };

  return (
    <Box className={styles.step1_main}>
      <Box className={styles.heading_container}>
        {showBackArrow && (
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
            className={styles.arrow_icon}
            onClick={() => props.onClose()}
          />
        )}
        <Typography className={styles.title_main}>{addr_title}</Typography>
      </Box>
      <Box
        className={styles.container}
        sx={{
          width: { xs: "90%", sm: "50%" },
          margin: "0 auto",
          background: "var(--white)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderBottom: "2px solid var(--lightgrey_6)",
            p: "33px 0",
          }}
        >
          <Box
            component="img"
            alt={title_1}
            src={shipping_logo?.data?.attributes?.url || ""}
            mr="10px"
            className={styles.profile_img}
            width="51px"
            height="35px"
          ></Box>
          <Box
            sx={{
              fontFamily: "var(--font_family_Bold)",
              fontWeight: "var(--font_weight_4)",
              fontSize: "24px",
              lineHeight: "40px",
              color: "var(--black)",
            }}
          >
            {title_1}
          </Box>
        </Box>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              fontFamily: "var(--font_family_Regular)",
              fontWeight: "var(--font_weight_0)",
            }}
            // className={
            //   props?.showLabels ? styles.bold_title : styles.subtitle_main
            // }
          >
            {addr_sub1}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              fontFamily: "var(--font_family_Regular)",
              fontWeight: "var(--font_weight_0)",
            }}
            // className={
            //   props?.showLabels ? styles.bold_subtitle : styles.subtitle_main
            // }
          >
            {addr_sub2}
          </Typography>
        </Box>
        <Box>
          <Box sx={{ padding: "20px" }}>
            <AddressField
              change_address="change_address"
              content={add_address}
              formik={formik}
              handleSelect={(e: any) =>
                handleAddressSearchSelection(e, "billingAddress")
              }
              handleAddressChange={handleBillingAddress}
              addressObjName="billingAddress"
              showLabels={props.showLabels}
            />
          </Box>
        </Box>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            // marginBottom: "150px",
          }}
        >
          <ButtonCust
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              formik.handleSubmit();
              gtmTagManager({
                event: globalVal?.gtmEvents?.shippingContinue,
                click_section: "shippingAddress",
              });
            }}
            sx={{
              marginBottom: "40px",
              "&:hover": {
                backgroundColor: "var(--button_hover_color) !important",
                border: "1px solid var(--button_hover_color) !important",
              },
            }}
          >
            {save_btn_txt}
          </ButtonCust>
        </Box>
      </Box>
    </Box>
  );
}
