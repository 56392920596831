import { useEffect, useState } from "react";
import { ButtonCust, Divider } from "../../../../widgets";
import { Grid, Typography, Box, Dialog, DialogContent } from "@mui/material";
import Config from "../../../../../config/env.config.json";
import styles from "./EstimatedMonthlyBillDialog.module.scss";
import { GetCurrencyFixedToTwo } from "../../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../../../utils/commonFunctions";
import { estimatedBillSectionContent } from "../../../../../features/strapi/accountSlice";
import { CardTypes } from "../../../../../enums";
import { Coupon } from "../../../../../features/checkout/checkoutSlice";
import { getCustomerPlan } from "../../../../../features/billing/billingSlice";
import { customerGroup } from "../../../../../features/account/accountSlice";
import { Close } from "@mui/icons-material";
import EnvConfig from "../../../../../config/env.config.json";

type EstimatedMonthlyBillDialogProps = {
  showEstBillModal: boolean;
  reviewDetails: any;
  currentPlan: any;
  pricingTax: any;
  oneTmeCost: number;
  counter: number;
  oneTimeSIMTaxCharges: any;
  shipPlan: any;
  setShowEstBillModal: any;
  estBillLoader: boolean;
  confirmPurchaseHandler: any;
  showManageCard: any;
  numOfEsim: number;
  numOfPsim: number;
  eSimCostPerLine: number;
  errorMsgOrder?: string;
  oneTimeShippingTaxCharges: any;
  addLineConfirmationOpsMessages?: any;
};

export const EstimatedMonthlyBillDialog = (
  props: EstimatedMonthlyBillDialogProps
) => {
  const {
    showEstBillModal,
    reviewDetails,
    currentPlan,
    pricingTax,
    oneTmeCost,
    counter,
    oneTimeSIMTaxCharges,
    oneTimeShippingTaxCharges,
    shipPlan,
    setShowEstBillModal,
    estBillLoader,
    confirmPurchaseHandler,
    showManageCard,
    numOfEsim,
    numOfPsim,
    eSimCostPerLine,
    errorMsgOrder,
    addLineConfirmationOpsMessages,
  } = props || {};
  const dispatch = useAppDispatch();
  const { estMonthlyAddContent } = useAppSelector(
    (state: any) => state.strapi.account
  );

  const { getCCDetails, group } = useAppSelector((state: any) => state.account);
  const { coupon } = useAppSelector((state: any) => state.checkout);
  const { customerPlanDetails } = useAppSelector((state: any) => state.billing);
  const [mainCard, setMainCard] = useState<any>(null);
  const [isCardExpired, setIsCardExpired] = useState(false);
  const [estBillCacheContent, setEstBillCacheContent] = useState<any>(null);
  const [totalActivitedUsers, settotalActivitedUsers] = useState<number>(0);
  const [wowDiscountCoupon, setWowDiscountCoupon] = useState<any>("");
  const [discount, setDiscount] = useState(0);

  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);

  useEffect(() => {
    let main: any =
      getCCDetails?.length > 0 &&
      getCCDetails.find((el: any) => el.isAutoPay === true);
    if (main) {
      setMainCard(main);
      let today, someday;
      let exMonth = main.dateOfExpiry && main.dateOfExpiry.substring(0, 2);
      let exYear = main.dateOfExpiry && main.dateOfExpiry.substring(2);
      exYear = exYear && `20${exYear}`;
      today = new Date();
      someday = new Date();
      someday.setFullYear(exYear, exMonth, 1);
      if (someday < today) {
        setIsCardExpired(true);
      } else {
        setIsCardExpired(false);
      }
    }
  }, [getCCDetails]);

  useEffect(() => {
    getCache(
      "EstimatedBillAdd",
      estimatedBillSectionContent,
      setEstBillCacheContent,
      dispatch
    );
    dispatch(getCustomerPlan);
    dispatch(customerGroup({ groupId: currentdetails?.groupId }));
  }, []);

  useEffect(() => {
    if (customerPlanDetails?.wowCoupon) {
      dispatch(Coupon({ couponCode: customerPlanDetails?.wowCoupon }));
    }
  }, [customerPlanDetails]);
  useEffect(() => {
    let totalActiveLine: any = group?.data?.filter(
      (x: any) => x.status === 2
    ).length;
    settotalActivitedUsers(totalActiveLine);
    if (coupon) {
      setWowDiscountCoupon(coupon?.name);
      setDiscount(
        coupon?.discountInDollar +
          coupon?.secondaryDiscountInDollar * (totalActivitedUsers - 1)
      );
    }
  }, [coupon]);

  useNonInitialEffect(() => {
    addMultipleCacheData("EstimatedBillAdd", estMonthlyAddContent);
  }, [estMonthlyAddContent]);

  const renderLiveOpsMessages = () => {
    if (addLineConfirmationOpsMessages?.length <= 0) return null;
    return (
      <Typography component="div" sx={{ m: "20px 40px", textAlign: "left" }}>
        <Typography
          component="div"
          sx={{
            color: "var(--primary_brand_color)",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
          }}
        >
          Note:
        </Typography>
        {addLineConfirmationOpsMessages.map((opsMessage: any) => {
          return (
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                wordBreak: "break-all",
              }}
              key={opsMessage.id}
            >
              <Typography
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "inherit",
                }}
              >
                {opsMessage.messageType}:{" "}
              </Typography>
              {opsMessage.message}
            </Typography>
          );
        })}
      </Typography>
    );
  };

  const content = estBillCacheContent
    ? estBillCacheContent
    : estMonthlyAddContent;

  let savings_text = content?.savings_txt
    ?.replace("{wowDiscountCoupon}", wowDiscountCoupon)
    .replace("{discount}", GetCurrencyFixedToTwo(discount));
  const isAllowedPsimCharges =
    (numOfPsim >= 1 && Config.IS_ESIM_SUPPORTED) || !Config.IS_ESIM_SUPPORTED;
  // sum of taxes of shipping,sim
  const totalTaxSum =
    numOfPsim > 0
      ? (oneTimeSIMTaxCharges?.totalTax || 0) +
        (oneTimeShippingTaxCharges?.totalTax || 0)
      : 0;
  // sum of taxes of shipping,sim and  sim,shipping charges
  const totalDueAmount = isAllowedPsimCharges
    ? oneTmeCost + (shipPlan?.cost || 0) + totalTaxSum || 0
    : 0;

  return (
    content && (
      <Dialog
        onClose={() => setShowEstBillModal(false)}
        open={showEstBillModal}
        maxWidth={"sm"}
        fullWidth
        scroll="body"
      >
        <DialogContent>
          <Close
            onClick={() => setShowEstBillModal(false)}
            style={{
              color: "var(--black)",
              opacity: "0.6",
              fontSize: "20px",
              cursor: "pointer",
            }}
          />
          <Grid className={styles.new_monthly_estimated_modal_block}>
            <Typography className={styles.new_monthly_estimated_modal_heading}>
              {content?.heading}
            </Typography>
            <Divider />
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.the}
                {reviewDetails.selectedPlanName}(
                {reviewDetails.currentAdditioinalLines + 1} @{" "}
                {GetCurrencyFixedToTwo(currentPlan?.baseLinePrice)})
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(reviewDetails.newPricing)}
              </Typography>
            </Box>
            <Box className={styles.monthly_estimated_bill_line}></Box>
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.tax_fees}
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(pricingTax.newPricingTax)}
              </Typography>
            </Box>
            <Box className={styles.monthly_estimated_bill_line}></Box>

            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.heading}
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(
                  reviewDetails.newPricing + pricingTax.newPricingTax
                )}
              </Typography>
            </Box>
            {Config?.SHOW_CHARGES_NOTE_IN_ESTIMATE_BILL && (
              <>
                <Typography className={styles.charges_text}>
                  {content?.plan_charges}
                </Typography>
                <Box
                  className={styles.monthly_estimated_bill_line}
                  my="16px"
                ></Box>
              </>
            )}
            <Box className={styles.one_time_charges_text}>
              {content?.one_time}
            </Box>
            {Config?.SHOW_CHARGES_NOTE_IN_ESTIMATE_BILL && (
              <Typography className={styles.charges_text} mb="16px">
                {content?.shipping_charges}
              </Typography>
            )}

            <Box className={styles.monthly_estimated_bill_line}></Box>
            {numOfEsim >= 1 && (
              <Box className={styles.estimated_pricing}>
                <Typography className={styles.estimated_pricing_text}>
                  {content?.esim_kit}({numOfEsim})
                </Typography>
                <Typography className={styles.estimated_price_value}>
                  {GetCurrencyFixedToTwo(eSimCostPerLine)}
                </Typography>
              </Box>
            )}
            {/* welcome kit */}
            {isAllowedPsimCharges && (
              <Box className={styles.estimated_pricing}>
                <Typography className={styles.estimated_pricing_text}>
                  {content?.welcome_kit}(
                  {Config.IS_ESIM_SUPPORTED ? numOfPsim : counter})
                </Typography>
                <Typography className={styles.estimated_price_value}>
                  {GetCurrencyFixedToTwo(oneTmeCost)}
                </Typography>
              </Box>
            )}
            <Box className={styles.monthly_estimated_bill_line}></Box>
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {isAllowedPsimCharges
                  ? shipPlan.label
                  : content?.instant_delivery}
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {isAllowedPsimCharges
                  ? GetCurrencyFixedToTwo(shipPlan?.cost)
                  : GetCurrencyFixedToTwo(
                      Config.INSTANT_DELIVERY_VIA_EMAIL_CHARGES
                    )}
              </Typography>
            </Box>
            <Box className={styles.monthly_estimated_bill_line}></Box>
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.estimated_pricing_text}>
                {content?.tax_fees}
              </Typography>
              <Typography className={styles.estimated_price_value}>
                {GetCurrencyFixedToTwo(totalTaxSum ?? 0)}
              </Typography>
            </Box>
            <Box className={styles.monthly_estimated_bill_line}></Box>
            <Box className={styles.estimated_pricing}>
              <Typography className={styles.one_time_charges_text}>
                {content?.total_due}
              </Typography>
              <Typography className={styles.one_time_charges_text}>
                {totalDueAmount
                  ? GetCurrencyFixedToTwo(totalDueAmount)
                  : GetCurrencyFixedToTwo(0)}
              </Typography>
            </Box>
            {currentdetails?.isWowHSDCustomer && (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  sx={{ color: "var(--primary_brand_color)" }}
                >
                  <Typography
                    variant="body2"
                    lineHeight="2.14"
                    fontFamily="var(--font_family_Medium)"
                    fontSize="14px"
                  >
                    {content?.promo_code}
                  </Typography>
                </Box>
                <Grid
                  border="1px dashed var(--text_color)"
                  borderRadius="5px"
                  p="10px 65px 10px 10px"
                  mb="14px"
                >
                  <Typography
                    textAlign="left"
                    fontFamily="var(--font_family_Medium)"
                    color="var(--primary_brand_color)"
                    fontSize="14px"
                  >
                    {savings_text}
                  </Typography>
                </Grid>
              </>
            )}
            <Box className={styles.default_card}>
              <Typography className={styles.one_time_charges_text}>
                {content?.payment}
              </Typography>
              <Box className={styles.account_review_change_card}>
                <span className={styles.review_card_img}>
                  <img
                    alt="card_icon"
                    src={
                      mainCard?.type?.toUpperCase() === CardTypes?.VISA
                        ? content?.visa_img?.data?.attributes?.url
                        : content?.review_card?.data?.attributes?.url
                    }
                  ></img>
                </span>
                <Typography
                  className={styles.cc_mask}
                  sx={{
                    color: isCardExpired
                      ? "var(--danger)"
                      : "var(--text_color)",
                  }}
                >
                  {content?.card_num}
                  {mainCard?.ccNumberMask}
                </Typography>
              </Box>
            </Box>
            <>
              {EnvConfig?.IS_MSG_CENTER_ENABLED
                ? renderLiveOpsMessages()
                : null}
            </>
            <Box className={styles.new_monthly_estimated_modal_btn}>
              <ButtonCust
                variantType={Config.PRIMARY_BUTTON_TYPE}
                loading={estBillLoader}
                onClick={confirmPurchaseHandler}
              >
                {content?.button_1}
              </ButtonCust>
            </Box>
            <Typography
              className={styles.manage_cards_for_secondary_lines}
              onClick={showManageCard}
            >
              {content?.manage_cards}
            </Typography>
            {errorMsgOrder && (
              <Typography className={styles.error_msg_order}>
                {errorMsgOrder}
              </Typography>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    )
  );
};
