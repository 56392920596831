import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { SimpleModal } from "../widgets";
import Lottie from "react-lottie";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  sendAPNSettingsBeforePurchase,
  sendAPNSettingsPostPurchase,
  sendAPNSettingsAndroidPostPurchase,
  sendAPNSettingsAndroidBeforePurchase,
} from "../../features/homepage/homepageSlice";
import EnvConfig from "../../config/env.config.json";
import { RoutingURLs } from "../../config/RoutingURLs";

type Props = {
  isShowModal: boolean;
  onCloseModal: (e: any) => any;
  currentLine?: any;
  isAndroid?: any;
  closeCompatModal?: any;
  closeCompImeiModal?: any;
};

export function ApnSettingsModal(props: Props) {
  const { configKeys } = useAppSelector((state: any) => state?.activation);
  const { apnRes, apnImages } = useAppSelector(
    (state: any) => state?.strapi?.apnSettings || {}
  );
  const { isShowModal, onCloseModal, currentLine, isAndroid } = props;
  const [index, setIndex] = useState(0);
  const [sendGuidelinesSuccess, setSendGuidelinesSuccess] = useState(false);
  const [sendGuidelinesLoading, setSendGuidelinesLoading] = useState(false);
  const [isSendGuidelinessLoader, setIsSendGuidelinessLoader] = useState(false);
  const dispatch = useAppDispatch();

  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);
  let currentUser: any = localStorage.getItem("currentuser");
  currentUser = JSON.parse(currentUser);
  const Screen1Heading =
    (isAndroid ? apnRes?.andriodStep1Heading : apnRes?.step1Heading) ?? "";
  const Screen1SubHeading =
    (isAndroid ? apnRes?.andriodStep1SubHeading : apnRes?.step1SubHeading) ??
    "";
  const Screen2SubHeading =
    (isAndroid ? apnRes?.andriodStep2SubHeading : apnRes?.step2SubHeading) ??
    "";
  const Screen3SubHeading =
    (isAndroid ? apnRes?.andriodStep3SubHeading : apnRes?.step3SubHeading) ??
    "";
  const Screen4SubHeading =
    (isAndroid ? apnRes?.andriodStep4SubHeading : apnRes?.step4SubHeading) ??
    "";
  const Screen5SubHeading =
    (isAndroid ? apnRes?.andriodStep5SubHeading : apnRes?.step5SubHeading) ??
    "";
  const Screen6SubHeading =
    (isAndroid ? apnRes?.andriodStep6SubHeading : apnRes?.step6SubHeading) ??
    "";
  const Screen8SubHeading =
    (isAndroid ? apnRes?.andriodStep8SubHeading : apnRes?.step8SubHeading) ??
    "";
  const Screen9SubHeading =
    (isAndroid ? apnRes?.andriodStep9SubHeading : apnRes?.step9SubHeading) ??
    "";
  const Screen1Icon =
    (isAndroid
      ? apnImages?.and_step01_img?.data?.attributes?.url
      : apnImages?.apn_step01_img?.data?.attributes?.url) ?? "";
  const Screen2Icon =
    (isAndroid
      ? apnImages?.and_step02_img?.data.attributes?.url
      : apnImages?.apn_step02_img?.data.attributes?.url) ?? "";
  const Screen3Icon =
    (isAndroid
      ? apnImages?.and_step03_img?.data.attributes?.url
      : apnImages?.apn_step03_img?.data.attributes?.url) ?? "";
  const Screen4Icon =
    (isAndroid
      ? apnImages?.and_step04_img?.data.attributes?.url
      : apnImages?.apn_step04_img?.data.attributes?.url) ?? "";
  const Screen5Icon =
    (isAndroid
      ? apnImages?.and_step05_img?.data.attributes?.url
      : apnImages?.apn_step05_img?.data.attributes?.url) ?? "";
  const Screen6Icon =
    (isAndroid
      ? apnImages?.and_step06_img?.data.attributes?.url
      : apnImages?.apn_step06_img?.data.attributes?.url) ?? "";
  const Screen8Icon =
    (isAndroid
      ? apnImages?.and_step08_img?.data.attributes?.url
      : apnImages?.apn_step08_img?.data.attributes?.url) ?? "";
  const Screen9Icon =
    (isAndroid
      ? apnImages?.and_step09_img?.data.attributes?.url
      : apnImages?.apn_step09_img?.data.attributes?.url) ?? "";
  const Screen7DividerIcon =
    apnImages?.apn_step07_divider?.data.attributes?.url ?? "";
  const ModalCloseIcon =
    apnImages?.apn_modal_close_img?.data.attributes?.url ?? "";
  const qrCode = configKeys?.APN_QR_CODE_IOS_16 ?? "";
  let pointerAnimation =
    (apnImages &&
      JSON.parse(JSON.stringify(apnImages?.apn_pointer_animation_json))) ??
    {};
  let emailAnimation =
    (apnImages &&
      JSON.parse(JSON.stringify(apnImages?.apn_email_animation_json))) ??
    {};
  let apnScreen1Animation =
    (apnImages &&
      JSON.parse(JSON.stringify(apnImages?.apn_cloud_animation_json))) ??
    {};
  let isPrimaryEmailId = EnvConfig?.IS_PRIMARY_EMAIL_APN_QR_CODE
    ? customerDetail?.emailId || currentUser?.email
    : false;

  const totalCarouselPage =
    currentLine?.email || currentLine?.emailId || isPrimaryEmailId
      ? isAndroid
        ? 9
        : 7
      : isAndroid
      ? 9
      : 6;


  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const emailAnimationOptions = {
    ...defaultOptions,
    animationData: emailAnimation,
  };
  const screen1Animation = {
    ...defaultOptions,
    animationData: apnScreen1Animation,
  };
  const pointerAnimationOptions = {
    ...defaultOptions,
    animationData: pointerAnimation,
  };
  let checkoutPage = location.pathname === RoutingURLs?.checkout;

  const handlePrev = (e: any) => {
    e.stopPropagation();
    if (index === 0) {
      return;
    } else {
      setIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleNext = (e: any) => {
    e.stopPropagation();
    if (index === totalCarouselPage - 1) {
      return;
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleShareApnSettings = () => {
    setIsSendGuidelinessLoader(true);
    let userEmail =
      customerDetail?.emailId || currentUser?.email || currentLine?.emailId;
    let userFirstName =
    currentLine.firstName || currentUser?.displayName?.trim()?.split(" ")[0] ||
      customerDetail?.displayName?.trim()?.split(" ")[0];
    if (currentLine?.isPrimary && !currentLine?.simPaid) {
      if (isAndroid) {
        dispatch(
          sendAPNSettingsAndroidPostPurchase({
            customerId: currentLine?.id ?? "",
          })
        ).then((resp: any) => {
          if (resp?.payload?.status === 200) {
            setSendGuidelinesSuccess(true);
            setIsSendGuidelinessLoader(false);
          } else {
            setIsSendGuidelinessLoader(false);
          }
        });
      } else {
        dispatch(
          sendAPNSettingsPostPurchase({
            customerId: currentLine?.id ?? "",
          })
        ).then((resp: any) => {
          if (resp?.payload?.status === 200) {
            setSendGuidelinesSuccess(true);
            setIsSendGuidelinessLoader(false);
          } else {
            setIsSendGuidelinessLoader(false);
          }
        });
      }
    } else {
      if (isAndroid) {
        dispatch(
          sendAPNSettingsAndroidBeforePurchase({
            email: userEmail ?? "",
            firstName: userFirstName ?? "",
          })
        ).then((resp: any) => {
          if (resp?.payload?.status === 200) {
            setSendGuidelinesSuccess(true);
            setIsSendGuidelinessLoader(false);
          } else {
            setIsSendGuidelinessLoader(false);
          }
        });
      } else {
        dispatch(
          sendAPNSettingsBeforePurchase({
            email: userEmail ?? "",
            firstName: userFirstName ?? "",
          })
        ).then((resp: any) => {
          if (resp?.payload?.status === 200) {
            setSendGuidelinesSuccess(true);
            setIsSendGuidelinessLoader(false);
          } else {
            setIsSendGuidelinessLoader(false);
          }
        });
      }
    }
  };

  if (sendGuidelinesSuccess) {
    return (
      <SimpleModal
        isShowModal={sendGuidelinesSuccess}
        onCloseModal={(e: any) => {
          e.stopPropagation();
          setSendGuidelinesSuccess(false);
          onCloseModal(e);
        }}
        icon={ModalCloseIcon}
        // iconSize={"15px"}
        showClose={true}
        sx={{
          width: { xs: "95%", sm: "60%", md: "55%", lg: "35%", xl: "30%" },
          top: { lg: "8%" },
          maxWidth: "440px",
        }}
        style={{
          borderRadius: "4px",
          minHeight: "360px",
          justifyContent: "start",
          boxShadow: "var(--apn_box_shadow)",
          padding: "18.5px 22px 0px",
        }}
      >
        <Box
          onClick={(e: any) => e.stopPropagation()}
          display="flex"
          flexDirection="column"
          pt={0.5}
          alignItems="center"
          style={{ width: "100%", marginTop: "-22px" }}
        >
          <Box
            sx={{
              fontSize: "14px",
              color: "var(--text_color_5)",
              fontWeight: "var(--font_weight_4)",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "18.41px",
              padding: "18.5px 22px",
              width: "100%",
              textAlign: "center",
              boxShadow: "var(--apn_box_shadow)",
            }}
          >
            {apnRes.apnPopupHeading}
          </Box>

          {/* @ts-ignore */}
          <Lottie
            options={emailAnimationOptions}
            height={200}
            width={200}
            style={{ pointerEvents: "none" }}
          />
          <Box
            sx={{
              fontSize: "12px",
              color: "var(--text_color_5)",
              fontWeight: "var(--font_weight_1)",
              fontFamily: "var(--font_family_Medium)",
              lineHeight: "24px",
              margin: "40px 0px",
              textAlign: "center",
            }}
          >
            {isAndroid
              ? apnRes?.apn_andr_send_email_text
              : apnRes.apn_send_email_text}{" "}
            {customerDetail?.email
              ? customerDetail?.email
              : currentUser?.email}
            .
          </Box>
        </Box>
      </SimpleModal>
    );
  }

  return (
    <>
      {apnRes ? (
        <SimpleModal
          isShowModal={isShowModal}
          onCloseModal={onCloseModal}
          showClose={true}
          icon={ModalCloseIcon}
          iconSize={"15px"}
          sx={{
            width: { xs: "95%", sm: "60%", md: "55%", lg: "35%", xl: "35%" },
            top: { lg: "8%" },
            maxWidth: isAndroid ? "480px" : "440px",
            padding: "30px  0px !important",
          }}
          style={{
            borderRadius: "4px",
            minHeight: "480px",
            justifyContent: "start",
          }}
        >
          <Box
            onClick={(e: any) => e.stopPropagation()}
            display="flex"
            flexDirection="column"
            pt={0.5}
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Box
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                color: "var(--text_color_5)",
                fontWeight: "var(--font_weight_2)",
                fontFamily: "var(--font_family_Bold)",
                marginBottom: "10px",
                boxShadow: "var(--apn_box_shadow)",
                width: "100%",
                textAlign: "center",
                height: "55px",
                marginTop: "-32px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "0.1px solid rgba(0, 0, 0, 0.25)"
              }}
            >
              {apnRes.apnPopupHeading}
            </Box>
            <Box display="flex" width={"100%"}>
              <Box
                display="flex"
                p={1}
                pt={0.5}
                alignItems="center"
                justifyContent="space-between"
                width={"100%"}
              >
                <ArrowCircleLeftIcon
                  sx={{
                    color:
                      index === 0
                        ? "var(--primary_brand_color_light)"
                        : "var(--primary_color)",
                    cursor: index === 0 ? "not-allowed" : "pointer",
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    left: "10px",
                    top: "50%",
                    zIndex: 1,
                    opacity: index === 0 ? 0.5 : 1, // Adjust opacity to visually disable
                    pointerEvents: index === 0 ? "none" : "auto", // Disable pointer events
                  }}
                  onClick={handlePrev}
                />
                <Box sx={{ width: "100%" }}>
                  {index === 0 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {Screen1Icon ? (
                        <img src={Screen1Icon} alt="screen 1" />
                      ) : null}
                      {!isAndroid && (
                        <Lottie
                          options={screen1Animation}
                          height={50}
                          width={40}
                          isPaused={false}
                          isStopped={false}
                          style={{
                            position: "absolute",
                            top: "40px",
                            pointerEvents: "none",
                          }}
                        />
                      )}

                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Regular)",
                        }}
                      >
                        {apnRes.step1Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          lineHeight: isAndroid ? "24px" : "21px",
                          fontFamily: isAndroid ? "var(--font_family_Regular)": "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "10px 5px 0px",
                          width: isAndroid ? "90%" : "100%",
                        }}
                      >
                        {Screen1Heading}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          lineHeight: "20px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          margin: "5px 5px 10px",
                          color: "var(--text_color_5)",
                        }}
                      >
                        {Screen1SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 1 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      {Screen2Icon ? (
                        <img src={Screen2Icon} alt="screen 1" />
                      ) : null}
                      <Lottie
                        options={pointerAnimationOptions}
                        height={isAndroid ? 106 : 100}
                        width={isAndroid ? 106 : 100}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: isAndroid ? "137px" : "70px",
                          left: isAndroid ? "146px" : "196px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step2Text}
                      </Box>
                      <Box
                        sx={{
                          margin: "15px 5px 0px",
                          width: "80%",
                          fontSize: isAndroid ? "14px" : "12px",
                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                        }}
                      >
                        {Screen2SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 2 && (
                    <Box
                      sx={{
                        margin: "20px 0",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      {Screen3Icon ? (
                        <img src={Screen3Icon} alt="screen 1" />
                      ) : null}
                      {isAndroid && (
                        <Lottie
                          options={pointerAnimationOptions}
                          height={isAndroid ? 109 : 100}
                          width={isAndroid ? 109 : 100}
                          isPaused={false}
                          isStopped={false}
                          style={{
                            position: "absolute",
                            top: isAndroid ? "144px" : "150px",
                            left: isAndroid ? "206px" : "191px",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step3Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: isAndroid ? "14px" : "12px",
                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                        }}
                      >
                        {Screen3SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 3 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <img src={Screen4Icon} alt="screen 1" />
                      <Lottie
                        options={pointerAnimationOptions}
                        height={isAndroid ? 110 : 100}
                        width={isAndroid ? 110 : 100}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: isAndroid ? "31px" : "-9px",
                          left: isAndroid ? "157px" : "200px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step4Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: isAndroid ? "14px" : "12px",

                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                          width: "70%",
                        }}
                      >
                        {Screen4SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 4 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <img src={Screen5Icon} alt="screen 1" />
                      <Lottie
                        options={pointerAnimationOptions}
                        height={isAndroid ? 107 : 100}
                        width={isAndroid ? 107 : 100}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: isAndroid ? "56px" : "100px",
                          left: isAndroid ? "182px" : "167px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step5Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: isAndroid ? "14px" : "12px",

                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                          width: "66%",
                        }}
                      >
                        {Screen5SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 5 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <img
                        src={Screen6Icon}
                        alt="screen 1"
                        style={{ marginLeft: isAndroid ? "40px" : "0px" }}
                      />
                      <Lottie
                        options={pointerAnimationOptions}
                        height={isAndroid ? 108 : 100}
                        width={isAndroid ? 108 : 100}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: isAndroid ? "-4px" : "-2px",
                          left: isAndroid ? "243px" : "215px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step6Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: isAndroid ? "14px" : "12px",

                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                          width: "82%",
                        }}
                      >
                        {Screen6SubHeading}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "0px 5px 0px",
                        }}
                      >
                        {isAndroid ? null : apnRes.step6SubHeading2}
                      </Box>
                    </Box>
                  )}

                  {index === 6 && (
                    <>
                      {isAndroid ? (
                        <Box
                          sx={{
                            textAlign: "center",
                            "& P": {
                              fontSize: "12px",
                              lineHeight: "22px",
                              fontFamily: "var(--font_family_Regular)",
                              fontWeight: "var(--font_weight_0)",
                              color: "var(--text_color_5)",
                            },
                            "& li": {
                              fontFamily:
                                "var(--font_family_Medium) !important",
                              fontWeight: "var(--font_weight_1) !important",
                              color: "var(--black)  !important",
                              paddingLeft: "10px !important",
                              lineHeight: "22px !important",
                              fontSize: "12px !important",
                              "& span": {
                                wordBreak: "break-word",
                                fontFamily: "var(--font_family_Semibold)",
                                fontWeight: "var(--font_weight_2)",
                                color: "var(--black)",
                              },
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px !important",
                              fontFamily:
                                "var(--font_family_Semibold) !important",
                              fontWeight: "var(--font_weight_2) !important",
                              margin: "7px 0px 8px",
                            }}
                          >
                            {apnRes?.andriodStep7?.title}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              padding: "0px 12px 0px 38px",
                            }}
                          >
                            <Box
                              component={"span"}
                              sx={{
                                fontFamily:
                                  "var(--font_family_Semibold) !important",
                                fontWeight: "var(--font_weight_2) !important",
                                fontSize: "12px",
                                lineHeight: "22px",
                                color: "var(--text_color_5)",
                              }}
                            >
                              {apnRes?.andriodStep7?.noteTitle}
                            </Box>
                            <Typography
                              sx={{
                                fontFamily:
                                  "var(--font_family_Medium) !important",
                                fontWeight: "var(--font_weight_1) !important",
                                fontSize: "12px !important",
                                textAlign: "start !important",
                              }}
                            >
                              &nbsp;{apnRes?.andriodStep7?.noteDesc}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", sm: "row" },
                              padding: "0px 0px 0px 40px",
                            }}
                          >
                            <Box
                              component="ul"
                              sx={{
                                padding: "0px",
                              }}
                            >
                              {apnRes?.andriodStep7?.nameSettings?.length > 0 &&
                                apnRes?.andriodStep7?.nameSettings?.map(
                                  (settingsData: any, index: number) => (
                                    <Box
                                      component={"li"}
                                      sx={{
                                        maxWidth: "163px",
                                        wordBreak: "break-word",
                                        pb: "9px",
                                        textAlign: "start",
                                        "&::marker": {
                                          content: '"•"',
                                          textIndent: "0px !important",
                                          fontSize: "10px",
                                          color: "var(--black)",
                                        },
                                      }}
                                      key={index}
                                    >
                                      {settingsData?.key}
                                      <Box component={"span"}>
                                        {settingsData?.value}
                                      </Box>
                                    </Box>
                                  )
                                )}
                            </Box>
                            <Box
                              component="ul"
                              sx={{
                                marginLeft: "10px",
                                width: { xs: "100%", sm: "243px" },
                                padding: "0px",
                              }}
                            >
                              {apnRes?.andriodStep7?.apnSettingsInfo?.length >
                                0 &&
                                apnRes?.andriodStep7?.apnSettingsInfo?.map(
                                  (settingsData: any, index: number) => (
                                    <Box
                                      component={"li"}
                                      sx={{
                                        textAlign: "start",
                                        wordBreak: "break-word",
                                        pb: "9px",
                                        "&::marker": {
                                          content: '"•"',
                                          textIndent: "0px !important",
                                          fontSize: "10px",
                                          color: "var(--black)",
                                        },
                                      }}
                                      key={index}
                                    >
                                      {settingsData?.key}
                                      <Box component={"span"}>
                                        {settingsData?.value}
                                      </Box>
                                    </Box>
                                  )
                                )}
                            </Box>
                          </Box>
                          <Box
                            component="span"
                            sx={{
                              backgroundColor: "var(--apn_background_color)",
                              padding: "5px 14px",
                              borderRadius: "30px",
                              marginTop: "50px",
                              fontSize: "12px",
                              lineHeight: "18px",
                              color: "var(--black)",
                              fontWeight: "var(--font_weight_2)",
                              fontFamily: "var(--font_family_Semibold)",
                            }}
                          >
                            {apnRes.step7Text}
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            margin: "20px 0",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={qrCode}
                            width={150}
                            height={150}
                            alt="screen 1"
                          />

                          <Box
                            component="span"
                            sx={{
                              marginTop: "20px",
                              fontSize: "14px",
                              lineHeight: "21px",
                              fontWeight: "var(--font_weoght_4)",
                              color: "var(--text_color_5)",
                              fontFamily: "var(--font_family_Bold)",
                            }}
                          >
                            {apnRes.step7Heading}
                          </Box>
                          <Box
                            sx={{
                              width: "55%",
                              fontSize: "12px",
                              lineHeight: "22px",
                              fontFamily: "var(--font_family_Regular)",
                              fontWeight: "var(--font_weight_0)",
                              color: "var(--text_color_5)",
                              margin: "15px 5px",
                            }}
                          >
                            {apnRes.step7SubHeading}
                          </Box>

                          <Box
                            sx={{
                              fontSize: "25px",
                              marginBottom: "15px",
                            }}
                          >
                            <img src={Screen7DividerIcon} alt="screen 1" />
                          </Box>
                          <Box
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontFamily: "var(--font_family_Medium)",
                              fontWeight: "var(--font_weight_1)",
                              color: "var(--black)",
                            }}
                          >
                            {apnRes.step07BottomText}
                          </Box>
                          <Box
                            sx={{
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontFamily: "var(--font_family_Regular)",
                              fontWeight: "var(--font_weight_0)",
                              color: "var(--black)",
                            }}
                          >
                            {apnRes.step07BottomUrl}
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                  {index === 7 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <img src={Screen8Icon} alt="screen 1" />
                      <Lottie
                        options={pointerAnimationOptions}
                        height={120}
                        width={120}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: "-4px",
                          left: "187px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step8Text}
                      </Box>
                      <Box
                        sx={{
                          fontSize: isAndroid ? "14px" : "12px",
                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                          width: "82%",
                        }}
                      >
                        {Screen8SubHeading}
                      </Box>
                    </Box>
                  )}
                  {index === 8 && (
                    <Box
                      sx={{
                        margin: "20px 0px 0px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      <img src={Screen9Icon} alt="screen 1" />
                      <Lottie
                        options={pointerAnimationOptions}
                        height={170}
                        width={170}
                        isPaused={false}
                        isStopped={false}
                        style={{
                          position: "absolute",
                          top: "107px",
                          left: "156px",
                          pointerEvents: "none",
                        }}
                      />
                      <Box
                        component="span"
                        sx={{
                          backgroundColor: "var(--apn_background_color)",
                          padding: "5px 14px",
                          borderRadius: "30px",
                          marginTop: "50px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "var(--black)",
                          fontWeight: "var(--font_weight_2)",
                          fontFamily: "var(--font_family_Semibold)",
                        }}
                      >
                        {apnRes.step9Text}
                      </Box>
                      <Box
                        sx={{
                          width: "80%",
                          fontSize: "14px",
                          lineHeight: "22px",
                          fontFamily: "var(--font_family_Regular)",
                          fontWeight: "var(--font_weight_0)",
                          color: "var(--text_color_5)",
                          margin: "15px 5px 0px",
                        }}
                      >
                        {Screen9SubHeading}
                      </Box>
                    </Box>
                  )}
                  {currentLine?.emailId || isPrimaryEmailId ? (
                    <Box
                      sx={{
                        paddingTop: "20px",
                        display: "flex",
                        fontFamily: "var(--font_family_Semibold)",
                        fontSize: "16px",
                        fontWeight: "var(--font_weight_2)",
                        lineHeight: "20px",
                        textAlign: "right",
                        color: "var(--primary_color)",
                        cursor: "pointer",
                        justifyContent: "center",
                      }}
                      onClick={handleShareApnSettings}
                    >
                      {isSendGuidelinessLoader ? (
                        <>
                          <CircularProgress
                            sx={{ color: "var(--primary_color)" }}
                          />
                        </>
                      ) : (
                        <>
                          {apnRes.step1BottomCtaText}
                          <img
                            src={apnImages.apn_share_img.data.attributes.url}
                            style={{ paddingLeft: "5px" }}
                          />
                        </>
                      )}
                    </Box>
                  ) : null}
                </Box>
                <ArrowCircleRightIcon
                  sx={{
                    color:
                      index === ( totalCarouselPage - 1)
                        ? "var(--primary_brand_color_light)"
                        : "var(--primary_color)",
                    cursor:
                      index === (totalCarouselPage - 1)
                        ? "not-allowed"
                        : "pointer",
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    opacity: index === ( totalCarouselPage - 1) ? 0.5 : 1, // Adjust opacity to visually disable
                    pointerEvents: index === ( totalCarouselPage - 1) ? "none" : "auto", // Disable pointer events
                  }}
                  onClick={handleNext}
                />
              </Box>
            </Box>
          </Box>
        </SimpleModal>
      ) : null}
    </>
  );
}
