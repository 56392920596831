import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getCustomerSupportContent } from "../../../features/strapi/homePageSlice";
import { PurchaseFlow } from "../PlanCard/PlanCardsComponent/PurchaseFlow/PurchaseFlow";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";

const CustomerSupport = isTemplateExisits(Config.Home.CustomerService)
  ? require(`./${Config.Home.CustomerService.template}_CustomerService`).default
  : null;

export const CustomerService = () => {
  const { customerSupportContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const dispatch = useAppDispatch();
  const [cacheCustomerSupportContent, setCacheCustomerSupportContent] =
    useState<any>(null);
  useEffect(() => {
    getCache(
      "CustomerService",
      getCustomerSupportContent,
      setCacheCustomerSupportContent,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("CustomerService", customerSupportContent);
  }, [customerSupportContent]);
  return (
    <CustomerSupport
      customerSupportContent={
        cacheCustomerSupportContent
          ? cacheCustomerSupportContent
          : customerSupportContent
      }
    />
  );
};
