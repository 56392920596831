import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

type Props = {
  checked: boolean;
  handleSwitchToggle?: any;
  label?: any;
  isDisabled?: boolean;
  styles?: any;
  name?: string;
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--primary_color)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "var(--grey_shade_3)",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const SwitchCustIOS = (props: Props) => {
  const { checked, handleSwitchToggle, label, isDisabled, styles, name } =
    props;
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            name={name}
            checked={checked}
            onChange={handleSwitchToggle}
            inputProps={{ "aria-label": "controlled" }}
            disabled={isDisabled || false}
            sx={{ m: 1, ...styles }}
          />
        }
        label={
          <Typography
            sx={{ fontSize: 14, fontFamily: "var(--font_family_Bold)" }}
          >
            {label}
          </Typography>
        }
      />
    </FormGroup>
  );
};
