import { Box, Typography } from "@mui/material";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { RoutingURLs } from "../../../config/RoutingURLs";

type Props = {
  content: any;
};
const ForSupport = (props: Props) => {
  const { content } = props;
  const { support, support_timing } = content;

  const onClickHandler = (path: any) => {
    if (path === RoutingURLs.chatbot) {
      window.open(RoutingURLs.chatbot, "_self");
      setTimeout(() => {
        window.history.replaceState(null, "", location.pathname);
      }, 1000);
    } else {
      return false;
    }
  };
  return (
    <Box
      sx={{
        background: "var(--white) !important",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: {xs:"30px auto",md:"100px auto"},
          width: {xs:"94%", md: "70%"},
          flexDirection: {xs: "column", sm: "row"}
        }}
      >
        {support?.map((element: any) => {
          const { img, path, text, id, timing } = element;
          return (
            <Box
              component={"a"}
              sx={{
                display: "flex",
                justifyContent: {xs:"flex-start", md:"center"},
                alignItems: "center",
                gap: "15px",
                textDecoration: "none",
                padding: "10px",           
              }}
              key={id}
              href={path === RoutingURLs.chatbot ? undefined : path}
            >
              <Box
                component="img"
                src={img?.data?.attributes?.url}
                alt={img?.data?.attributes?.alternativeText}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => onClickHandler(path)}
              />
              <Box>
                <Typography
                  sx={{
                    fontFamily: "var(--font_family_Semibold)",
                    fontSize: "18px",
                    lineHeight: "24px",
                    color: "var(--black)",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() => onClickHandler(path)}
                >
                  {BrandNameUpdate(id !== support.length ? text : timing)}
                </Typography>
                {id !== support.length && (
                  <Typography
                    sx={{
                      fontFamily: "var(--font_family_Regular)",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "var(--text_color)",
                      width: "fit-content",
                      cursor: "pointer",
                    }}
                    onClick={() => onClickHandler(path)}
                    dangerouslySetInnerHTML={{
                      __html:
                      timing,
                    }}
                  >
                    {/* {timing} */}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ForSupport;
