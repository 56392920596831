import { useEffect } from "react";
import { DetailsCoverageProps } from "./DetailsCoverage";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import { Grid, Typography, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import styles from "./S5_DetailsCoverage.module.scss";
import { useNavigate } from "react-router-dom";

function DetailsCoverageCmp(props: DetailsCoverageProps) {
  let navigate = useNavigate();
  const { coverageCheckContent } = props;
  const { heading, back, iframeLink, iframeTitle, back_nav, sub_heading } =
    coverageCheckContent;
  const { details_coverage, iframeMapId } = styles;
  useEffect(() => {
    window.scroll(0, 0);
    let container = document.getElementById(iframeMapId);
    window.addEventListener(
      "message",
      function (e) {
        let message = e.data;
        if (message.map && message.height && container) {
          container.style.height = message.height + 350 + "px";
        }
      },
      false
    );
  }, []);
  return (
    <Grid container className={details_coverage} mt={{ xs: 6.8 }}>
      <Grid container direction="row" px={"40px"} py={"20px"}>
        <Grid
          item
          onClick={() => navigate(back_nav)}
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <ArrowBackIosIcon />
          &nbsp;
          {back}
        </Grid>
        <Grid item sx={{ mx: "auto" }}>
          <Typography
            variant="h4"
            lineHeight={2.5}
            sx={{ textAlign: "center", fontSize: "35px !important" }}
          >
            {/* {heading} */}
            Mobile coverage map
          </Typography>
          <Typography
            lineHeight={1.6}
            sx={{
              fontSize: "18px",
              fontFamily: "var(--font_family_Medium)",
              textAlign: "center",
            }}
          >
            {/* {sub_heading} */}
            Mexico/Canada coverage and International Calling/Roaming are
            currently unavailable.
          </Typography>
        </Grid>
      </Grid>

      <Grid container textAlign="center" mb={2.5}>
        <iframe
          id={iframeMapId}
          src={iframeLink}
          title={iframeTitle}
          loading="lazy"
        ></iframe>
      </Grid>
    </Grid>
  );
}

export default DetailsCoverageCmp;
