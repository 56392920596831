import styles from "./S1_NegResultBottom.module.scss";
import { Box, Typography } from "@mui/material";
import { ButtonCust } from "../../widgets";
import Config from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { gaEventTypes, gaScreenNames } from "../../../Types/GoogleAnalytics";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import { handleCheckout } from "../../../features/billCalculator/billCalculatorSlice";
import { PlanCard } from "../../home/PlanCard/PlanCardsComponent";

const T2_CompareResult = (p: any) => {
  const props = p?.content;
  const { pageHandler } = p || {};
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = currentdetails ? JSON.parse(currentdetails) : null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const GAAndFBEventsHandler = (categyName: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.BillCalculator,
      category: categyName,
      buttonValue: value,
    });
  };

  const onClickHandler = (
    navigationPath: string,
    scrollToPath: string,
    categyName: string,
    gaName: string
  ) => {
    navigate(navigationPath);
    setTimeout(function () {
      scroller.scrollTo(scrollToPath, {
        duration: 1000,
        delay: 0,
        offset: -85,
        smooth: true,
      });
    }, 1500);
    GAAndFBEventsHandler(categyName, gaName);
  };

  const { planData, saveAmt } = useAppSelector(
    (state: any) => state.billCalculator
  );
  const getStarted = () => {
    dispatch(handleCheckout({ isCheckout: true }));
  };
  return (
    <>
      <Box
        sx={{
          m: { xs: "0 15px", lg: "0 30px" },
        }}
      >
        {props?.back && (
          <Box
            className={styles.back_button}
            onClick={() => {
              pageHandler();
            }}
          >
            <ArrowBackIcon className={styles.arrow_icon} />
            {props?.back}
          </Box>
        )}
        <Box m={{ xs: 0, sm: "0px auto" }} width="100%">
          <Box
            textAlign={{ xs: "center" }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              my: {
                xs: `${props?.back ? 0 : "20px"}`,
                sm: `${props?.back ? 0 : "20px"}`,
              },
            }}
          >
            {props?.title ? (
              <Typography
                variant="h3"
                component={"h3"}
                fontSize="23px"
                className={styles.page_title}
              >
                {BrandNameUpdate(props?.title)}
              </Typography>
            ) : null}
            <Typography
              variant="body1"
              className={styles.sub_title}
              sx={{ lineHeight: "30px" }}
            >
              {props?.sub_title}
            </Typography>
            {/* <Box className={styles.page_line}></Box> */}
          </Box>
          <PlanCard
            planContent={props}
            plandata={planData}
            navigate={navigate}
          />
          {props?.save_text && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                fontSize: "14px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_1)",
              }}
              margin={{
                xs: "0 0 30px",
              }}
            >
              {planData?.addLineSplitPrice ? (
                <>
                  {props?.save_text}&nbsp;
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      color: "var(--text_color)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_2)",
                    }}
                    component="span"
                  >
                    {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}&nbsp;
                    {props?.per_line}
                  </Box>
                  &nbsp;{props?.every_line}
                </>
              ) : (
                <>
                  {props?.add_line}&nbsp;
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Semibold)",
                      color: "var(--text_color)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_2)",
                    }}
                    component="span"
                  >
                    {GetCurrencyFixedToTwo(saveAmt, planData?.currency)}
                    {props?.per_month_short}
                  </Box>
                </>
              )}
            </Box>
          )}
          {/* {!currentdetails?.simPaid && (
            <Box className={styles.btn}>
              <ButtonCust
                style={{ width: "100%" }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={getStarted}
              >
                {props?.btn_text}
              </ButtonCust>
            </Box>
          )} */}

          {!currentdetails?.simPaid && (
            <Box
              sx={{
                color: "var(--primary_color)",
                fontSize: "16px",
                fontFamily: "var(--font_family_Semibold)",
                cursor: "pointer",
                textAlign: "center",
                my: "20px",
              }}
              onClick={() =>
                onClickHandler(
                  Config?.IS_NAVIGATE_TO_PLANS_SECTION_FROM_BILLCALCULATOR
                    ? RoutingURLs.cellPhonePlans
                    : RoutingURLs.default,
                  "bestCellPhonePlan",
                  "Plan",
                  props.view_plans
                )
              }
            >
              {props.view_plans}
            </Box>
          )}
          {/* <Box
            className={styles.font_weight}
            textAlign={{ xs: "center" }}
            sx={{
              color: "var(--text_color)",
              fontSize: "14px",
              fontFamily: "var(--font_family_Medium)",
              marginBottom: "40px",
            }}
          >
            {props.compatibility_desc}
            <Box
              sx={{
                color: "var(--primary_color)",
                fontSize: "14px",
                fontFamily: "var(--font_family_Bold)",
                fontWeight: "var(--font_weight_5)",
                cursor: "pointer",
              }}
              component="span"
              onClick={() =>
                onClickHandler(
                  RoutingURLs.imeiCheck,
                  "",
                  "Compatibility",
                  props.check_compatibility
                )
              }
            >
              &nbsp; {props.check_compatibility}
            </Box>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default T2_CompareResult;
