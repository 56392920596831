import { Box, Grid, Paper, Typography } from "@mui/material";
import { ButtonCust } from "../../../widgets";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import styles from "./S6_InitialActivation.module.scss";
import clsx from "clsx";
import { ActivationStatus } from "../../../../enums";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";
import { InternalBanner } from "../../../common/InternalBanner";
import EnvConfig from "../../../../config/env.config.json";
export const InitialSecondayLine = (props: any) => {
  const { handleStep, currentLine, content, updatingCustomer } = props;
  const [displayFAQ, setDisplayFAQ] = useState(false);
  const letsGoHandler = () => {
    if (currentLine?.esim) {
      updatingCustomer({
        activationJourneyStatus: ActivationStatus.IN_PROGRESS,
      });
    }
    handleStep(true);
  };
  const {
    intro_btn,
    sec_title1,
    sec_title2,
    sec_desc1,
    sec_desc2,
    faq_txt,
    dataObject,
    infoData,
    intro_title,
    tick_icon,
    arrowRt_icon,
    esim_points,
    intro_desc2,
    intro_pnts,
    each_icon,
    flight_icon,
    back_txt,
  } = content || {};
  const introPoints = currentLine?.esim ? esim_points : infoData;

  return (
    <>
      {/* <InternalBanner
        // key={key}
        pageName={"MyAccount"}
        apiEndPoint={"myAccBanner"}
        type={"internal"}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: "var(--lightgrey_3)",
          height: "fit-content",
          minHeight: "88vh",
        }}
      >
        <Box
          display={{ xs: "none", md: "block" }}
          sx={{
            cursor: "pointer",
            // opacity: disableBack && "0.5",
            width: "24px",
            height: "24px",
            zIndex: "100",
            left: "0",
            position: "absolute",
            ml: { md: "30px", sm: "25px", xs: "25px" },
            mt: { md: "60px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "18px",
              height: "18px",
              position: "absolute",
              left: "3rem",
            }}
            onClick={() => handleStep(false)}
          >
            <ArrowBackIosIcon sx={{ width: "18px" }} />
            <Box>{back_txt}</Box>
          </Box>
        </Box>
        <KeyboardBackspaceIcon
          sx={{
            display: { xs: "block", sm: "none" },
            position: "absolute",
            left: "1.4rem",
            top: "6.1rem",
            color: "var(--white)",
            cursor: "pointer",
          }}
          onClick={() => handleStep(false)}
        />
        <Paper
          sx={{
            // border: "1px solid rgba(0,0,0,.2)",
            width: { xs: "90%", sm: "724px" },
            height: "fit-content",
            mt: { xs: "30px", sm: "60px" },
            boxShadow: { xs: "unset !important" },
            marginBottom: "100px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: { xs: "25px", sm: "31px" },
              pb: { xs: "16px", sm: "22px" },
            }}
          >
            <Box
              component="img"
              alt={"circular_loader"}
              src={flight_icon?.data?.[0]?.attributes?.url}
              sx={{ width: { xs: "33px", paddingRight: "16px" } }}
            />
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "24px" },
                fontFamily: "var(--font_family_Bold)",
              }}
            >
              {intro_title}
            </Typography>
          </Box>
          <hr style={{ border: "1px solid #F8F8F8" }}></hr>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "var(--font_family_Regular)",
                mt: { xs: "18px" },
                mb: { xs: "21px" },
                color: "var(--text_color)",
              }}
            >
              {intro_desc2}
            </Typography>
          </Box>
          <Box
            sx={{
              mx: currentLine?.esim ? "" : "auto",
              width: { xs: "90%", sm: currentLine?.esim ? "auto" : "60%" },
              pl: { xs: "15px" },
            }}
          >
            {introPoints?.map((data: any) => {
              return (
                <Box
                  className={styles.activation_info}
                  key={data.id}
                  sx={{
                    px: { xs: "0px", md: "65px" },
                    boxSizing: "border-box",
                  }}
                >
                  <img
                    className={styles.check_icon}
                    src={each_icon?.data?.[0]?.attributes?.url}
                    alt={each_icon?.data?.[0]?.attributes?.alternativeText}
                  />
                  <Typography
                    className={styles.activation_body_text}
                    sx={{
                      wordWrap: "break-word !important",
                      maxWidth: "100%",
                    }}
                  >
                    {BrandNameUpdate(data.desc)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <Box sx={{ textAlign: "center", mt: "25px", mb: "36px" }}>
            <ButtonCust
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              onClick={() => letsGoHandler()}
              sx={{
                width: { xs: "250px", sm: "250px", md: "250px" },
                height: { md: "50px !important" },
              }}
            >
              {intro_btn}
            </ButtonCust>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
