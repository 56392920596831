import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { pushTrackingEventsToGAAndFB } from "../../../../../utils/commonFunctions/GaAndFb";
import { AddressCheck } from "./AddressCheckFlow/AddressCheck";
import { CompatibilityCheck } from "./CompatibilityFlow/CompatibilityCheck";
import { FinalSuccessModal } from "./FinalDetailedModal/FinalSuccessModal";
import { PurchaseJourneyContent } from "../../../../../features/strapi/homePageSlice";
import Config from "../../../../../config/env.config.json";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { RoutingURLs } from "../../../../../config/RoutingURLs";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../../Types/GoogleAnalytics";
import EsimIMEI from "../../../../common/EsimIMEI/EsimIMEI";
type Props = {
  coverageModal?: any;
  setCoverageModal?: any;
  setShowCompatibilityModal?: any;
  showCompatibilityModal?: any;
  coverageError?: any;
  setCoverageError?: any;
  checkAddress?: any;
  setCheckAddress?: any;
  coverageMessage?: any;
  setCoverageMessage?: any;
  coverageAddress?: any;
  setCoverageAddress?: any;
  finalSuccessModal?: any;
  setFinalSuccessModal?: any;
  isFail?: any;
  setIsFail?: any;
  emailCompatability?: any;
  setEmailCompatability?: any;
  imeiNumber?: any;
  setImeiNumber?: any;
  isBYOD?: any;
  homeCompatibilityComp?: any;
  setShowLoader?: any;
  fromPostAct?: any;
  handleAddLine?: any;
};

export const PurchaseFlow = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { purchaseContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [signal4G, setSignal4G] = useState("");
  const [signal5G, setSignal5G] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [imeiErr, setImeiErr] = useState("");
  const [name, setName] = useState("");
  const [imeiErrorMessage, setImeiErrorMessage] = useState("");
  const [isIMEISkipped, setIsIMEISkipped] = useState(false);
  const [purchasejourney, setPurchaseJourney] = useState(null);
  const [imeiData, setImeiData] = useState({
    carriers: [],
    makeModel: "",
    mode: "",
    wifiCalling: "",
    isLostOrStolen: "",
  });

  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
      dispatch(PurchaseJourneyContent());
    } else {
      setPurchaseJourney(purchaseContent?.data?.data?.attributes);
    }
  }, [purchaseContent]);

  const checkAgain = () => {
    props.setFinalSuccessModal(false);
    props.setCoverageAddress({
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });
    props.setCheckAddress("");
    setSignal4G("");
    setSignal5G("");
    props.setCoverageError(false);
    setIsIMEISkipped(false);
    props.setEmailCompatability("");
    props.setImeiNumber("");
    setName("");
    setEmailErr("");
    setImeiErr("");
    if (Config.ADDRESS_CHECK_DURING_PURCHASE || Config.IS_ESIM_SUPPORTED)
      props.setCoverageModal(true);
    else props.setShowCompatibilityModal(true);
    props.setCoverageModal(true);
  };
  const closeModalHandler = () => {
    setName("");
    setEmailErr("");
    setImeiErr("");
    props.setCoverageModal(false);
    props.setCoverageError(false);
    props.setShowCompatibilityModal(false);
    props.setCheckAddress && props.setCheckAddress("");
    setSignal4G("");
    setSignal5G("");
  };
  const skipImeiCheck = () => {
    props.setShowCompatibilityModal(false);
    props.setFinalSuccessModal(true);
    setIsIMEISkipped(true);
  };

  const getFromPostActOrNot = () => {
    if (props.fromPostAct) {
      return true;
    } else {
      return false;
    }
  };

  const getFromHomePageOrNot = () => {
    if (props.fromPostAct) {
      return false;
    } else {
      return true;
    }
  };
  const seeOurPlan = (_: any) => {
    props.setShowCompatibilityModal(false);
    gaEventTypes.event,
      gaEventTypes.click,
      {
        appName: Config.brand,
        screenName: gaScreenNames.home,
        category: gaCategory.plan,
        buttonValue: purchaseContent?.imei_success_btn,
      };
    navigate(RoutingURLs.default);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 500,
        delay: 0,
        offset: -100,
        smooth: "easeInOutQuart",
        smooh: "easeOutQuart",
      });
    }, 1000);
  };
  return (
    <>
      {props.coverageModal && (
        <AddressCheck
          navigate={navigate}
          setShowCompatibilityModal={props.setShowCompatibilityModal}
          setCoverageError={props.setCoverageError}
          setCoverageMessage={props.setCoverageMessage}
          setCoverageAddress={props.setCoverageAddress}
          setCheckAddress={props.setCheckAddress}
          setSignal4G={setSignal4G}
          setSignal5G={setSignal5G}
          signal4G={signal4G}
          signal5G={signal5G}
          setCoverageModal={props.setCoverageModal}
          coverageAddress={props.coverageAddress}
          coverageError={props.coverageError}
          coverageMessage={props.coverageMessage}
          checkAddress={props.checkAddress}
          coverageModal={props.coverageModal}
          closeModalHandler={closeModalHandler}
          setFinalSuccessModal={props.setFinalSuccessModal}
          skipImeiCheck={skipImeiCheck}
          addressContent={purchasejourney}
          checkAgain={checkAgain}
        />
      )}
      {Config.IS_ESIM_SUPPORTED &&
        props.showCompatibilityModal &&
        !props.homeCompatibilityComp && (
          <EsimIMEI
            showCheckIMEIModal={props.showCompatibilityModal}
            hideCheckIMEIModal={() => {
              props.setShowCompatibilityModal(false);
            }}
            fromPostAct={getFromPostActOrNot()}
            fromHomePage={getFromHomePageOrNot()}
            changePreferenceHandler={() => {}}
            setShowLoader={props.setShowLoader}
            handleAddLine={props.handleAddLine}
          />
        )}
      {!Config.IS_ESIM_SUPPORTED && props.showCompatibilityModal && (
        <CompatibilityCheck
          isBYOD={props.isBYOD}
          showCompatibilityModal={props.showCompatibilityModal}
          setShowCompatibilityModal={props.setShowCompatibilityModal}
          closeModalHandler={closeModalHandler}
          imeiErr={imeiErr}
          emailCompatability={props.emailCompatability}
          imeiNumber={props.imeiNumber}
          emailErr={emailErr}
          skipImeiCheck={skipImeiCheck}
          seeOurPlan={seeOurPlan}
          setImeiNumber={props.setImeiNumber}
          setEmailCompatability={props.setEmailCompatability}
          imeiErrorMessage={imeiErrorMessage}
          setImeiData={setImeiData}
          setFinalSuccessModal={props.setFinalSuccessModal}
          setImeiErrorMessage={setImeiErrorMessage}
          setEmailErr={setEmailErr}
          setImeiErr={setImeiErr}
          name={name}
          setName={setName}
          isFail={props.isFail}
          setIsFail={props.setIsFail}
          imeiContent={purchasejourney}
        />
      )}
      {Config.IS_ESIM_SUPPORTED &&
        props.showCompatibilityModal &&
        props.homeCompatibilityComp && (
          <CompatibilityCheck
            isBYOD={props.isBYOD}
            showCompatibilityModal={props.showCompatibilityModal}
            setShowCompatibilityModal={props.setShowCompatibilityModal}
            closeModalHandler={closeModalHandler}
            imeiErr={imeiErr}
            emailCompatability={props.emailCompatability}
            imeiNumber={props.imeiNumber}
            emailErr={emailErr}
            skipImeiCheck={skipImeiCheck}
            seeOurPlan={seeOurPlan}
            setImeiNumber={props.setImeiNumber}
            setEmailCompatability={props.setEmailCompatability}
            imeiErrorMessage={imeiErrorMessage}
            setImeiData={setImeiData}
            setFinalSuccessModal={props.setFinalSuccessModal}
            setImeiErrorMessage={setImeiErrorMessage}
            setEmailErr={setEmailErr}
            setImeiErr={setImeiErr}
            name={name}
            setName={setName}
            isFail={props.isFail}
            setIsFail={props.setIsFail}
            imeiContent={purchasejourney}
          />
        )}
      {!props.isBYOD && props.finalSuccessModal && (
        <FinalSuccessModal
          finalSuccessModal={props.finalSuccessModal}
          signal5G={signal5G}
          signal4G={signal4G}
          isIMEISkipped={isIMEISkipped}
          imeiData={imeiData}
          checkAgain={checkAgain}
          setFinalSuccessModal={props.setFinalSuccessModal}
          navigate={navigate}
          finalContent={purchasejourney}
        />
      )}
    </>
  );
};
