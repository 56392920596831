import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { configInfoCall } from "../../../features/account/accountSlice";
import AccountInfoComponent from "../Account/AccountInfo/AccountInfo";
import EnvConfig from "../../../config/env.config.json";
import { CustomerStatus } from "../../../enums";

const T2_SettingsCards = (props: any) => {
  const { configInfo } = useAppSelector((state: any) => state.account);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const keys =
      "ENABLE_FLAT_REFERRAL,REFERRER_DISC_IN_DOLLAR,REFEREE_DISC_IN_DOLLAR,DELAY_REFERRAL_COUPON_BY_MONTHS,AUTO_TOP_UP_SIZE,AUTO_TOP_UP_AMOUNT,PRORATE_DATA_MIN_LIMIT_MB,STANDARD_BILL_DAY,ENABLE_DATA_PRORATE,IR_MIN_CREDIT_AMOUNT,IR_PAYGO_CREDIT_LIMIT,IR_MIN_COVER_CREDIT_AMOUNT,IR_MAX_CREDIT_AMOUNT,IR_INCR_CREDIT_AMOUNT,BILL_DUE_DAYS";
    dispatch(
      configInfoCall({
        configId: "PROV_CONFIG",
        configKeys: keys,
        topupPulseDetails: true,
        mvne: EnvConfig.OPERATOR,
        intlCountriesStatus: 1,
      })
    );
  }, []);
  let customerDetails: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );

  return (
    <Box>
      {props?.content?.title && (
        <Box
          sx={{
            color: "var(--text_color_11)",
            fontSize: { xs: "24px", md: "36px" },
            fontFamily: "var(--font_family_Light)",
            padding: "40px 0 24px",
            lineHeight: "50px",
            textAlign: "center",
          }}
        >
          {props?.content?.title}
        </Box>
      )}
      {configInfo?.configs?.ACCOUNT_LOCK_UNLOCK_FEATURE_ENABLED && customerDetails.status === CustomerStatus.ACTIVE? (
        <AccountInfoComponent />
      ) : (
        <Box
          sx={{
            mt: "32px",
            mb: "32px",
            display: { xs: "block", sm: "flex" },
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mx: { xs: "auto", sm: "16px", md: "auto" },
            width: { xs: "95%", sm: "95%", md: "800px" },
          }}
        >
          <Skeleton variant="rounded" width="100%" />
          <Skeleton animation="wave" width="100%" />
          <Skeleton animation="wave" width="100%" />
        </Box>
      )}
    </Box>
  );
};
export default T2_SettingsCards;
