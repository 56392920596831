import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { getPaymentLandingContent } from "../../../features/strapi/paymentLandingSlice";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";

const PaymentLandingTemplate = isTemplateExisits(
  Config.PaymentLanding.PaymentLanding
)
  ? require(`./${Config.PaymentLanding.PaymentLanding.template}_PaymentLanding`)
      .default
  : null;

export type paymentLandingProps = {
  content?: any;
  shipmentType: string;
  loader: boolean;
};

export const PaymentLandingComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { paymentLandingContent } = useAppSelector(
    (state: any) => state.strapi.paymentLanding
  );
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);

  const [cacheContent, setCacheContent] = useState<any>(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getCache(
      "PaymentLanding",
      getPaymentLandingContent,
      setCacheContent,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData(
      "PaymentLanding",
      paymentLandingContent?.data?.data?.attributes
    );
  }, [paymentLandingContent]);
  return (
    (cacheContent || paymentLandingContent) && (
      <PaymentLandingTemplate
        content={
          cacheContent
            ? cacheContent
            : paymentLandingContent?.data?.data?.attributes
        }
        loader={loader}
      />
    )
  );
};
