import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";
import { NLContentStrapi } from "../../../features/strapi/nutritionLabelSlice";

type Props = {
  NLContent?: any;
  plan: any;
  planPerLinesCost: any;
  minShipvalue: any;
  maxShipvalue: any;
};
const NLCardComp = isTemplateExisits(Config.NutritionLabelPage.NLCard)
  ? require(`./${Config.NutritionLabelPage.NLCard.template}_NLCard`).default
  : null;

export const NLCard = (props: Props) => {
  const { plan, planPerLinesCost, minShipvalue, maxShipvalue } = props;

  //     title: "Broadband Facts",
  //     brandname: "Breezeline",
  //     disclosure: "Mobile Broadband Consumer Disclosure",
  //     mprice_heading: "Monthly Price",
  //     add_charges:
  //       "Additional Charges & Terms <span style='font-size: 12px;font-family:var(--font_family_medium) !important}}'>may apply</span>",
  //     monthly_fee_heading: "Provider Monthly Fees",
  //     cost_rec_heading: "Cost Recovery Fee",
  //     cost_rec_value: "6% of total invoice",
  //     one_time_fee_heading: "One-time Fees at the Time of Purchase",
  //     act_fee_heading: "Activation Fee",
  //     act_fee_value: "per line",
  //     sim_ship_heading: "SIM Card Shipping Fee",
  //     add_charges_desc:
  //       "Features, such as data top-ups, international long distance calling, and roaming, are available at additional charge. Please visit <a href='https://breezeline.com' target='_blank' style='text-decoration:underline;color:var(--text_color)'>breezeline.com</a> for details. ",
  //     termination_fee_heading: "Early Termination Fee",
  //     termination_fee_value: "N/A",
  //     govt_taxes_heading: "Government Taxes",
  //     govt_taxes_value: "Varies by Location",
  //     discount_heading: "Discounts & Bundles",
  //     discount_desc:
  //       "A discount of $5 per month will apply on your Internet service when you enroll with Breezeline Mobile. An additional $5 per month will be applied if you also have TV or Home Phone services on your account (for a total discount of $10).  Mobile plan pricing includes a $15 “Breezeline Internet Subscriber” Discount.",
  //     discount_link: "Click Here",
  //     discount_more_text: "for more",

  //     speeds_title: "Speeds Provided with Plan",
  //     download_speed_heading: "Typical Download Speed",
  //     download_speed_value: "1000 Mbps",
  //     upload_speed_heading: "Typical Upload Speed",
  //     uplload_speed_value: "50 Mbps",
  //     latency_heading: "Typical Latency",
  //     latency_value: "1 Ms",
  //     data_mp_heading: "Data Included with Monthly Price",
  //     data_mp_value: "N/A",
  //     add_charges_data_heading: "Charges for Additional Data Usage",
  //     add_charges_data_value: "N/A",
  //     net_manage_heading: "Network Management",
  //     net_manage_url: "Read our Policy",
  //     privacy_heading: "Privacy",
  //     privacy_url: "Read our Policy",
  //     cust_support_title: "Customer Support",
  //     contact_us_heading: "Contact Us:",
  //     contact_us_url: "breezeline.com/support",
  //     contact_no: "888-536-9600",
  //     fcc_desc:
  //       "Learn more about the terms used on this label by visiting the Federal Communications Commission Consumer Resource Center",
  //     fcc_url: "fcc.gov/consumer",
  //     UPI: "Unique Plan Identifier:",
  //   };
  const dispatch = useAppDispatch();
  const [cacheData, setCacheData] = useState<any>(null);
  const { nutritionLabelData } = useAppSelector(
    (state: any) => state?.strapi?.nutritionLabel || {}
  );

  useEffect(() => {
    getCache("NutritionLabel", NLContentStrapi, setCacheData, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("NutritionLabel", nutritionLabelData);
  }, [nutritionLabelData]);

  return (
    (nutritionLabelData || cacheData) && (
      <NLCardComp
        NLContent={cacheData ? cacheData.NL_Data : nutritionLabelData.NL_Data}
        plan={plan}
        planPerLinesCost={planPerLinesCost}
        minShipvalue={minShipvalue}
        maxShipvalue={maxShipvalue}
      />
    )
  );
};
