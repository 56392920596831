import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { getSupportContent } from "../../../features/strapi/homePageSlice";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";

const SupportComponent = isTemplateExisits(Config.Home.Support)
  ? require(`./${Config.Home.Support.template}_Support`).default
  : null;

export type SupportTempProps = {
  content?: any;
};

export const Support = () => {
  const dispatch = useAppDispatch();
  const [cacheSupportContent, setSupportContent] = useState<any>();
  const { supportContentSuccess } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  useEffect(() => {
    getCache(
      "HomeCustomerSupport",
      getSupportContent,
      setSupportContent,
      dispatch
    );
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("HomeCustomerSupport", supportContentSuccess);
  }, [supportContentSuccess]);

  return (
    (cacheSupportContent || supportContentSuccess) && (
      <SupportComponent
        content={
          cacheSupportContent ? cacheSupportContent : supportContentSuccess
        }
      />
    )
  );
};
