import { Box, Typography } from "@mui/material";
import {
  Divider,
  TextFieldCust,
  ButtonCust,
  SimpleModal,
} from "../../../widgets";
import styles from "./NewNumberModal.module.scss";
import { useAppSelector } from "../../../../app/hooks";
import { gaCategory, gaScreenNames } from "../../../../Types/GoogleAnalytics";
import Config from "../../../../config/env.config.json";
import { gtmTagManager } from "../../../../utils";

type InewNumberProps = {
  open: boolean;
  onClose: any;
  content: any;
  GAAndFBEventsHandler: any;
  zipCodeformik: any;
  zipcodeChangeHandler: any;
};

export const NewNumberModal = (props: InewNumberProps) => {
  const {
    open,
    onClose,
    content,
    GAAndFBEventsHandler,
    zipCodeformik,
    zipcodeChangeHandler,
  } = props;

  const { zipCodeStatus, zipCodeLoader } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { title, desc, zc_ph, zc_btn, msgs } = content?.zp_md;
  const { isValid } = zipCodeStatus || {};
  const closeIconSt = {
    position: "absolute",
    right: "-15px",
  };
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      className={styles.new_num_modal}
      sx={{
        borderRadius: "4.8px",
        maxWidth: { xs: "95%", sm: "500px" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      closeIconSt={closeIconSt}
    >
      <Box className={styles.dialog_main}>
        <Typography className={styles.dialog_heading}>{title}</Typography>
        {/* <Divider /> */}
        <Typography
          sx={{
            fontFamily: "var(--font_family_Regular)",
            fontSize: "16px",
            mb: "25px",
            textAlign: "center",
          }}
          width={{ xs: "100%", sm: "75%" }}
        >
          {desc}
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          className={styles.textField_limit_container}
        >
          <Box sx={{ width: { xs: "100%", sm: "75%" }, alignSelf: "center" }}>
            <TextFieldCust
              placeholder={zc_ph}
              maxlength={5}
              name="zipCode"
              value={zipCodeformik.values.zipCode}
              onChange={(e) => zipcodeChangeHandler(e)}
              error={
                zipCodeformik.errors?.zipCode || isValid === false
                  ? true
                  : false
              }
              helperText={
                zipCodeformik.errors?.zipCode ||
                (isValid === false && msgs?.invalid_msg)
              }
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  zipCodeformik.handleSubmit();
                }
              }}
            />
          </Box>
          <Typography
            sx={{ alignSelf: "center", width: { xs: "100%", sm: "75%" } }}
            className={styles.limit_text}
          >
            {zipCodeformik.values.zipCode?.length}/5
          </Typography>
        </Box>
        <ButtonCust
          variantType={Config.PRIMARY_BUTTON_TYPE}
          sx={{ padding: "13px 23px", height: "50px !important" }}
          onClick={() => {
            zipCodeformik.handleSubmit();
            GAAndFBEventsHandler(gaCategory.activation, "Check ZIP Code");
            gtmTagManager({
              event: gaScreenNames.zipCompleted,
            });
          }}
          disabled={
            zipCodeformik.values?.zipCode.length === 0
              ? true
              : !zipCodeformik?.isValid
          }
          loading={zipCodeLoader}
        >
          {zc_btn}
        </ButtonCust>
      </Box>
    </SimpleModal>
  );
};
