import React from "react";
import { NLProps } from "./NutritionLabel";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { getResolutionDetails } from "../../../../utils/commonFunctions";

import { SwitchCustIOS } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import { NLCard } from "../../../common/NLCard/NLCard";

const T1_NutritionLabel = (props: NLProps) => {
  const {
    plandata,
    selectedLine,
    priceCalculationHandler,
    tabValue,
    maxShipvalue,
    minShipvalue,
    showNutritionLabels,
    setShowNutritionLabels,
    setTabValue,
    NLContent,
  } = props;
  const { mobile } = getResolutionDetails();

  return EnvConfig.IS_NUTRITION_LABEL_REQUIRED ? (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          mr="10px"
          sx={{ fontSize: "14px", fontFamily: "var(--font_family_Medium)" }}
        >
          {NLContent?.plan_features?.switch_title}
        </Typography>
        <SwitchCustIOS
          styles={{
            mr: "0px",
            color: "var(--sky_blue)",
          }}
          checked={showNutritionLabels}
          handleSwitchToggle={(e: any) => {
            setShowNutritionLabels(!showNutritionLabels);
          }}
        />
      </Box>

      {showNutritionLabels && (
        <>
          {mobile ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Tabs
                value={tabValue}
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setTabValue(newValue);
                }}
                aria-label="wrapped label tabs example"
                sx={{
                  backgroundColor: "var(--nutrition_label_mb_bg)",
                  borderRadius: "50px",
                  marginTop: "10px",
                  "& .MuiTabs-indicator": {
                    backgroundColor: "transparent !important",
                  },
                  "& .Mui-selected": {
                    color: "white !important",
                    backgroundColor:
                      "var(--nutrition_label_selected_bg) !important",
                    borderRadius: " 30px !important",
                  },
                }}
              >
                {plandata?.map((p: any, i: any) => {
                  return (
                    <Tab
                      value={p?.name}
                      label={p?.planLabelDisplayNameApp}
                      wrapped
                      sx={{
                        fontFamily: "var(--font_family_Bold)",
                        fontSize: "18px",
                      }}
                    />
                  );
                })}
              </Tabs>
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              gap: "40px",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              margin: { xs: "30px", sm: "0px" },
            }}
          >
            {plandata?.map((p: any, i: any) => {
              const planPerLinesCost = priceCalculationHandler(p, selectedLine);
              return mobile ? (
                tabValue === p.name && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: { xs: "0px", sm: "20px" },
                    }}
                    key={p.name + i}
                  >
                    {!mobile ? (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "var(--font_family_Bold)",
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          {`${p?.planData} GB`} {p?.displayName}
                        </Typography>
                      </Box>
                    ) : null}

                    <NLCard
                      plan={p}
                      planPerLinesCost={planPerLinesCost}
                      minShipvalue={minShipvalue}
                      maxShipvalue={maxShipvalue}
                    />
                  </Box>
                )
              ) : (
                <NLCard
                  plan={p}
                  planPerLinesCost={planPerLinesCost}
                  minShipvalue={minShipvalue}
                  maxShipvalue={maxShipvalue}
                />
              );
            })}
          </Box>
        </>
      )}
    </Box>
  ) : null;
};

export default T1_NutritionLabel;
