import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Typography,
} from "@mui/material";
import {
  ButtonCust,
  NotificationCust,
  SimpleModal,
  TextFieldCust,
} from "../../../widgets";
import styles from "./S1_LineComponent.module.scss";
import EditIcon from "@mui/icons-material/EditOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Store } from "react-notifications-component";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import { LineComponentProps } from "./LineComponent";
import { CustomerStatus } from "../../../../enums";
import {
  displayPhoneNumberFormat,
  getNumberWithOrdinal,
} from "../../../../utils/commonFunctions/ReusableFunctions";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useEffect, useState } from "react";
import { updateSecondaryUser } from "../../../../features/checkout/checkoutSlice";
import clsx from "clsx";
import {
  activateLine,
  clearStepsData,
} from "../../../../features/activation/activationSlice";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { SimTypes } from "../../../../features/checkout/Types";
import { activationStatusCheck } from "../../../../features/activation/activationSlice";
import { PortingDialogue } from "../../PAHome/ActivationStatus/PortingDialog";
import { CarrierTypes } from "../../../../enums";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

const GAAndFBEventsHandler = (category: string, value: string) => {
  pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
    appName: EnvConfig.brand,
    screenName: gaScreenNames.account,
    category: category,
    buttonValue: value,
  });
};
const SingleLine = (props: any) => {
  const {
    lineimage,
    lineimg,
    linenumber,
    linemail,
    statusimg,
    status,
    handleClickopen,
    handleActivateModal,
    eachUser,
    content,
    orderId,
    setSecCustomerPayload,
    groupData,
    data,
    portingFailedUsers,
  } = props;

  const { group } = useAppSelector((state: any) => state.account);
  const primaryStatus = group?.data?.find(
    (item: any) => item?.isPrimary
  )?.status;
  const { activationModals } = useAppSelector(
    (state: any) => state.strapi.postActivation || {}
  );
  const { notifications: notificationMsg } = activationModals || {};
  const {
    zipCode,
    accNum,
    verizonPin,
    portingFailCC,
    pinNum,
    acivationFailCC,
    portReject1,
    portReject2,
    newNumSuc,
    portSuccess,
  } = activationModals || {};
  const { activationStatus } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { getCustomer } = useAppSelector((state: any) => state?.checkout || {});
  const { mnpInfo } = getCustomer || {};
  const { oldNumber, operator, acctNum, oldZip } = mnpInfo || {};
  const { password } = activationStatus || {};
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modalName, setModalName] = useState<any>("");
  const [modalData, setModalData] = useState<any>({});
  const [buttonLoaderFg, setButtonLoader] = useState<boolean>(false);
  const handleActivateSecondary = (user: any) => {
    localStorage.setItem("secondaryCustomerDetail", JSON.stringify(user));
    dispatch(updateSecondaryUser(user));
    dispatch(clearStepsData());
    navigate(RoutingURLs.activation);
  };
  const user = portingFailedUsers?.find((el: any) => el?.id === eachUser?.id);
  const handleUpdate = (e: any) => {
    e.stopPropagation();
    if (user?.portFailureCode === 1011) {
      setModalName("zipCode");
      setModalData(zipCode);
    } else if (user?.portFailureCode === 1010) {
      if (
        CarrierTypes?.VERIZON.toLowerCase() === user?.oldCarrier?.toLowerCase()
      ) {
        setModalName("verizonPin");
        setModalData(verizonPin);
      } else {
        setModalName("pinNum");
        setModalData(pinNum);
      }
    } else if (user?.portFailureCode === 1009) {
      setModalName("accNum");
      setModalData(accNum);
    } else if (user?.portFailureCode === 1017) {
      const oldCarrierName = user?.oldCarrier?.toLowerCase();
      if (
        oldCarrierName === CarrierTypes?.ATT ||
        oldCarrierName === CarrierTypes?.ATANDT
      ) {
        setModalName("portRejected");
        setModalData(portReject1);
      } else {
        setModalName("portRejected");
        setModalData(portReject2);
      }
    }
  };
  const handleCloseActivationModel = (e: any) => {
    setModalData({});
    setModalName("");
  };

  const activationPayload: any = {
    customerId: eachUser?.id,
    reachPlanId: eachUser?.reachPlanId,
    iccId: eachUser?.simId,
    imei: eachUser?.imei,
    make: eachUser?.make,
    model: eachUser?.model,
    isPort: true,
    numberToPort: eachUser?.mnpInfo?.oldNumber,
    oldCarrier: eachUser?.mnpInfo?.operator,
    portUpdate: true,
    oldCarrierAccountNumber: eachUser?.mnpInfo?.acctNum,
    oldZip: eachUser?.oldZip,
  };

  const submitHandler = (val: any) => {
    setButtonLoader(true);

    const payload: any = {
      ...activationPayload,
      password: val,
    };

    const pinPayload = { ...payload, password: val };
    const zipcodePayload = {
      ...payload,
      oldZip: val,
      password: password,
    };
    const accountPayload = {
      ...payload,
      oldCarrierAccountNumber: val,
      password: password,
    };
    let py: any = {};
    //error code  1011
    if (modalName === "zipCode") {
      py = zipcodePayload;
    }
    //error code 1010
    else if (modalName === "verizonPin" || modalName === "pinNum") {
      py = pinPayload;
    }
    //error code 1009
    else if (modalName === "accNum") {
      py = accountPayload;
    }
    callActivation(py);
  };
  const notificationSection = (st: number, msg?: string) => {
    if (st === 9) {
      NotificationCust({
        message: msg ?? notificationMsg.message1,
        type: "info",
        duration: 0,
        id: "portInProgressNoti",
        showIcon: true,
      });
    } else if (st === 1) {
      NotificationCust({
        message: msg ?? notificationMsg.message2,
        type: "info",
        duration: 0,
        id: "actInProgressNoti",
        showIcon: true,
      });
    }
    if (st !== 9) {
      Store.removeNotification("portInProgressNoti");
    }
    if (st !== 1) {
      Store.removeNotification("actInProgressNoti");
    }
  };
  const callActivation = (py: any) => {
    dispatch(activateLine({ payload: py })).then((res: any) => {
      setButtonLoader(false);
      if (res?.payload?.data?.status === "SUCCESS") {
        if (eachUser?.isMNP === true) {
          notificationSection(CustomerStatus.PORTING_IN_PROGRESS);
        } else {
          notificationSection(CustomerStatus.ACTIVATION_IN_PROGRESS);
        }
        setModalName("");
      } else if (res.payload?.status === "FAILURE") {
        setModalName("cc");
        setModalData(acivationFailCC);
      }
    });
  };

  return (
    <>
      <Box
        className={styles.main}
        sx={{
          width: { sm: "92%", xs: "92%", md: "50%" },
          height: {md: "80px"},
          cursor: `${eachUser?.isPrimary && "pointer"}`,
        }}
        onClick={() => {
          eachUser && dispatch(updateSecondaryUser(eachUser));
          GAAndFBEventsHandler(gaCategory.manageLines, lineimg);
          if (eachUser?.isPrimary) {
            navigate(RoutingURLs.profile);
          } else if (!eachUser?.isPrimary && eachUser?.emailId) {
            if (eachUser?.status === CustomerStatus.PORT_CANCELLED) {
              eachUser?.esim
                ? navigate(RoutingURLs.activateEsim)
                : navigate(RoutingURLs.trackOrder);
            } else {
              handleClickopen(eachUser);
            }
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <Box
            component="img"
            className={styles.img}
            src={lineimage}
            alt={content?.active_img?.data?.attributes?.alternativeText}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box className={styles.owner}>
              {lineimg}&nbsp;
              {eachUser && (
                <Box component="span" className={styles.simType}>
                  {eachUser && eachUser?.esim ? "(eSIM)" : "(Physical SIM)"}
                </Box>
              )}
              {data && (
                <Box component="span" className={styles.simType}>
                  {data?.simPreference === SimTypes.phySim
                    ? "(Physical SIM)"
                    : "(eSIM)"}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              {linenumber && (
                <Typography className={styles.owner_mail}>
                  {linenumber} | &nbsp;
                </Typography>
              )}
              {linemail && (
                <Typography className={styles.owner_mail} pr={"42px"}>
                  {linemail}
                </Typography>
              )}
            </Box>
            {user && (
              <Typography sx={{ color: "var(--danger)", fontSize: "12px" }}>
                {user?.portFailReachMsg}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            cursor: "pointer",
          }}
        >
          {status === CustomerStatus.ACTIVE &&
          eachUser.esim &&
          !eachUser.eSimSetup ? (
            <Typography
              style={{
                cursor: "pointer",
                color: "var(--dusk)",
              }}
              className={styles.active}
              onClick={() => {
                const secondaryCustomerStore = groupData?.find(
                  (item: { id: any }) => item.id === eachUser.id
                );
                localStorage.setItem(
                  "secondaryUserId",
                  JSON.stringify(secondaryCustomerStore?.id)
                );
                navigate(RoutingURLs?.steps);
              }}
            >
              {content?.confirm_act}
            </Typography>
          ) : status === CustomerStatus.ACTIVE ? (
            <>
              <Box
                component="img"
                className={styles.img_active}
                sx={{
                  margin: { xs: "0px 5px 0px 15px", md: "5px 10px 0px 0px" },
                }}
                src={statusimg}
                alt={content?.active_img?.data?.attributes?.alternativeText}
              />
              <Typography className={styles.active}>
                {content?.active}
              </Typography>
            </>
          ) : status === CustomerStatus.DISCONNECTED ? (
            <Typography className={styles.active}>
              {content?.disconnected}
            </Typography>
          ) : status === CustomerStatus.SUSPENDED ? (
            <Typography className={styles.active}>
              {content?.suspended}
            </Typography>
          ) : status === CustomerStatus.HOTLINED ? (
            <Typography className={styles.active}>
              {content?.hotlined}
            </Typography>
          ) : status === CustomerStatus.REJECTED ? (
            <Typography className={styles.active}>
              {content?.rejected}
            </Typography>
          ) : status === CustomerStatus.DISABLED ? (
            <Typography className={styles.active}>
              {content?.disabled}
            </Typography>
          ) : status === CustomerStatus.PORTING_FAILED ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Typography className={styles.active_fail}>
                {content?.port_fail}
              </Typography>
              <Typography
                className={styles.try_again}
                onClick={handleUpdate}
                sx={{
                  opacity:
                    primaryStatus === CustomerStatus.SUSPENDED ||
                    primaryStatus === CustomerStatus.DISCONNECTED ||
                    primaryStatus === CustomerStatus.DISABLED
                      ? "0.3"
                      : "",
                  pointerEvents:
                    primaryStatus === CustomerStatus.SUSPENDED ||
                    primaryStatus === CustomerStatus.DISCONNECTED ||
                    primaryStatus === CustomerStatus.DISABLED
                      ? "none"
                      : "",
                }}
              >
                {content?.try_again}
              </Typography>
            </Box>
          ) : status === CustomerStatus.PORTING_IN_PROGRESS ? (
            <Typography className={styles.active}>
              {content?.port_inprog}
            </Typography>
          ) : status === CustomerStatus.ACTIVATION_FAILED ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box className={styles.active}>{content?.activation_fail}</Box>
              <Box
                style={{
                  cursor: "pointer",
                  color: "var(--dusk)",
                }}
                className={styles.active}
                onClick={() => {
                  navigate(RoutingURLs.helpCenter);
                }}
              >
                {content?.contact_cust}
              </Box>
            </Box>
          ) : status === CustomerStatus.INACTIVE ? (
            <Typography
              className={
                primaryStatus !== CustomerStatus.ACTIVE
                  ? styles.disabled_btn
                  : styles.active
              }
              sx={{ color: "var(--primary_color)" }}
              onClick={() => {
                if (primaryStatus === CustomerStatus.ACTIVE) {
                  const secondaryCustomer = groupData?.find(
                    (item: { id: any }) => item.id === eachUser.id
                  );
                  localStorage.setItem(
                    "secondaryCustomerDetail",
                    JSON.stringify(secondaryCustomer)
                  );
                  navigate(
                    eachUser.esim
                      ? RoutingURLs?.activateEsim
                      : RoutingURLs?.trackOrder,
                    {
                      state: {
                        orderId: orderId,
                        secondaryCustomerID: eachUser.id,
                        isSecondary: true,
                        emailId: eachUser.emailId,
                      },
                    }
                  );
                }
              }}
            >
              {content?.track_order}
            </Typography>
          ) : (
            <Box
              className={
                primaryStatus === CustomerStatus.SUSPENDED ||
                primaryStatus === CustomerStatus.DISCONNECTED
                  ? styles.disabled_btn
                  : ""
              }
              onClick={
                primaryStatus === CustomerStatus.SUSPENDED ||
                primaryStatus === CustomerStatus.DISCONNECTED
                  ? () => null
                  : () => {
                      if (!eachUser?.emailId) {
                        handleActivateModal(true);
                        const payload = {
                          esim:
                            data?.simPreference === SimTypes?.eSim.toUpperCase()
                              ? true
                              : false,
                          imei: data?.imei ? data.imei : "",
                          make: data?.make ? data.make : "",
                          model: data?.model ? data.model : "",
                          selectedLine: data?.lineNumber
                        };
                        setSecCustomerPayload(payload);
                      } else if (
                        eachUser?.status === CustomerStatus?.INACTIVE
                      ) {
                        GAAndFBEventsHandler(
                          gaCategory.manageLines,
                          content?.act_now
                        );
                        handleActivateSecondary(eachUser);
                      }
                    }
              }
            >
              <Typography
                className={clsx(
                  styles.click,
                  eachUser?.emailId ? styles.click_secondary_line : ""
                )}
              >
                {content?.act_now}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {modalName.length ? (
        <PortingDialogue
          name={modalName}
          modalData={modalData}
          closeHandler={handleCloseActivationModel}
          submitHandler={submitHandler}
          buttonLoaderFg={buttonLoaderFg}
          defaultError={notificationMsg.defaultError}
        />
      ) : (
        ""
      )}
    </>
  );
};

const LineListComponent = (props: LineComponentProps) => {
  const {
    user,
    customerForm,
    handleChangeEmail,
    updateSecondaryEmail,
    handleClose,
    handleClickOpen,
    activateModal,
    open,
    value,
    openUser,
    emailErr,
    updateEmailErr,
    edit,
    customerErr,
    customerDetailBtn,
    setCustomerErr,
    handleClickEdit,
    handleActivateModal,
    content,
    setStopAddLinesFlow,
    extraLinesData,
    groupData,
    setSecCustomerPayload,
    setGroupData,
  } = props;

  const { getShipmentID } = useAppSelector((state: any) => state.trackorder);
  const { group } = useAppSelector((state: any) => state.account);
  const { activationStatus } = useAppSelector((state: any) => state.activation);
  const [portFailedUsers, setPortFailedUsers] = useState([]);
  const dispatch = useAppDispatch();
  const handleClickopen = (user: any) => {
    handleClickOpen(user);
  };

  const hanldeEdit = () => {
    handleClickEdit();
  };
  const callActivationStatus = async (user: any) => {
    let userDetails = JSON.parse(JSON.stringify(user));
    const matchingUserIndex = groupData.findIndex(
      (item: any) => item.id === userDetails.id
    );

    if (matchingUserIndex === -1) {
      setGroupData((current: any) => [...current, userDetails]);
    } else {
      const updatedGroupData = [...groupData];
      updatedGroupData[matchingUserIndex] = userDetails;
      setGroupData(updatedGroupData);
    }
  };

  useEffect(() => {
    if (group?.data?.length) {
      const data = group?.data?.slice().sort((a: any, b: any) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      });
      let totalPortFailedUsers: any = group.data.filter(
        (x: any) => x.status === 10
      );
      let portFailedUser: any = [];
      for (let x in totalPortFailedUsers) {
        dispatch(
          activationStatusCheck({
            payload: { customerId: totalPortFailedUsers[x].id },
          })
        ).then((res: any) => {
          if (res.payload.status === 200) {
            let portFailedCustomers: any = totalPortFailedUsers?.map(
              (item: any) => {
                if (item?.id === res?.payload?.data?.data?.customerId) {
                  portFailedUser.push({
                    ...item,
                    ...res?.payload?.data?.data,
                  });
                }
              }
            );
          }
        });
      }
      setPortFailedUsers(portFailedUser);
    }
  }, []);
  useNonInitialEffect(() => {
    if (group?.data?.length) {
      const data = group?.data?.slice().sort((a: any, b: any) => {
        return (
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
        );
      });
      data.forEach(async (ele: any) => {
        await callActivationStatus(ele);
      });
    }
  }, [group]);

  return (
    <>
      <SimpleModal
        isShowModal={activateModal}
        onCloseModal={handleClose}
        showClose={true}
        className={styles.secondary_line_dialog}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "550px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
        >
          <TextFieldCust
            name="firstName"
            onChange={customerForm.handleChange}
            onBlur={customerForm.handleBlur}
            sx={{ m: "24px 0 8px 0" }}
            error={
              customerForm.touched?.firstName && customerForm.errors?.firstName
                ? true
                : false
            }
            helperText={
              customerForm.touched?.firstName && customerForm.errors?.firstName
            }
            label={content?.fst_name}
          />
          <TextFieldCust
            name="lastName"
            onChange={customerForm.handleChange}
            onBlur={customerForm.handleBlur}
            sx={{ mb: "8px" }}
            error={
              customerForm.touched?.lastName && customerForm.errors?.lastName
                ? true
                : false
            }
            helperText={
              customerForm.touched?.lastName && customerForm.errors?.lastName
            }
            label={content?.lst_name}
          />
          {EnvConfig?.HIDE_SECONDARY_EMAIL_FIELD ? null : (
            <TextFieldCust
              name="email"
              onChange={(e: any) => {
                customerForm.handleChange(e), setCustomerErr("");
              }}
              onBlur={customerForm.handleBlur}
              sx={{ mb: "8px" }}
              error={
                customerForm.touched?.email && customerForm.errors?.email
                  ? true
                  : false
              }
              helperText={
                customerForm.touched?.email && customerForm.errors?.email
              }
              label={content?.email}
            />
          )}
          {customerErr !== "" && (
            <Typography className={styles.secondary_email_err}>
              {customerErr}
            </Typography>
          )}
          <ButtonCust
            sx={{ width: "100%" }}
            loading={customerDetailBtn}
            onClick={() => {
              customerForm.handleSubmit();
              GAAndFBEventsHandler(gaCategory.manageLines, content?.submit);
            }}
          >
            {content?.submit}
          </ButtonCust>
        </Grid>
      </SimpleModal>
      <SimpleModal
        isShowModal={open}
        onCloseModal={handleClose}
        showClose={true}
        className={styles.secondary_line_dialog_email}
        sx={{
          borderRadius: "4.8px",
          maxWidth: { xs: "95%", sm: "500px" },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Grid
          item
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          className={styles.inner_container}
        >
          <Box sx={{ margin: "0px -12px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0px 12px",
              }}
            >
              <Box
                component="img"
                src={
                  openUser?.status === CustomerStatus.ACTIVE
                    ? content?.active_img?.data?.attributes?.url
                    : content?.inactive_img?.data?.attributes?.url
                }
                alt={content?.inactive_img?.data?.attributes?.alternativeText}
                width="34px"
              />
            </Box>
            <Typography
              sx={{
                fontSize: "22px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--h3_font_weight)",
                margin: "15px 0px 5px",
                padding: "0px 12px",
              }}
            >
              {openUser?.firstName}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "var(--font_family_Medium)",
                margin: "10px 0 5px 0",
                padding: "0px 12px",
                color: "var(--grey_shade_3)",
              }}
            >
              {content?.secondary}
            </Typography>
          </Box>
          {openUser?.status === CustomerStatus.ACTIVE ? (
            <Typography className={styles.line_details}>
              {displayPhoneNumberFormat(openUser?.reachNumber)}&nbsp;
              {content?.sim_id}&nbsp;{openUser?.simId}
            </Typography>
          ) : (
            ""
          )}
          <Box
            sx={{
              margin: "15px 0px 33px",
              width: "100%",
            }}
          >
            <TextFieldCust
              variant="standard"
              label={content?.email}
              value={value}
              sx={{ width: "100%" }}
              onChange={(e: any) => {
                if (!edit) {
                  handleChangeEmail(e);
                }
              }}
              disabled={edit}
              className={styles.email_textfield_cust}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={hanldeEdit}>
                    {edit ? <EditIcon /> : <MoreHorizIcon />}
                  </IconButton>
                </InputAdornment>
              }
              error={emailErr !== ""}
              helperText={emailErr}
            />
          </Box>
          {updateEmailErr !== "" && (
            <Typography
              textAlign="center"
              fontFamily="var(--font_family_Bold)"
              fontSize="16px"
              color="var(--danger)"
              my="8px"
            >
              {updateEmailErr}
            </Typography>
          )}
          <ButtonCust
            variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
            sx={{ width: { xs: "200px", sm: "100%" } }}
            onClick={updateSecondaryEmail}
            loading={customerDetailBtn}
          >
            {content?.update}
          </ButtonCust>
        </Grid>
      </SimpleModal>
      {groupData && groupData?.length > 0 ? (
        groupData.map((user: any) => {
          let orderId: any;
          let secondaryCustomerId: any;
          getShipmentID?.data?.map((ship: any) => {
            if (user.id == ship.secondaryCustomers) {
              orderId = ship.orderId;
              secondaryCustomerId = ship.secondaryCustomers;
              secondaryCustomerId = ship.secondaryCustomers[0];
            } else {
              secondaryCustomerId = user.id;
            }
          });
          return (
            <SingleLine
              lineimage={
                user.status === CustomerStatus.ACTIVE
                  ? content?.active_img?.data?.attributes?.url
                  : content?.inactive_img?.data?.attributes?.url
              }
              lineimg={
                user.isPrimary
                  ? `${user.firstName} ${content?.owner}`
                  : `${user.firstName}`
              }
              linenumber={
                user && user.reachNumber
                  ? displayPhoneNumberFormat(user.reachNumber)
                  : ""
              }
              linemail={user && user.emailId ? user.emailId : ""}
              statusimg={
                user.status === CustomerStatus.ACTIVE
                  ? content?.tick_icon?.data?.attributes?.url
                  : ""
              }
              status={user.status}
              handleClickopen={handleClickopen}
              handleActivateModal={handleActivateModal}
              eachUser={user}
              portingFailedUsers={portFailedUsers}
              content={content}
              key={user?.id}
              orderId={orderId}
              groupData={groupData}
            />
          );
        })
      ) : (
        <>
          {Array.from({ length: user?.extraLines + 1 }).map((_, index) => (
            <Skeleton
              variant="rounded"
              animation="wave"
              height={55}
              sx={{
                borderRadius: "27.5px",
                width: { sm: "92%", xs: "92%", md: "50%" },
                m: "10px auto",
              }}
            />
          ))}
        </>
      )}
      {user?.extraLines > user?.additionalLines && extraLinesData?.length > 0
        ? extraLinesData.map((data: any, idx: number) => {
            return (
              <SingleLine
                lineimage={content?.inactive_img?.data?.attributes?.url}
                lineimg={
                  getNumberWithOrdinal(user.additionalLines + 1 + (idx + 1)) +
                  ` ${content?.line}`
                }
                // handleClickopen={handleClickopen}
                handleActivateModal={handleActivateModal}
                content={content}
                key={idx}
                setSecCustomerPayload={setSecCustomerPayload}
                data={data}
              />
            );
          })
        : null}
      {user?.extraLines > user?.additionalLines && !EnvConfig?.IS_ESIM_SUPPORTED
        ? new Array(user?.extraLines - user?.additionalLines)
            .fill(1)
            .map((_, idx: number) => {
              return (
                <SingleLine
                  lineimage={content?.inactive_img?.data?.attributes?.url}
                  lineimg={
                    getNumberWithOrdinal(user.additionalLines + 1 + (idx + 1)) +
                    ` ${content?.line}`
                  }
                  // handleClickopen={handleClickopen}
                  handleActivateModal={handleActivateModal}
                  content={content}
                  key={idx}
                />
              );
            })
        : null}
      {EnvConfig?.REPLACE_NOTE_WITH_ADD_LINE && (
        <Box display="flex" my="1.2rem">
          <Typography
            className={styles.note_txt}
            style={{ color: "var(--primary_color)" }}
          >
            {content?.note}
          </Typography>
          <Typography className={styles.note_txt} pl="3px">
            {BrandNameUpdate(content?.note_text)}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default LineListComponent;
