import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Config from "../../config/app.config.json";
import EnvConfig from "../../config/env.config.json";
import { GetCustomer } from "../../features/checkout/checkoutSlice";
import {
  getInTouchApi,
  liveOpsMessagesApi,
} from "../../features/homepage/homepageSlice";
import { fetchTrackOrderContent } from "../../features/strapi/trackOrderSlice";
import {
  ReachPlan,
  refreshOrderNo,
  trackOrderShipment,
} from "../../features/trackorder/trackorderSlice";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { differenceInMilliseconds } from "date-fns";
import { useLocation } from "react-router-dom";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../utils/commonFunctions";
import { refreshImeiData } from "../../features/activation/activationSlice";
import {
  deleteCartItems,
  getCartItems,
} from "../../features/shopWare/shopWareSlice";
import { OpsMsgTypes } from "../../features/homepage/OpsMsgTypes";

const TrackOrderTemplate = isTemplateExisits(Config.TrackOrder)
  ? require(`./${Config.TrackOrder.template}_TrackOrder`).default
  : null;

export const TrackOrderComponent = () => {
  const [content, setContent] = useState<any>(null);
  const [trackOrderData, setTrackOrderData] = useState<any>([]);
  const [customer, setCustomer] = useState<any>([]);
  const [planData, setPlanData] = useState<any>([]);
  const [orderId, setOrderId] = useState<any>(null);
  const [shipmentTrackingOpsMessages, setShipmentTrackingOpsMessages] =
    useState([]);

  const { trackOrderContent } = useAppSelector(
    (state: any) => state.strapi.trackOrder
  );

  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const { trackOrderStatus, reachPlan } = useAppSelector(
    (state: any) => state.trackorder
  );
  const { liveOpsMessagesSuccess, liveOpsMessagesError } = useAppSelector(
    (state: any) => state.homepage
  );

  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = location?.state;

  useEffect(() => {
    EnvConfig?.IS_MSG_CENTER_ENABLED &&
      dispatch(
        liveOpsMessagesApi({
          limit: OpsMsgTypes.limit,
          partialMatch: true,
          query: "",
          status: [OpsMsgTypes.statusInProgress],
        })
      );
  }, []);

  useEffect(() => {
    if (
      EnvConfig?.IS_MSG_CENTER_ENABLED &&
      liveOpsMessagesSuccess?.status === "SUCCESS" &&
      liveOpsMessagesSuccess?.data?.results
    ) {
      let shipmentTrackingOpsMessages: any = [];
      liveOpsMessagesSuccess?.data?.results?.forEach((opsMessage: any) => {
        if (
          opsMessage?.inventory[
            "messaging_center.web_my_account.shipment_tracking_page"
          ]
        ) {
          shipmentTrackingOpsMessages.push(opsMessage);
        }
      });
      setShipmentTrackingOpsMessages(shipmentTrackingOpsMessages);
    }
  }, [liveOpsMessagesSuccess]);

  useEffect(() => {
    getCache("trackOrderData", fetchTrackOrderContent, setContent, dispatch);

    let user1: any = localStorage.getItem("currentuser");
    user1 = JSON.parse(user1);
    let email: string = user1 && user1.email ? user1.email : "";
    email && dispatch(GetCustomer({ username: encodeURIComponent(email) }));
  }, [trackOrderContent]);
  useNonInitialEffect(() => {
    addMultipleCacheData("trackOrderData", trackOrderContent);
  }, [trackOrderContent]);

  useEffect(() => {
    dispatch(refreshOrderNo(null));
    dispatch(refreshImeiData());
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      deleteCart();
    }
  }, []);

  useEffect(() => {
    if (
      getCustomer &&
      getCustomer.shipmentInfo &&
      getCustomer.reachPlanId &&
      Object.keys(getCustomer.shipmentInfo)[
        Object.keys(getCustomer.shipmentInfo).length - 1
      ]
    ) {
      setCustomer(getCustomer);
      setTrackOrderId();
      if (orderId) {
        dispatch(
          trackOrderShipment({
            poll: true,
            orderId: orderId,
          })
        );
      }
      dispatch(
        ReachPlan({
          plan: getCustomer.reachPlanId,
        })
      );
      let paymentStatus = localStorage.getItem("userPaymentStatus");
      if (paymentStatus && paymentStatus === "yes") {
        pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
          appName: EnvConfig.brand,
          screenName: gaScreenNames.trackOrder,
          category: gaCategory.purchaseFlow,
          buttonValue: getCustomer.data && getCustomer.data.extraLines,
        });

        localStorage.removeItem("userPaymentStatus");
        if (getCustomer && getCustomer.emailId) {
          dispatch(
            getInTouchApi({
              data: {
                emailId: getCustomer && getCustomer.emailId,
                hubspotMap: {
                  email: getCustomer && getCustomer.emailId,
                  is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
                  is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
                  website_journey_score: 8,
                  is_auto_pay: true,
                  website_purchase_payment_success: true,
                  website_number_of_lines:
                    getCustomer && getCustomer.extraLines + 1,
                },
              },
            })
          );
        }
      }
    }
  }, [getCustomer]);

  useEffect(() => {
    if (trackOrderStatus && trackOrderStatus.status === "SUCCESS") {
      setTrackOrderData(trackOrderStatus.data);
    }
    if (reachPlan && reachPlan?.data?.status === "SUCCESS" && reachPlan.data) {
      setPlanData(reachPlan.data.data);
    }
  }, [trackOrderStatus, reachPlan]);
  const deleteCart = () => {
    let lineItemsInCart: any = localStorage.getItem("lineItems");
    lineItemsInCart = JSON.parse(lineItemsInCart);
    let planDetails: any = localStorage.getItem("reachSelectedPlanDetail");
    planDetails = JSON.parse(planDetails);
    let deletionId: any = lineItemsInCart?.find((item: any) => {
      return item?.payload?.planName === planDetails?.plan?.displayName;
    });
    if (deletionId) {
      dispatch(deleteCartItems(deletionId?.id)).then((res: any) => {
        if (res) {
          dispatch(getCartItems());
        }
      });
    }
  };
  const setTrackOrderId = () => {
    const orders = [];
    const { shipmentInfo } = getCustomer || {};
    if (shipmentInfo) {
      for (const orderId in shipmentInfo) {
        if (shipmentInfo?.hasOwnProperty(orderId)) {
          const date = shipmentInfo[orderId];
          orders.push({ orderId, date });
        }
      }
    }
    const currentOrderId = orders?.length && orders?.sort((a, b) =>
      differenceInMilliseconds(b?.date, a?.date)
    )[0]?.orderId;

    if (params?.orderId) {
      setOrderId(params?.orderId);
    } else {
      setOrderId(currentOrderId);
    }
  };

  return (
    (content || trackOrderContent) && (
      <TrackOrderTemplate
        content={content ? content : trackOrderContent}
        trackOrderData={trackOrderData}
        customerData={customer}
        planData={planData}
        {...(EnvConfig?.IS_MSG_CENTER_ENABLED && {
          shipmentTrackingOpsMessages: shipmentTrackingOpsMessages,
        })}
      />
    )
  );
};
