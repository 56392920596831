import { HomeBannerProps } from "./HomeBanner";
import { Box, Grid, Paper, Typography, Skeleton, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { ButtonCust } from "../../widgets/Button/ButtonCust";
import Styles from "./S1_HomeBanner.module.scss";
import config from "../../../config/env.config.json";
import clsx from "clsx";
import { getResolutionDetails } from "../../../utils/commonFunctions/ReusableFunctions";
import { generateImageContainer } from "../../../utils/commonFunctions/generateImageTemplate";
import { CustomerStatus as CS } from "../../../enums/CustomerStatus";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const HomeBannerT1 = (props: HomeBannerProps) => {
  const {
    heading,
    sub_heading,
    button_text,
    banner_web,
    banner_mobile,
    banner_ipad,
    button_text2,
    list_items,
    heading2,
  } = props?.bannerContent || {};
  const { largeDesktop, desktop, ipad, mobile } = getResolutionDetails();
  const { pageName } = props;
  const {
    content_carousel,
    ticks_main_div,
    list_item_txt,
    button2,
    banner_image,
  } = Styles;
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const { status } = customerDetails || {};
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        position: "relative",
        minHeight: { xs: "600px", sm: "300px", md: "300px", lg: "500px" },
      }}
    >
      {banner_web || banner_ipad || banner_mobile ? (
        <>
          <Paper sx={{ padding: 0 }} elevation={0}>
            {(largeDesktop || desktop) &&
              generateImageContainer({
                data: banner_web,
                attrs: {
                  width: "100%",
                  height: "500px",
                },
                className: { banner_image },
              })}
            {ipad &&
              generateImageContainer({
                data: banner_ipad,
                attrs: {
                  width: "100%",
                  height: "600px",
                },
                className: { banner_image },
              })}
            {mobile &&
              generateImageContainer({
                data: banner_mobile,
                attrs: {
                  width: "100%",
                  height: "auto",
                },
                className: { banner_image },
              })}
          </Paper>
          <Grid
            sx={{
              position: "absolute",
              textAlign: {
                xs: "var(--main_banner_title_f_align_mobile)",
                sm: "left",
              },
            }}
            className={content_carousel}
          >
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "baseline" },
                }}
              >
                {heading && (
                  <Typography
                    component="h1"
                    variant="h3"
                    // gutterBottom
                    className="main_banner_title"
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(heading),
                    }}
                  />
                )}
                {heading2 && (
                  <Typography
                    component="h3"
                    variant="h3"
                    gutterBottom
                    className="main_banner_title"
                  >
                    {BrandNameUpdate(heading)}
                  </Typography>
                )}
                {sub_heading && (
                  <Typography
                    variant="h4"
                    component="h4"
                    className={clsx(ticks_main_div, "main_banner_sub_title")}
                  >
                    {BrandNameUpdate(sub_heading)}
                  </Typography>
                )}
                {list_items?.length > 0 ? (
                  <Box
                    pb={{
                      xs: "15px",
                      md: "25px",
                    }}
                    pl={{ xs: "35px", md: "0" }}
                    sx={{
                      ml: { md: "unset" },
                      width: { xs: "90%", md: "100%" },
                    }}
                  >
                    {list_items.map((item: any, index: any) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: "6px 0px 0px",
                          }}
                        >
                          {item?.image?.data?.attributes?.url ? (
                            <Box
                              component={"img"}
                              alt={BrandNameUpdate(
                                item?.image?.data?.attributes?.alternativeText
                              )}
                              src={item?.image?.data?.attributes?.url}
                              sx={{ width: "16px" }}
                            />
                          ) : null}
                          <Box className={list_item_txt}>{item?.offer}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : null}
                {button_text && (
                  <ButtonCust
                    variantType={config.PRIMARY_BUTTON_TYPE}
                    onClick={props.onButton1Click}
                    sx={{
                      position: "relative",
                      display: "flex",
                      margin: { md: "unset" },
                      width: "250px",
                    }}
                    disabled={
                      pageName === "PostActivationHome" &&
                      status !== CS.ACTIVE &&
                      status !== CS.SUSPENDED
                        ? true
                        : false
                    }
                  >
                    {button_text}
                  </ButtonCust>
                )}
                {button_text2 && (
                  <Box
                    className={button2}
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      justifyContent: { xs: "center", sm: "start" },
                      width: { sm: "max-content" },
                      position: { sm: "absolute" },
                      left: { sm: "6%" },
                    }}
                  >
                    <Box
                      component="span"
                      sx={{ fontFamily: "inherit" }}
                      onClick={() => props.onButton2Click()}
                    >
                      {button_text2}
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: { xs: "500px", sm: "300px", md: "300px", lg: "500px" },
          }}
        >
          <Stack spacing={1} width="70%">
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              width={"100%"}
              height={50}
            />
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default HomeBannerT1;
