import { Close } from "@mui/icons-material";
import { Box, Popover, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { validateEmail } from "../../../../../../utils/commonFunctions/ValidateEmail";
import { ButtonCust, TextFieldCust, SimpleModal } from "../../../../../widgets";
import Config from "../../../../../../config/env.config.json";
import styles from "./CompatibilityCheck.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import isEmpty from "lodash/isEmpty";
import { updateToHubspot } from "../../../../../../utils/commonFunctions/hubspot";
import { checkCompatibility } from "../../../../../../features/homepage/homepageSlice";
import { pushTrackingEventsToGAAndFB } from "../../../../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../../../Types/GoogleAnalytics";
import clsx from "clsx";
import { BrandNameUpdate, ModelNameUpdate, getLastCharacter, modelMapper } from "../../../../../../utils/commonFunctions/BrandNameUpdate";

type Props = {
  showCompatibilityModal?: any;
  setShowCompatibilityModal?: any;
  closeModalHandler?: any;
  imeiErr?: any;
  emailCompatability?: any;
  imeiNumber?: any;
  emailErr?: any;
  skipImeiCheck?: any;
  seeOurPlan?: any;
  setImeiNumber?: any;
  setEmailCompatability?: any;
  imeiErrorMessage?: any;
  setImeiData?: any;
  setFinalSuccessModal?: any;
  setImeiErrorMessage?: any;
  setEmailErr?: any;
  setImeiErr?: any;
  name?: any;
  setName?: any;
  isFail?: any;
  setIsFail?: any;
  imeiContent?: any;
  isBYOD?: any;
};

export const CompatibilityCheck = (props: Props) => {
  const [haveTrouble, setHaveTrouble] = useState(false);
  const [target, setTarget] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessForBYOD, setIsSuccessForBYOD] = useState(false);
  const [isFailForBYOD, setIsFailForBYOD] = useState(false);
  const [checkClick, setCheckClick] = useState(false);
  const [modelCompat, setModalCompat] = useState(true);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.plan,
      category: category,
      buttonValue: value,
    });
  };
  const { compatibilityLoading, compatibility, errorCompatibility } =
    useAppSelector((state: any) => state.homepage);
  const dispatch = useAppDispatch();

  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
        if (!isEmpty(compatibility)) {
        let data = compatibility.data.data;
        let modelN: string = getLastCharacter(compatibility?.data?.data?.model, 5);
        if(Config.IS_IMEI_DEVICE_MODEL_NEG_CHECK_ENABLED && modelN && modelMapper[modelN]) {
          setModalCompat(false);
        } else {
          setModalCompat(true);
          if (data.isValid) {
            setIsSuccess(false);
            if (!props.isBYOD) props.setShowCompatibilityModal(false);
            props.setFinalSuccessModal(true);
            setCheckClick(false);
            props.setImeiData({
              carriers:
                data.supportedCarriers.join("") == "PLUM_VAD"
                  ? "Ghost Mobile"
                  : data.supportedCarriers.join(""),
              makeModel: `${data.make} ${data.model}`,
              mode: data.mode,
              wifiCalling: data.wifiCalling,
              isLostOrStolen: data.isLostOrStolen,
            });
            updateToHubspot(data, props.emailCompatability);
            setIsSuccessForBYOD(true);
          } else {
            props.setIsFail(true);
            setCheckClick(false);
            setIsFailForBYOD(true);
          }
        }
      }
    }
  }, [compatibility]);

  useEffect(() => {
    if (!isEmpty(errorCompatibility)) {
      props.setImeiErrorMessage(imei_error4);
    }
  }, [errorCompatibility]);
  useEffect(() => {
    let user: any = JSON.parse(localStorage.getItem("currentuser") || "null");
    if (user?.email) {
      props.setEmailCompatability(
        props.emailCompatability ? props.emailCompatability : user?.email
      );
    }
  }, []);

  const nameHandler = (e: any) => {
    props.setName(e.target.value);
  };
  const tryAgainHandler = () => {
    GAAndFBEventsHandler(gaCategory.imeiCheck, try_again_btn);
    props.setImeiNumber("");
    props.setName("");
    props.setEmailCompatability("");
    setIsSuccess(false);
    setIsSuccessForBYOD(false);
    setIsFailForBYOD(false);
    setCheckClick(false);
    setModalCompat(true);
    props.setIsFail(false);
  };
  const imeiHandler = (e: any) => {
    props.setImeiNumber(e.target.value);
    props.setImeiErr("");
  };
  const closeTroubleHandler = () => {
    setTarget(null);
    setHaveTrouble(false);
  };
  const emailCompatibilityHandler = (e: any) => {
    props.setEmailCompatability(e.target.value);
    props.setEmailErr("");
  };
  const handleEnter = (e: any) => {
    if (e.key === "Enter") {
      checkHandler();
    }
  };

  const checkHandler = () => {
    if (
      props.emailCompatability &&
      props.imeiNumber &&
      props.emailErr === "" &&
      props.imeiErr === "" &&
      props.imeiNumber.length === 15 &&
      validateEmail(props.emailCompatability) &&
      /^\d+$/.test(props.imeiNumber)
    ) {
      setCheckClick(true);
      props.setImeiErrorMessage("");
      dispatch(checkCompatibility({ imeiNumber: props.imeiNumber })).catch(()=>{
        setCheckClick(false);
      });
    } else {
      if (props.emailCompatability === "") {
        props.setEmailErr(imei_error3);
      } else if (!validateEmail(props.emailCompatability)) {
        props.setEmailErr(email_error1);
      }
      if (props.imeiNumber === "") {
        props.setImeiErr(imei_error4);
      } else if (props.imeiNumber !== "" && props.imeiNumber.length !== 15) {
        props.setImeiErr(imei_error2);
      } else if (props.imeiNumber !== "" && !/^\d+$/.test(props.imeiNumber)) {
        props.setImeiErr(imei_error1);
      }
    }
    let phoneCompatibilityObject = {
      imei: props.imeiNumber,
      email: props.emailCompatability,
    };
    localStorage.setItem(
      "phoneCompatibilityObject",
      JSON.stringify(phoneCompatibilityObject)
    );
  };

  const onShopNewPhonesClick = (url: string) => {
    window.open(url, "_blank");
  };
  const {
    coverage_modal,
    bummer_text,
    bummer_content,
    have_trouble_header,
    have_trouble_tool_tip,
    coverage_error,
    having_trouble_div,
    try_again,
    imei_fail_text,
    compatibility_plan_input,
    compatibility_header,
    above_email_text,
    limit_div,
    phone_trouble_div,
    input_div,
    add_margin_bottom,
    have_trouble_detail_div,
    detail_img_div,
    detail_img,
    detail_content,
    detail_head,
    detail_subtext,
    have_trouble_header_text,
    close_icon,
    bummer_fail_text,
    info_content_ios,
    tick_icon_point,
    ns_link,
  } = styles;

  const {
    imei_success_title,
    imei_success_subtitle,
    imei_success_btn,
    imei_success_img,
    imei_fail_title,
    imei_fail_subtitle,
    try_again_btn,
    imei_fail_img,
    compatibility_title,
    compatibility_label,
    imei_length,
    dial_msg,
    having_trouble,
    popover_title,
    popover_subtitle1,
    popover_subtitle2,
    popover_subtext1,
    popover_subtext2,
    popover_img1,
    popover_img2,
    imei_error1,
    imei_error2,
    imei_error3,
    email_label,
    name_label,
    compatibility_btn,
    skip_text,
    imei_error4,
    email_error1,
    byod_fail_title,
    byod_fail_subtitle,
    byod_fail_btn,
    close_img,
    imei_compatibility_info,
    network_settings,
  } = props.imeiContent || {};
  const haveTroubleHandler = async (e: any) => {
    setHaveTrouble(true);
    setTarget(e.target);
  };
  const renderNetworkSettings = () => {
    if (compatibility !== undefined) {

      return compatibility?.data?.data?.make.toLowerCase() == "apple" ? (
        <>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              padding: "5px 0",
              color: "var(--text-color)",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "var(--font_weight_4)",
            }}
          >
            {network_settings[0].title}
          </Typography>
          <Box component="div" sx={{
            color: "var(--text-color)",
            fontFamily: "var(--font_family_Semibold)",
            fontSize: "14px",
            lineHeight: 1.6,
            margin: "5px 0px",
          }}>
            {" "}
            {BrandNameUpdate(network_settings?.[0]?.desc_1)}&nbsp;
            <Box
              component="a"
              className={ns_link}
              href={network_settings[0].link1_url}
              target="_blank"
            >
              {network_settings[0].link_1}
            </Box>&nbsp;
            {BrandNameUpdate(network_settings?.[0]?.link1_suffix)}
          </Box>
          {network_settings?.[0]?.desc_2 && <Box>
            <Typography
              component="span"
              sx={{
                color: "var(--text-color)",
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                lineHeight: 1.6,
                margin: "5px 0px",
              }}
            >{imei_compatibility_info?.note_txt}</Typography>&nbsp;
            <Typography
              component="span"
              sx={{
                color: "var(--text-color)",
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                lineHeight: 1.6,
                margin: "5px 0px",
              }}
            >{BrandNameUpdate(network_settings?.[0]?.desc_2)}</Typography>
          </Box>}
        </>
      ) : (
        <>
          <Typography
            component="div"
            sx={{
              fontFamily: "var(--font_family_Semibold)",
              padding: "5px 0",
              color: "var(--text-color)",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "var(--font_weight_4)",
            }}
          >
            {network_settings[2].title}
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
            }}
          >
            {network_settings?.[2]?.icon?.data?.attributes?.url ? (
              <>
                <Box
                  component="img"
                  className={tick_icon_point}
                  src={network_settings[2].icon?.data?.attributes?.url}
                ></Box>{" "}
              </>
            ) : null}
            <Typography
              component="div"
              sx={{
                color: "var(--text-color)",
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                margin: "5px 0px",
              }}
            >
              {BrandNameUpdate(network_settings?.[2]?.desc_1)}&nbsp;
              <Box
                component="a"
                className={ns_link}
                href={network_settings[2].link1_url}
                target="_blank"
              >
                {network_settings[2].link_1}
              </Box>
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {network_settings?.[2]?.icon?.data?.attributes?.url?<><Box
              component="img"
              className={tick_icon_point}
              src={network_settings[2].icon?.data?.attributes?.url}
            ></Box>{" "}</>:null}
          </Box>
        </>
      );
    }
  };
  return (
    props.imeiContent && (
      <SimpleModal
        isShowModal={props.showCompatibilityModal}
        onCloseModal={props.closeModalHandler}
        showClose={true}
        className={coverage_modal}
      >
        {props.isBYOD && isSuccessForBYOD && modelCompat && (
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Box
              component={"img"}
              alt={imei_success_img?.data?.attributes?.alternativeText}
              sx={{ width: "60px", height: "58px" }}
              src={imei_success_img?.data?.attributes?.url}
            />
            <Box className={bummer_text}>{imei_success_title}</Box>
            <Box className={bummer_content}>{imei_success_subtitle}</Box>

            {network_settings ? <Box>
              <Box
                sx={{
                  textAlign: "justify",
                  padding: "20px 0px",
                }}
              >
                {renderNetworkSettings()}
              </Box>
            </Box> : null}
            <Box>
              <ButtonCust
                sx={{ my: 1 }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  props.seeOurPlan();
                  GAAndFBEventsHandler(gaCategory.imeiCheck, imei_success_btn);
                }}
              >
                {imei_success_btn}
              </ButtonCust>
            </Box>

            <Box
              component={"span"}
              className={try_again}
              onClick={tryAgainHandler}
            >
              {try_again_btn}
            </Box>
          </Box>
        )}
        {isSuccess && (
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Box
              component={"img"}
              alt={imei_success_img?.data?.attributes?.alternativeText}
              sx={{ width: "60px", height: "58px" }}
              src={imei_success_img?.data?.attributes?.url}
            />
            <Box className={bummer_text}>{imei_success_title}</Box>
            <Box className={bummer_content}>{imei_success_subtitle}</Box>

            <Box>
              <ButtonCust
                sx={{ my: 1 }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  props.seeOurPlan(),
                    GAAndFBEventsHandler(
                      gaCategory.imeiCheck,
                      imei_success_btn
                    );
                }}
              >
                {imei_success_btn}
              </ButtonCust>
            </Box>

            <Box
              component={"span"}
              className={try_again}
              onClick={tryAgainHandler}
            >
              {try_again_btn}
            </Box>
          </Box>
        )}
        {props.isBYOD && isFailForBYOD && modelCompat && (
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Box
              component={"img"}
              alt={imei_fail_img?.data?.attributes?.alternativeText}
              sx={{ width: "60px", height: "58px" }}
              src={imei_fail_img?.data?.attributes?.url}
            />
            <Box className={bummer_fail_text}>{byod_fail_title}</Box>
            <Box className={bummer_content} sx={{ my: 1 }}>
              {BrandNameUpdate(byod_fail_subtitle)}
            </Box>
            {/* <Box>
              <ButtonCust
                sx={{ my: 1 }}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                onClick={() => {
                  onShopNewPhonesClick(globalVal?.shopPhone);
                  GAAndFBEventsHandler(gaCategory.imeiCheck, byod_fail_btn);
                }}
              >
                {byod_fail_btn}
              </ButtonCust>
            </Box> */}

            <Box
              component={"span"}
              className={try_again}
              onClick={tryAgainHandler}
            >
              {try_again_btn}
            </Box>
          </Box>
        )}
        {!modelCompat && (
          <>
          <Box
            component="img"
            sx={{width: "40px", height: "40px"}}
            src={close_img?.data?.attributes?.url}
          ></Box>
          <Typography
            component="div"
            sx={{
              color: "var(--text-color)",
              fontFamily: "var(--font_family_Semibold)",
              fontSize: "14px",
              lineHeight: 1.6,
              margin: "5px 0px",
            }}
          >
           {imei_compatibility_info && ModelNameUpdate(BrandNameUpdate(imei_compatibility_info?.model_compat_fail_msg), getLastCharacter(compatibility?.data?.data?.model, 5))}
          </Typography>

          <Box
              component={"span"}
              className={try_again}
              onClick={tryAgainHandler}
            >
              {try_again_btn}
          </Box>
        </>
        )}
        {!props.isBYOD && props.isFail && (
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <Box
              component={"img"}
              alt={imei_fail_img?.data?.attributes?.alternativeText}
              sx={{ width: "60px", height: "58px" }}
              src={imei_fail_img?.data?.attributes?.url}
            />
            <Box className={imei_fail_text}>
              <Box>{imei_fail_title}</Box>
              <Box>{BrandNameUpdate(imei_fail_subtitle)}</Box>
            </Box>

            <Box
              component={"span"}
              className={try_again}
              onClick={tryAgainHandler}
            >
              {try_again_btn}
            </Box>
          </Box>
        )}
        {!props.isFail && !isSuccess && !isSuccessForBYOD && !isFailForBYOD && modelCompat ? (
          <Box className={compatibility_plan_input} onKeyPress={handleEnter}>
            <Box className={compatibility_header}>{compatibility_title}</Box>
            <Box
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  checkHandler();
                }
              }}
            >
              <TextFieldCust
                value={props.imeiNumber}
                type={window.screen.width <= 600 ? "number" : "text"}
                label={compatibility_label}
                onChange={(e: any) => {
                  if (
                    (/^[0-9]+$/.test(e.target.value) &&
                      e.target.value.length <= 15) ||
                    e.target.value === ""
                  ) {
                    imeiHandler(e);
                  }
                }}
                error={
                  props.imeiErr !== "" ||
                  (props.imeiNumber !== "" &&
                    !/^\d+$/.test(props.imeiNumber)) ||
                  (props.imeiNumber !== "" && props.imeiNumber.length !== 15) ||
                  (checkClick && props.imeiNumber === "")
                }
                helperText={
                  props.imeiErr
                    ? props.imeiErr
                    : props.imeiNumber !== "" && !/^\d+$/.test(props.imeiNumber)
                    ? imei_error1
                    : props.imeiNumber !== "" && props.imeiNumber.length !== 15
                    ? imei_error2
                    : checkClick && props.imeiNumber === ""
                    ? imei_error3
                    : ""
                }
                maxlength={15}
              />

              <Box className={limit_div}>
                {props.imeiNumber && props.imeiNumber.length
                  ? props.imeiNumber.length
                  : "0"}
                {imei_length}
              </Box>

              <Box className={phone_trouble_div}>
                <Box className={above_email_text}>{dial_msg}</Box>
                <Box>
                  <Box
                    className={having_trouble_div}
                    id="having-trouble-div"
                    onClick={(e) => {
                      haveTroubleHandler(e);
                      GAAndFBEventsHandler(
                        gaCategory.imeiCheck,
                        having_trouble
                      );
                    }}
                  >
                    {having_trouble}
                  </Box>
                  <Popover
                    id={"popover-contained"}
                    open={haveTrouble}
                    anchorEl={target}
                    onClose={closeTroubleHandler}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Box className={have_trouble_tool_tip}>
                      <Box className={have_trouble_header}>
                        <Close
                          onClick={closeTroubleHandler}
                          className={close_icon}
                        />
                        <Box className={have_trouble_header_text}>
                          {popover_title}
                        </Box>
                      </Box>
                      <Box className={have_trouble_detail_div}>
                        <Box className={detail_img_div}>
                          <Box
                            component={"img"}
                            src={popover_img1?.data?.attributes?.url}
                            alt={
                              popover_img1?.data?.attributes?.alternativeText
                            }
                            className={detail_img}
                          />
                        </Box>
                        <Box className={detail_content}>
                          <Box className={detail_head}>{popover_subtitle1}</Box>
                          <Box
                            className={clsx(detail_subtext, add_margin_bottom)}
                          >
                            {popover_subtext1}
                          </Box>
                        </Box>
                      </Box>
                      <Box className={have_trouble_detail_div}>
                        <Box className={detail_img_div}>
                          <Box
                            component={"img"}
                            src={popover_img2?.data?.attributes?.url}
                            alt={
                              popover_img2?.data?.attributes?.alternativeText
                            }
                            className={detail_img}
                          />
                        </Box>
                        <Box className={detail_content}>
                          <Box className={detail_head}>{popover_subtitle2}</Box>
                          <Box className={detail_subtext}>
                            {popover_subtext2}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Popover>
                </Box>
              </Box>

              <Box className={input_div}>
                <TextFieldCust
                  value={props.emailCompatability}
                  label={email_label}
                  onChange={emailCompatibilityHandler}
                  error={
                    props.emailErr !== "" ||
                    (props.emailCompatability !== "" &&
                      !validateEmail(props.emailCompatability)) ||
                    (checkClick && props.emailCompatability === "")
                  }
                  helperText={
                    props.emailErr
                      ? props.emailErr
                      : props.emailCompatability !== "" &&
                        !validateEmail(props.emailCompatability)
                      ? email_error1
                      : checkClick && props.emailCompatability === ""
                      ? imei_error3
                      : ""
                  }
                />
              </Box>

              <Box className={input_div}>
                <TextFieldCust
                  value={props.name}
                  label={name_label}
                  onChange={nameHandler}
                />
              </Box>
              {props.imeiErrorMessage && (
                <Box className={coverage_error}>{props.imeiErrorMessage}</Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonCust
                  sx={{ my: 1 }}
                  variantType={Config.PRIMARY_BUTTON_TYPE}
                  onClick={() => {
                    checkHandler();
                    GAAndFBEventsHandler(
                      gaCategory.imeiCheck,
                      compatibility_btn
                    );
                  }}
                  loading={compatibilityLoading}
                >
                  {compatibility_btn}
                </ButtonCust>
                {!props.isBYOD && Config.ADDRESS_CHECK_DURING_PURCHASE && (
                  <Box>
                    <Box
                      component={"span"}
                      className={try_again}
                      onClick={props.skipImeiCheck}
                    >
                      {skip_text}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        ) : null}
      </SimpleModal>
    )
  );
};
