import { Stack } from "@mui/material";
import Config from "../../config/app.config.json";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";

const PlanItem = isTemplateExisits(Config.Checkout.ChangePlan)
  ? require(`./${Config.Checkout.ChangePlan.template}_PlanItem`).default
  : null;
interface IPlansContainer {
  plans: Array<any>;
  selectedPlan?: any;
  handlePlanSelection: any;
  activePlanName: any;
  currentPlanBorder?: boolean;
  content?: any;
  user?: any;
}

export const PlansContainer = (props: IPlansContainer) => {
  const {
    plans,
    selectedPlan,
    activePlanName,
    handlePlanSelection,
    content,
    currentPlanBorder,
    user,
  } = props;
  const sortedPlans = [
    ...plans?.filter((a) => a.name !== activePlanName),
    ...plans?.filter((a) => a.name === activePlanName),
  ];
  return (
    <Stack sx={{ width: "100%" }}>
      {sortedPlans &&
        sortedPlans.map((plan: any, index: any) => {
          return (
            <PlanItem
              key={index}
              selectedPlan={selectedPlan}
              activePlanName={activePlanName}
              plan={plan}
              handlePlanSelection={handlePlanSelection}
              content={content}
              currentPlanBorder={currentPlanBorder}
              user={user}
              planIndex={index}
            />
          );
        })}
    </Stack>
  );
};
