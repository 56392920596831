import { isTemplateExisits } from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";

const HelpCenterComp = isTemplateExisits(Config.MyAccount.LineUserData)
  ? require(`./${Config.ContactUs.HelpCenter.template}_HelpCenter`).default
  : null;

const HelpCenter = (props: any) => {
  const {
    data,
    content,
  } = props;

  return (
    content && (
      <HelpCenterComp
        data={data}
        content={content}
      />
    )
  );
};

export default HelpCenter;
