import {
  Box,
  CircularProgress,
  ClickAwayListener,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import {
  ButtonCust,
  CustToolTip,
  NotificationCust,
  SwitchCust,
} from "../../../widgets";
// import { IRPurchaseHistoryProps } from "./IRPurchaseHistory";
import Styles from "./IRPurchaseHistory.module.scss";
import Config from "../../../../config/env.config.json";
import IRAutoRenewal from "./IRAutoRenewal";
import IRNoOrderHistory from "./IRNoOrderHistory";
import IRDataUsage from "./IRDataUsage";
import { format } from "date-fns";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../app/hooks";
import IRDataBoost from "./IRDataBoost";
import DropDownMenu from "../../../widgets/DropDownMenu/DropDownMenu";
import IRCancelReschedulePack from "./IRCancelReschedulePack";
import _ from "lodash";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";
import PaymentStatus from "../../IRILDComponents/PaymentStatus/PaymentStatus";
import { IRPackStatus, PostActivationIRILDEnums } from "../../../../enums";
import { getPackUsageHistory } from "../../../../features/services/servicesSlice";

const T1_IRPurchaseHistory = (props: any) => {
  const {
    content,
    allPacksData,
    isDataBoostJourney,
    setIsDataBoostJourney,
    onClickSwitchTabs,
    rescheduleSuccess,
    setRescheduleSuccess,
    callAllPacksApi,
  } = props;
  const dispatch = useAppDispatch();
  const { allPacksPending } = useAppSelector((state) => state.services);
  const { group } = useAppSelector((state) => state.account);
  const {
    pack_status,
    data_boost,
    ordered_for,
    order_details,
    data_txt,
    amt_txt,
    ordered_on,
    activates_on,
    expired_on,
    unit,
    details,
    active,
    expires_on,
    statusList,
    select_img,
    up_arrow_img,
    down_arrow_img,
    refund_txt,
    cancelled_on,
    refund_note,
    // buy_pack_again,
    reschedule_success,
    act_loader,
    failed_data,
    snackBar,
  } = content;

  const {
    all_pack_container,
    dot,
    horizontal_line,
    vertical_line,
    pack_details,
    country_name,
  } = Styles;

  const [open, setOpen] = useState(false);
  const [dataBoostLine, setDataBoostLine] = useState(null);
  const [packId, setPackId] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [packIndex, setPackIndex] = useState<number | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState({
    name: null,
    status: "",
  });
  const [selectedLineValue, setSelectedLineValue] = useState({
    status: null,
    name: "All Lines",
  });
  const [userList, setUserList] = useState([
    {
      status: null,
      name: "All Lines",
    },
  ]);
  const menuOpen = Boolean(menuAnchorEl);
  const [lineAnchorEl, setLineAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);
  const lineOpen = Boolean(lineAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const processingPackId: any = localStorage.getItem("SpecificPurchasePackId");

  const filteredPaymentData: any = filterData.filter(
    (pack: any) => pack.id === processingPackId
  );

  useEffect(() => {
    const dateString = new Date(
      filteredPaymentData[0]?.createdDate
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (filteredPaymentData[0]?.status === 1) {
      NotificationCust({
        message: (
          <Box
            sx={{
              fontSize: "14px",
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_5",
            }}
          >
            {` ${snackBar.starting}
            ${dateString}
            ${snackBar.ending}`}
          </Box>
        ),
        type: "info",
        duration: 10000,
        id: "successActivationMessage",
        showIcon: true,
      });

      localStorage.removeItem("SpecificPurchasePackId");
    }
  }, [filteredPaymentData]);

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedIndex(index);
    setSelectedValue(option);
    setMenuAnchorEl(null);
  };

  const handleClickLineItem = (event: React.MouseEvent<HTMLElement>) => {
    setLineAnchorEl(event.currentTarget);
  };

  const handleLineClose = () => {
    setLineAnchorEl(null);
  };

  const handleLineItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: any
  ) => {
    setSelectedLineIndex(index);
    setSelectedLineValue(option);
    setLineAnchorEl(null);
  };
  const [selectedDate, setSelectedDate] = useState<any>("");

  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const PoperOver = (data: any) => {
    return (
      <ClickAwayListener onClickAway={onClose}>
        <List
          sx={{
            listStyleType: "disc",
            pl: 3,
            "& .MuiListItem-root": {
              display: "list-item",
            },
            fontFamily: "var(--font_family_Medium)",
            fontWeight: "var(--font_weight_5)",
            fontSize: "14px",
            color: "var(--tab_color_2)",
            lineHeight: "18px",
          }}
        >
          <ListItem
            disablePadding
            sx={{
              padding: "10px 10px 10px 5px",
            }}
          >
            {ordered_on}{" "}
            {format(new Date(data.createdDate), "MMMM dd, yyyy hh:mmaaa")}
          </ListItem>
          <ListItem
            disablePadding
            sx={{
              padding: "10px 10px 10px 5px",
            }}
          >
            {expires_on}{" "}
            {format(new Date(data.tentativeEndDate), "MMMM dd, yyyy hh:mmaaa")}
          </ListItem>
        </List>
      </ClickAwayListener>
    );
  };

  const handleDataBoostCta = (data: any, index: any) => {
    setIsDataBoostJourney(true);
    setDataBoostLine(data);
    setPackIndex(index);
  };

  const handleDataUsage = (data: any) => {
    setPackId(data.id);
    dispatch(
      getPackUsageHistory({
        packId: data.id,
      })
    );
  };

  const getUserName = (packData: any, index: any) => {
    let userData = group?.data.find(
      (i: any) => i.id === packData[index].customerId
    );
    return userData?.firstName + " " + userData?.lastName;
  };

  const getUserList = () => {
    let newuserArr = allPacksData
      .filter((el: any) => group.data.find((f: any) => f.id === el.customerId))
      .map((item: any) => ({
        name:
          group.data.find((f: any) => f.id === item.customerId).firstName +
          " " +
          group.data.find((f: any) => f.id === item.customerId).lastName,
        status: group.data.find((f: any) => f.id === item.customerId).id,
      }));

    return _.uniqBy(newuserArr, function (e: any) {
      return e.status;
    });
  };

  useEffect(() => {
    if (allPacksData) {
      let test2: any = [
        {
          status: null,
          name: "All Lines",
        },
      ];

      test2.push(...getUserList());

      setUserList(test2);
      setSelectedValue(statusList[0]);
      setSelectedLineValue(test2[0]);
    }
  }, [allPacksData]);

  useNonInitialEffect(() => {
    if (allPacksData) {
      const oldPacks = [...allPacksData];
      const filteredAllPersons: any = oldPacks
        .filter((pack) => {
          if (selectedValue?.status === pack.status) return true;
          if (selectedValue?.status == null) return pack;
        })
        .filter((pack) => {
          if (selectedLineValue?.status === pack.customerId) return true;
          if (selectedLineValue?.status == null) return pack;
        });
      setFilterData(filteredAllPersons);
    }
  }, [selectedValue, selectedLineValue]);
  const dateFormat: any = (e: any) => {
    const date = new Date(e);
    const formattedDate = format(date, "MMMM d, yyyy");
    return formattedDate;
  };

  return (
    <>
      {!isDataBoostJourney && !rescheduleSuccess && (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <DropDownMenu
            statuslist={statusList}
            handleMenuItemClick={handleMenuItemClick}
            handleClose={handleClose}
            menuAnchorEl={menuAnchorEl}
            handleClickListItem={handleClickListItem}
            selectedIndex={selectedIndex}
            menuOpen={menuOpen}
            selectedIcon={select_img}
            uparrowIcon={up_arrow_img}
            downarrowIcon={down_arrow_img}
          />
          <DropDownMenu
            statuslist={userList}
            handleMenuItemClick={handleLineItemClick}
            handleClose={handleLineClose}
            menuAnchorEl={lineAnchorEl}
            handleClickListItem={handleClickLineItem}
            selectedIndex={selectedLineIndex}
            menuOpen={lineOpen}
            selectedIcon={select_img}
            uparrowIcon={up_arrow_img}
            downarrowIcon={down_arrow_img}
          />
        </Box>
      )}

      {filterData &&
      filterData.length !== 0 &&
      !isDataBoostJourney &&
      !rescheduleSuccess ? (
        filterData
          .sort((a: any, b: any) => {
            if (
              (a.status === IRPackStatus.EXPIRED &&
                b.status === IRPackStatus.CANCELLED) ||
              (a.status === IRPackStatus.CANCELLED &&
                b.status === IRPackStatus.EXPIRED) ||
              (a.status === IRPackStatus.EXPIRED &&
                b.status === IRPackStatus.CREATION_IN_PROGRESS) ||
              (a.status === IRPackStatus.CREATION_IN_PROGRESS &&
                b.status === IRPackStatus.EXPIRED) ||
              (a.status === IRPackStatus.ACTIVE &&
                b.status === IRPackStatus.CREATION_IN_PROGRESS) ||
              (a.status === IRPackStatus.CREATION_IN_PROGRESS &&
                b.status === IRPackStatus.ACTIVE) ||
              (a.status === IRPackStatus.EXPIRED &&
                b.status === IRPackStatus.FAILED) ||
              (a.status === IRPackStatus.FAILED &&
                b.status === IRPackStatus.EXPIRED)
            ) {
              return b.status - a.status;
            } else {
              return a.status - b.status;
            }
          })
          .map((data: any, index: any) => (
            <Box className={all_pack_container} key={data.id}>
              <Box className={pack_details}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontSize: "30px",
                        fontWeight: "var(--font_weight_5)",
                        lineHeight: "30px",
                        color: "var(--tab_color_2)",
                      }}
                    >
                      {data.offeringDisplayName}
                    </Typography>
                    {data.countriesMap && (
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_5)",
                          color: "var(--tab_color_2)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box component="span" className={dot}></Box>
                        <Box component="span" className={country_name}>
                          {(data.countriesMap[
                            data.countriesList[0]
                          ]?.countryName).toLowerCase()}
                        </Box>
                      </Typography>
                    )}
                    {data.status !== IRPackStatus.FAILED && (
                      <Typography
                        component="div"
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontWeight: "var(--font_weight_5)",
                          color:
                            data.status === IRPackStatus.SCHEDULED
                              ? "var(--dusty-orange1)"
                              : data.status === IRPackStatus.EXPIRED ||
                                data.status === IRPackStatus.CANCELLED
                              ? "var(--red-color_5)"
                              : data.status === IRPackStatus.ACTIVE
                              ? "var(--primary_brand_color_dark)"
                              : "var(--tab_color_2)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box component="span" className={dot}></Box>
                        {pack_status.status[data.status]}
                        {data.status === IRPackStatus.ACTIVE && (
                          <Box
                            component="img"
                            alt={active?.data.attributes?.alternativeText || ""}
                            src={active?.data.attributes?.url || ""}
                            ml="3px"
                            position="relative"
                            top="2px"
                          />
                        )}
                        {data.status === IRPackStatus.CREATION_IN_PROGRESS && (
                          <Box
                            component="img"
                            alt={
                              act_loader?.data.attributes?.alternativeText || ""
                            }
                            src={act_loader?.data.attributes?.url || ""}
                            ml="3px"
                            position="relative"
                            top="2px"
                            width="20px"
                            height="20px"
                          />
                        )}
                      </Typography>
                    )}
                  </Box>
                  {data.status === IRPackStatus.CANCELLED ? (
                    <Typography
                      component="div"
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_5)",
                        color: "var(--lite_background2)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="span"
                        className={dot}
                        sx={{
                          backgroundColor: "var(--lite_background2) !important",
                        }}
                      ></Box>
                      <Box component="span">{refund_txt}</Box>
                    </Typography>
                  ) : data.status === IRPackStatus.FAILED ? (
                    <Typography
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                        fontWeight: 800,
                        color: "var(--red-color_5) !important",
                      }}
                    >
                      {failed_data.failed}
                    </Typography>
                  ) : data.status === IRPackStatus.ACTIVE &&
                    Config.ENABLE_IR_DATA_BOOST ? (
                    <ButtonCust
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      onClick={() => handleDataBoostCta(data, index)}
                    >
                      {data_boost}
                    </ButtonCust>
                  ) : null}
                </Box>
                <Box className={horizontal_line}></Box>
                {data.status === IRPackStatus.FAILED ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      textAlign: "center",
                      paddingBottom: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: "40px",
                        fontFamily: "var(--font_family_Bold)",
                      }}
                    >
                      {failed_data.orderedFor}{" "}
                      <span
                        style={{
                          color: "var(--primary_color)",
                          fontFamily: "var(--font_family_Bold)",
                        }}
                      >
                        {getUserName(filterData, index)}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        opacity: 0.7,
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {failed_data.desc}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: "25px",
                    }}
                  >
                    <Box
                      sx={{
                        fontFamily: "var(--font_family_Medium)",
                        fontWeight: "var(--font_weight_5)",
                        fontSize: "14px",
                        color: "var(--tab_color_2)",
                        lineHeight: "18px",
                      }}
                    >
                      <Typography component="div">
                        {ordered_for}{" "}
                        <Typography
                          component="span"
                          sx={{
                            color: "var(--primary_brand_color_dark)",
                          }}
                        >
                          {getUserName(filterData, index)}
                        </Typography>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mt: "25px",
                        }}
                      >
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_5)",
                            fontSize: "16px",
                            color: "var(--tab_color_2)",
                            lineHeight: "18px",
                            opacity: 0.7,
                          }}
                        >
                          {data_txt}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{ opacity: 1 }}
                          pl="5px"
                        >
                          {data.totalDataGB}
                          {unit}
                        </Typography>
                        <Box className={vertical_line}></Box>
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_5)",
                            fontSize: "16px",
                            color: "var(--tab_color_2)",
                            lineHeight: "18px",
                            opacity: 0.7,
                          }}
                        >
                          {amt_txt}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{ opacity: 1 }}
                          pl="5px"
                        >
                          {GetCurrencyFixedToTwo(data.totalCost)}
                        </Typography>
                      </Box>
                    </Box>
                    {data.status === IRPackStatus.ACTIVE ? (
                      <>
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_5)",
                            fontSize: "14px",
                            color: "var(--tab_color_2)",
                            lineHeight: "18px",
                            cursor: "pointer",
                            height: "fit-content",
                          }}
                        >
                          {order_details}
                          <CustToolTip
                            title={PoperOver(data)}
                            open={
                              open
                                ? anchorEl === data.id
                                  ? true
                                  : false
                                : false
                            }
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            placement="top-end"
                            onMouseOver={() => {
                              setAnchorEl(data.id);
                              setOpen(true);
                            }}
                          >
                            <Box
                              component="img"
                              alt={
                                details?.data.attributes?.alternativeText || ""
                              }
                              src={details?.data.attributes?.url || ""}
                              ml="2px"
                              position="relative"
                              top="3px"
                            />
                          </CustToolTip>
                        </Typography>
                      </>
                    ) : (
                      <Box>
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_5)",
                            fontSize: "14px",
                            color: "var(--tab_color_2)",
                            lineHeight: "18px",
                            mb: "25px",
                          }}
                        >
                          {ordered_on}{" "}
                          {format(
                            new Date(data.createdDate),
                            "MMMM dd, yyyy hh:mmaaa"
                          )}
                        </Typography>
                        <Typography
                          component="div"
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontWeight: "var(--font_weight_5)",
                            fontSize: "14px",
                            color:
                              data.status === IRPackStatus.EXPIRED ||
                              data.status === IRPackStatus.CANCELLED
                                ? "var(--red-color_5)"
                                : "var(--tab_color_2)",
                            lineHeight: "18px",
                          }}
                        >
                          {data.status === IRPackStatus.EXPIRED
                            ? expired_on +
                              " " +
                              format(
                                new Date(data.tentativeEndDate),
                                "MMMM dd, yyyy hh:mmaaa"
                              )
                            : data.status === IRPackStatus.CANCELLED &&
                              data.cancelledDate
                            ? cancelled_on +
                              " " +
                              format(
                                new Date(data.cancelledDate),
                                "MMMM dd, yyyy hh:mmaaa"
                              )
                            : activates_on +
                              " " +
                              format(
                                new Date(data.tentativeStartDate),
                                "MMMM dd, yyyy hh:mmaaa"
                              )}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {data.status === IRPackStatus.CANCELLED && (
                  <Box
                    component="div"
                    sx={{
                      color: "var(--tab_color_2)",
                      opacity: "0.7",
                      fontSize: "16px",
                      fontFamily: "var(--font_family_Medium)",
                      marginBottom: "30px",
                    }}
                  >
                    {refund_note}
                  </Box>
                )}
                {data.status === IRPackStatus.ACTIVE &&
                  Config.ENABLE_IR_DATA_RENEWAL && (
                    <IRAutoRenewal
                      content={content}
                      autoRenew={data.autoRenew}
                      packId={data.id}
                    />
                  )}
              </Box>
              {/* {data.status === 5 && (
              <Box
                component="div"
                sx={{
                  color: "var(--white)",
                  fontSize: "16px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                  backgroundColor: "var(--primary_color)",
                  padding: "24px 0",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {buy_pack_again}
              </Box>
            )} */}
              {data.status === IRPackStatus.SCHEDULED && (
                <IRCancelReschedulePack
                  content={content}
                  packId={data.id}
                  rescheduleSuccess={rescheduleSuccess}
                  setRescheduleSuccess={setRescheduleSuccess}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                />
              )}
              {data.status === IRPackStatus.ACTIVE && (
                <IRDataUsage
                  content={content}
                  data={data}
                  handleDataUsage={handleDataUsage}
                  packId={packId}
                />
              )}
            </Box>
          ))
      ) : allPacksPending ? (
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "var(--primary_brand_color_dark)",
            }}
          />
        </Box>
      ) : isDataBoostJourney ? (
        <IRDataBoost
          content={content}
          getUserName={() => getUserName(filterData, selectedLineIndex)}
          packIndex={packIndex}
          dataBoostLine={dataBoostLine}
          setIsDataBoostJourney={setIsDataBoostJourney}
        />
      ) : rescheduleSuccess ? (
        <PaymentStatus
          icon={reschedule_success?.addOn_status}
          title={reschedule_success?.title}
          description={`${reschedule_success?.desc} ${dateFormat(
            selectedDate
          )}.`}
          button={reschedule_success?.button}
          onClick={() => {
            callAllPacksApi();
            setRescheduleSuccess(false);
            onClickSwitchTabs(PostActivationIRILDEnums.tab_right);
          }}
          variantType={Config.QUATERNARY_BUTTON_TYPE}
        />
      ) : (
        <IRNoOrderHistory content={content} />
      )}
    </>
  );
};

export default T1_IRPurchaseHistory;
