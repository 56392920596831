import React, { useEffect, useMemo, useState } from "react";
import WalletCard from "../HistoryCard/WalletCard";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getILDUsageHistory } from "../../../../../features/services/servicesSlice";
import styles from "./UsageHistory";

const UsageHistory = (props: any) => {
  const dispatch = useAppDispatch();

  const { getILDUsageHistoryData, getILDUsageHistoryPending } = useAppSelector(
    (state) => state?.services
  );
  const [filteredData, setFilteredData] = useState([]);
  const { country, usage, less_icon, greater_icon, no_usage } = props.content;

  const customerDetailsJSON: any = localStorage.getItem("customerDetail");
  const customerDetail = JSON.parse(customerDetailsJSON);

  const getStartAndEndDateJSON: any = localStorage.getItem("StartEndDate");
  const getStartAndEndDateData = JSON.parse(getStartAndEndDateJSON);

  function convertDateFormat(inputDate: any) {
    // Create a Date object by parsing the original date string
    const dateObject = new Date(inputDate);

    // Extract year, month, and day components
    const year = dateObject.getFullYear();
    // Months are 0-based in JavaScript, so we add 1 to get the correct month
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const startDate = convertDateFormat(getStartAndEndDateData.displayStartDate);
  const endDate = convertDateFormat(getStartAndEndDateData.displayEndDate);

  useEffect(() => {
    if (
      getILDUsageHistoryData &&
      Object.keys(getILDUsageHistoryData).length > 0
    ) {
      const filteredDatas: any = Object.values(getILDUsageHistoryData).filter(
        (item: any) => {
          return item.billEndDate === endDate;
        }
      );

      setFilteredData(filteredDatas);
    } else {
      // Handle the case where getILDUsageHistoryData is null or empty
    }
  }, []);

  const extractCountryVoiceUsages = (data: any) => {
    const countryVoiceUsages = [];

    for (const dateKey in filteredData) {
      const usageDetail = data[dateKey]?.usageDetail;
      for (const id in usageDetail) {
        const countryUsageMap = usageDetail[id].countryUsageMap;
        if (countryUsageMap) {
          for (const countryName in countryUsageMap) {
            const voiceUsage = countryUsageMap[countryName].voiceUsage;
            countryVoiceUsages.push({ countryName, voiceUsage });
          }
        }
      }
    }

    return countryVoiceUsages;
  };

  const memoizedData = useMemo(
    () => extractCountryVoiceUsages(getILDUsageHistoryData),
    [getILDUsageHistoryData]
  );

  useEffect(() => {
    dispatch(
      getILDUsageHistory({
        packId: customerDetail?.groupId,
      })
    );
  }, []);

  return (
    <div>
      <WalletCard
        lineDataForWallet={props?.lineDataForWallet}
        content={props.content}
      >
        {getILDUsageHistoryPending ? (
          <Box textAlign={"center"}>
            <CircularProgress sx={{ color: "var(--primary_color)" }} />
          </Box>
        ) : memoizedData.length === 0 ? (
          <Box p={10}>
            <Typography
              sx={{
                fontSize: "34px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_5)",
                opacity: 0.4,
              }}
            >
              {no_usage}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                onClick={() => {
                  if (
                    getStartAndEndDateData.totalNumberOfKeys > 0 &&
                    getStartAndEndDateData.selectedDateKeyIndex
                  ) {
                    props.handleBillCycleChange("decrement");
                  }
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={less_icon.data.attributes.url}
                  alt={less_icon.data.attributes.alternativeText}
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "var(--font_weight_1)",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {getStartAndEndDateData?.displayStartDate}
              </Typography>
              <span>-</span>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "var(--font_weight_1)",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {getStartAndEndDateData?.displayEndDate}
              </Typography>
              <Box
                onClick={() => {
                  if (
                    getStartAndEndDateData.totalNumberOfKeys > 0 &&
                    getStartAndEndDateData.selectedDateKeyIndex
                  ) {
                    props.handleBillCycleChange("increment");
                  }
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={greater_icon.data.attributes.url}
                  alt={greater_icon.data.attributes.alternativeText}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "40%", md: "40%" },
                margin: "auto",
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                }}
              >
                {country}
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  fontSize: "14px",
                  fontFamily: "var(--font_family_Medium)",
                  fontWeight: "var(--font_weight_5)",
                }}
              >
                {usage}
              </Typography>
            </Box>

            {memoizedData.map((data: any, index: number) => (
              <Box
                key={index}
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  width: { xs: "40%", md: "40%" },
                  margin: "auto",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1)",
                  }}
                >
                  {data.countryName}
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    fontWeight: "var(--font_weight_1)",
                  }}
                >
                  {data.voiceUsage}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </WalletCard>
    </div>
  );
};

export default UsageHistory;
