import {
  FormControlLabel,
  Radio,
  RadioProps,
  FormControlLabelProps,
} from "@mui/material";
import { Box } from "@mui/system";
import classes from "./RadioCust.module.scss";

interface RadioCustProps extends RadioProps {
  labelPlacement?: "end" | "start" | "top" | "bottom";
  label?: string;
  radio_btn_style?: any;
  radioSx?: any;
  disabled?: any;
}

export const RadioCust = (props: RadioCustProps) => {
  return (
    <Box className={classes.radio_container}>
      <FormControlLabel
        value={props.value}
        sx={props.radio_btn_style ? props.radio_btn_style : {}}
        control={
          <Radio
            checked={props.checked}
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            sx={props?.radioSx}
            disabled={props?.disabled}
          />
        }
        label={props.label}
        labelPlacement={props.labelPlacement || "end"}
      />
    </Box>
  );
};
