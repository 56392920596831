import { Box, Typography } from "@mui/material";
import styles from "./ManageCards.module.scss";
import { ButtonCust } from "../Button/ButtonCust";
import { getIsCardExpired } from "../../../utils/commonFunctions/ReusableFunctions";
import { Divider } from "../Divider";
import EnvConfig from "../../../config/env.config.json";
import { useNavigate } from "react-router-dom";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { CardTypes } from "../../../enums";
import { brandNames } from "../../../enums";

type ManageProps = {
  manageCardDetails?: any;
  isDescription?: any;
  getCustomer?: any;
  cardData?: any;
  payType?: any;
  selectOpenHandler?: any;
  openHandler?: any;
  addNewCardHandler?: any;
  getCardsInOrder?: any;
  isLoading?: boolean;
};
const getButtonVariant = () => {
  switch (EnvConfig.DOMAIN) {
    case "WOW":
      return "primary_contained_rounded";

    default:
      return "custom_secondary_transparent_brand_rounded";
  }
};
export const CardChangeModal = (props: ManageProps) => {
  const navigate = useNavigate();
  const {
    manageCardDetails,
    isDescription,
    cardData,
    payType,
    selectOpenHandler,
    addNewCardHandler,
    getCardsInOrder,
    openHandler,
    getCustomer,
    isLoading,
  } = props;
  const getAddNewStyles = () => {
    switch (EnvConfig.DOMAIN) {
      case brandNames.GHOST:
        return styles.add_new_card_ghost;
      case brandNames.WOW:
        return styles.add_new_card_wow;
      default:
        return "";
    }
  };

  return (
    <Box className={styles.manage_card_container}>
      <Typography className={styles.card_heading}>
        {manageCardDetails?.heading}
      </Typography>
      <Typography
        sx={{
          fontFamily: "var(--font_family_Regular)",
          fontSize: "16px",
          marginTop: "26px",
        }}
      >
        {/* The total balance will be charged in full 
      on the 28th of each month. */}
      </Typography>
      {isDescription ? (
        <Typography
          className={styles.description}
          sx={{
            ...(EnvConfig.DOMAIN === "GHOST" && {
              mb: "20px",
            }),
          }}
        >
          {manageCardDetails?.description?.replace(
            "{0}",
            `${new Date(getCustomer?.nextBillingDate).getDate()}`
          )}
        </Typography>
      ) : null}
      <Box
        className={styles.bottom_section}
        sx={{ marginTop: "20px !important" }}
      >
        {cardData ? (
          <Typography className={styles.save_card_text}>
            {manageCardDetails?.save_cards}
          </Typography>
        ) : (
          <Typography className={styles.save_card_text}>
            {manageCardDetails?.no_cards}
          </Typography>
        )}
        <>
          {cardData?.length > 0 &&
            getCardsInOrder(cardData).map((each: any, ind: number) => {
              let cardIsExpired = getIsCardExpired(each);
              return (
                <>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      borderRadius: "8px",
                      marginBottom: "25px",
                      padding: "0px 12px 12px 12px",
                    }}
                  >
                    <Box className={styles.number_container} key={ind}>
                      <Box
                      // sx={{
                      //   display: "flex",
                      //   alignItems: "flex-start",
                      // }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component="img"
                            alt="visa_image"
                            sx={{
                              width: "30px",
                              height: "20px",
                              marginRight: "10px",
                              alignSelf: "flex-start",
                            }}
                            src={
                              each?.type?.toUpperCase() === CardTypes?.VISA
                                ? manageCardDetails?.visa?.data?.attributes?.url
                                : each?.type?.toUpperCase() ===
                                  CardTypes?.MASTERCARD
                                ? manageCardDetails?.master_card?.data
                                    ?.attributes?.url
                                : each?.type?.toUpperCase() === CardTypes?.JCB
                                ? manageCardDetails?.jcb?.data?.attributes?.url
                                : each?.type?.toUpperCase() ===
                                  CardTypes?.AMERICANEXPRESS
                                ? manageCardDetails?.american_express?.data
                                    ?.attributes?.url
                                : manageCardDetails?.discover?.data?.attributes
                                    ?.url
                            }
                          />
                          <Typography
                            className={styles.card_number}
                            sx={{ pl: "5px" }}
                          >
                            XXXX-XXXX-XXXX- {each.ccNumberMask}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            pl: "5px",
                          }}
                        >
                          <Typography
                            className={styles.expiry}
                            sx={{
                              fontWeight: "var(--font_weight_0)",
                              textAlign: "left !important",
                            }}
                            mb="3px"
                          >
                            <Box
                              component="span"
                              sx={{
                                fontFamily:
                                  "var(--font_family_Bold) !important",
                              }}
                            >
                              {each && each.nameOnCard} |{" "}
                            </Box>
                            {cardIsExpired ? (
                              <Box
                                component={"span"}
                                sx={{ color: "var(--note_danger)" }}
                              >
                                Expired date{" "}
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(0, 2)}
                                /
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(2)}
                              </Box>
                            ) : (
                              <Box
                                component={"span"}
                                className="cardNotExpired"
                              >
                                Expiration date{" "}
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(0, 2)}
                                /
                                {each &&
                                  each.dateOfExpiry &&
                                  each.dateOfExpiry.slice(2)}
                              </Box>
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        {each.isDefault && payType && (
                          <Box
                            component="img"
                            src={
                              manageCardDetails?.right_mark?.data?.attributes
                                ?.url
                            }
                            alt="tick_icon"
                            sx={{
                              width: "20px",
                              height: "15px",
                              alignSelf: "center",
                              margin: "0px 10px 0px 10px",
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <hr style={{ border: "1px solid #F8F8F8" }}></hr>
                      {payType ? (
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          //   flexDirection={!each.isAutoPay ? "row" : "column"}
                          //   alignItems="center"
                        >
                          {!each.isAutoPay && (
                            <Typography
                              onClick={() => {
                                if (!cardIsExpired)
                                  selectOpenHandler(
                                    manageCardDetails?.set_default,
                                    each,
                                    true
                                  );
                              }}
                              className={styles.update_text}
                              sx={{
                                opacity: cardIsExpired ? 0.5 : 1,
                                cursor: `${
                                  cardIsExpired
                                    ? "not-allowed !important"
                                    : "pointer"
                                }`,
                              }}
                            >
                              {manageCardDetails?.set_default}&nbsp;
                            </Typography>
                          )}
                          <Typography
                            onClick={() => openHandler(each)}
                            className={styles.update_text}
                          >
                            {manageCardDetails?.update}&nbsp;
                          </Typography>
                          {each.isAutoPay && EnvConfig.DOMAIN === "GHOST" && (
                            <Box>
                              <span className={styles.expiry}>
                                {manageCardDetails?.auto_pay}
                              </span>{" "}
                              <span
                                className={styles.update_text}
                                onClick={() => {
                                  navigate(RoutingURLs.helpCenter);
                                }}
                              >
                                {manageCardDetails?.cus_care}&nbsp;
                              </span>
                            </Box>
                          )}
                          {!each.isAutoPay && cardData?.length > 1 && (
                            <Typography
                              onClick={() =>
                                selectOpenHandler(
                                  manageCardDetails?.remove_card,
                                  each,
                                  false
                                )
                              }
                              className={styles.update_text}
                            >
                              {manageCardDetails?.remove}&nbsp;
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        !cardIsExpired && (
                          <Typography
                            className={styles.update_text}
                            onClick={() => selectOpenHandler("", each, true)}
                          >
                            {manageCardDetails?.pay_using}
                          </Typography>
                        )
                      )}
                    </Box>
                  </Box>
                </>
              );
            })}
        </>
        <Box
          sx={{
            // opacity: "0.3",
            // borderTop: "1px solid var(--grey_color_1)",
            margin: "0 auto 15px",
          }}
        ></Box>
        {!cardData && (
          <>
            <Typography className={styles.card_details_missing}>
              {manageCardDetails?.card_miss_desc}
              <span
                className={styles.cus_care}
                onClick={() => {
                  navigate(RoutingURLs.contactUs);
                }}
              >
                {manageCardDetails?.cus_care}
              </span>
            </Typography>
          </>
        )}
      </Box>
      <ButtonCust
        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        onClick={addNewCardHandler}
        loading={isLoading}
        className={getAddNewStyles()}
        sx={{
          padding: "13px 23px 12px",
        }}
      >
        <Typography
          fontSize="25px"
          mr="5px"
          fontFamily="var(--font_family_Bold)"
        >
          {manageCardDetails?.plus}
        </Typography>
        <Typography className={styles.add_card}>
          {manageCardDetails?.add_new}
        </Typography>
      </ButtonCust>
    </Box>
  );
};

export default CardChangeModal;
