import { isTemplateExisits } from "../../../utils/commonFunctions";
import Config from "../../../config/app.config.json";

const ForSupportComp = isTemplateExisits(Config.MyAccount.LineUserData)
  ? require(`./${Config.ContactUs.ForSupport.template}_ForSupport`).default
  : null;

const ForSupport = (props: any) => {
  const {
    data,
    content,
  } = props;

  return (
    content && (
      <ForSupportComp
        data={data}
        content={content}
      />
    )
  );
};

export default ForSupport;
