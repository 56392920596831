import "./TextFieldCust.scss";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from "@mui/material/styles";
import EnvConfig from "../../../config/env.config.json";

type ITextFieldProps = TextFieldProps & {
  maxlength?: number;
  minLength?: number;
  className?: string;
  readOnly?: boolean;
  endAdornment?: any;
  sx?: any;
  inputSx?: any;
};

let variant = EnvConfig?.TEXT_FIELD_VARIANT as any;

const customTheme = (outerTheme: Theme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              color: "var(--text_field_label)",
            },
            "& input:-webkit-autofill": {
              color: "var(--text_color) !important",
              opacity: "0.7",
            },
            "& .MuiInputBase-root": {
              color: "var(--text_field_text) !important",
              borderRadius: "var(--text-field_border_radius)",
              fontFamily: "var(--font_family_Regular)",
            },
            "& .MuiInputLabel-root": {
              color: "var(--text_field_text) !important",
              fontSize: "var(--text_field_label_font_size)",
              fontFamily: "var(--text_field_font_family)",
              top: "2px",
            },
            "& .Mui-disabled": {
              opacity: "var(--textfield_disabled_opacity) !important",
            },
            "& .MuiOutlinedInput-input": {
              color: "var(--text_color) !important",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--text_field_border) !important",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--text_field_border) !important",
              borderWidth: "1px",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--text_field_border) !important",
              borderWidth: "1px",
            },
          },
        },
      },
    },
  });

export const TextFieldCust: React.FC<ITextFieldProps> = (props) => {
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
        {...props}
        onBlur={props.onBlur}
        className={`TextField ${props.className ? props.className : ""}`}
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        {...(props.id && { id: props.id })}
        onChange={props.onChange}
        variant={props.variant || variant || "filled"}
        value={props.value}
        error={props.error}
        helperText={props.helperText}
        disabled={props.disabled}
        inputProps={{
          maxLength: props.maxlength,
          minLength: props.minLength,
          readOnly: props.readOnly,
          autoComplete: "new-password",
          form: {
            autocomplete: "off",
          },
          sx: props.inputSx,
          ...props.inputProps,
        }}
        InputProps={{
          endAdornment: props.endAdornment,
        }}
        multiline={props.multiline}
        rows={props.rows}
        maxRows={props.maxRows}
        name={props.name}
        sx={props.sx}
        inputRef={props.inputRef}
        autoComplete="off"
        onKeyUp={props.onKeyUp}
      />
    </ThemeProvider>
  );
};
