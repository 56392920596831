export const RoutingURLs = {
  default: "/",
  plans: "/plans",
  aboutUs: "/about-us",
  profile: "/profile",
  helpCenter: "/help-center",
  help: "/help",
  signUp: "/signup",
  login: "/login",
  home: "/home",
  settings: "/settings",
  activation: "/activation",
  billCalculator: "/bill-calculator",
  internationalServices: "/international_services",
  imeiCheck: "/imei-compatibility-check",
  cellPhonePlans: "/cell-phone-plans",
  bestfit: "/bestfit",
  giveBack: "/giveback",
  checkout: "/checkout",
  postActivationHome: "/post-activation-home",
  myAccount: "/my-account",
  trackOrder: "/track-order",
  billing: "/billing",
  account: "/account",
  secondarylogin: "/secondarylogin",
  steps: "/steps",
  reviews: "/reviews",
  terms: "/terms",
  privacy: "/privacy",
  contactUs: "/contact-us",
  coverageCheck: "/coverage-check",
  discover: "/discover",
  services: "/services",
  loader: "/loader",
  activateEsim: "/activate-esim",
  wallet: "/wallet",
  ulPromo: "/ul-bundle-promo",
  unlimitedBundleTerms: "/terms-ul-bundle",
  basicLanding: "/basic-plan",
  moderateLanding: "/moderate-plan",
  peoplesPlanLanding: "/peoples-plan",
  allInPlanLanding: "/all-in-plan",
  coverageMap: "/coverage-map",
  deviceConfirmCheck: "/confirm-activation",
  chatbot: "#hs-chat-open",
  paymentLanding: "/payment-landing",
  paymentSuccess: "/payment-success",
  safeConnection: "/safe-connection",
  nutritionLabel: "/nutrition-label/:upi",
  nutritionLabelDownload: "/broadband-facts",
  networkMangement: "/network-management"
};

export const version = "v0";
export const versionNew = "v1";
