import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { Divider, SimpleModal, ButtonCust } from "../../../widgets";
import styles from "./BuyDataDialog.module.scss";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import EnvConfig from "../../../../config/env.config.json";
import { CardTypes } from "../../../../enums";

type InewNumberProps = {
  open: boolean;
  onClose: any;
  onSubmit: any;
  amount: any;
  totalTax: any;
  topupPulseDetails: any;
  dataAdded: any;
  customerTopUpLoader: boolean;
  mainCard: any;
  cardChangeHandler: any;
  isCardExpired: any;
  cppdContent?: any;
};

export const BuyDataDialog = (props: InewNumberProps) => {
  const {
    open,
    onClose,
    onSubmit,
    amount,
    totalTax,
    topupPulseDetails,
    dataAdded,
    customerTopUpLoader,
    mainCard,
    cardChangeHandler,
    isCardExpired,
    cppdContent,
  } = props;
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };

  const {
    card_num,
    change_text,
    confirm_purchase,
    credit_note,
    due_amount,
    review,
    tax_fees,
  } = cppdContent?.buy_data;

  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      className={styles.buy_data_dialog}
      sx={{
        borderRadius: "4.8px",
        maxWidth: { xs: "95%", sm: "500px" },
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box className={styles.dialog_main}>
        <Typography className={styles.dialog_heading}>{review}</Typography>
        <Divider />
        <Box
          className={styles.inner_container}
          sx={{ mt: "20px", px: { xs: 0, sm: "25px" } }}
        >
          <Box className={styles.row_container}>
            <Typography className="description_secondary_txt">
              {dataAdded}GB (
              {GetCurrencyFixedToTwo(topupPulseDetails?.pulseCost)}/
              {topupPulseDetails?.pulse}GB)
            </Typography>
            <Typography className="description_secondary_txt">
              {GetCurrencyFixedToTwo(amount)}
            </Typography>
          </Box>
          <Box className={styles.row_divider} />
          <Box className={styles.row_container}>
            <Typography className="description_secondary_txt">
              {tax_fees}
            </Typography>
            <Typography className="description_secondary_txt">
              {GetCurrencyFixedToTwo(totalTax)}
            </Typography>
          </Box>
          <Box className={styles.row_divider} />
          <Box className={styles.row_container}>
            <Typography className={styles.total_text}>{due_amount}</Typography>
            <Typography className={styles.total_text}>
              {GetCurrencyFixedToTwo(totalTax + amount)}
            </Typography>
          </Box>
          {mainCard && (
            <>
              <Typography
                className={styles.card_text}
                p={"20px 0 25px"}
                lineHeight="20px"
              >
                {credit_note}
              </Typography>
              <Box display="flex" justifyContent="center">
                <Box
                  component="img"
                  src={
                    mainCard?.type?.toUpperCase() === CardTypes?.VISA
                      ? cppdContent?.visa_img?.data?.attributes?.url
                      : mainCard?.type?.toUpperCase() === CardTypes?.MASTERCARD
                      ? cppdContent?.master_crd?.data?.attributes?.url
                      : mainCard?.type.toUpperCase() === CardTypes?.JCB
                      ? cppdContent?.jcb_crd_img?.data?.attributes?.url
                      : mainCard?.type.toUpperCase() ===
                        CardTypes?.AMERICANEXPRESS
                      ? cppdContent?.ae_card_img?.data?.attributes?.url
                      : cppdContent?.discover?.data?.attributes?.url
                  }
                  alt="card"
                  sx={{ width: "35px", height: "23px", pr: "10px" }}
                />
                <Typography className={styles.card_text}>
                  {card_num}
                  {mainCard?.ccNumberMask}
                </Typography>
                <Typography
                  className={clsx(styles.card_text, styles.change_card)}
                  onClick={cardChangeHandler}
                >
                  {change_text}
                </Typography>
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="center">
            <ButtonCust
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              sx={{
                padding: "13px 23px",
                height: "45px !important",
                mt: "20px",
              }}
              onClick={() => {
                if (mainCard) {
                  onSubmit();
                } else {
                  cardChangeHandler();
                }
                GAAndFBEventsHandler(
                  gaCategory.buyDataPack,
                  "Confirm Purchase"
                );
              }}
              loading={customerTopUpLoader}
              disabled={isCardExpired}
            >
              {confirm_purchase}
            </ButtonCust>
          </Box>
        </Box>
      </Box>
    </SimpleModal>
  );
};
