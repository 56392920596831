export function convertDataFromBytes(sizeInBytes: number) {
  var unitsValue = ["B", "KB", "MB", "GB", "TB"];
  // if (sizeInBytes === 0) {
  //     sizeInBytes = 0;
  //     return sizeInBytes + unitsValue[0];
  // } else {
  //     var i: any = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  //     var decs: any = unitsValue[i] === 'GB' ? 1 : 0;
  //     let size: any = sizeInBytes / Math.pow(1024, i);
  //     let sizeInBytes1: any = size.toFixed(decs) * 1;
  //     return sizeInBytes1 + unitsValue[i];
  // }
  if (sizeInBytes > 0) {
    var i: any = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    var decs: any = unitsValue[i] === "GB" ? 2 : 0;
    let size: any = sizeInBytes / Math.pow(1024, i);
    let sizeInBytes1: any = size.toFixed(decs) * 1;
    return sizeInBytes1 + unitsValue[i];
  } else {
    sizeInBytes = 0;
    return sizeInBytes + unitsValue[0];
  }
}

export function convertDataFromBytesWithSpace(sizeInBytes: number) {
  var unitsValue = [" B", " KB", " MB", " GB", " TB"];

  if (sizeInBytes > 0) {
    var i: any = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    var decs: any = unitsValue[i] === "GB" ? 1 : 0;
    let size: any = sizeInBytes / Math.pow(1024, i);
    let sizeInBytes1: any = size.toFixed(decs) * 1;
    return sizeInBytes1 + unitsValue[i];
  } else {
    sizeInBytes = 0;
    return sizeInBytes + unitsValue[0];
  }
}
