import { Box } from "@mui/system";
import {
  ButtonCust,
  SearchBoxWithDropdown,
  SimpleModal,
  TextFieldCust,
} from "../widgets";
import "./ZipcodeModal.scss";
import { scroller } from "react-scroll";
import { useState } from "react";
import config from "../../config/env.config.json";
import { useAppSelector } from "../../app/hooks";
import { Link } from "@mui/material";
type Props = {
  modalFg: boolean;
  closeHandler?: any;
  content?: any;
  updateFg?: any;
  address?: any;
  showBox?: any;
  setShowBox?: any;
  clickHandler?: any;
  showcheckcontent?: boolean;
};
export function ZipcodeModal(props: Props) {
  const {
    modalFg,
    closeHandler,
    content,
    updateFg,
    address,
    showBox,
    setShowBox,
    clickHandler,
    showcheckcontent
  } = props;
  const [selectedItem, setSelectedItem] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedZipcode, setSelectedZipcode] = useState<any>();
  const [validZip, setValidZip] = useState(true);
  const { whitelistedZipcodes } = useAppSelector(
    (state: any) => state.checkout
  );
  const inputChangeHandler = (e: any, val: any) => {
    setSelectedItem(e.target.HTML);
    if (val === "") {
      setShowBox(false);
      setValidZip(true);
    } else {
      setShowBox(true);
      setValidZip(false);
    }
    const foundItem = whitelistedZipcodes?.find(
      (item: any) => item.zipCode === val
    );
    if (!foundItem) {
      // setShowBox(true)
    }
  };
  const onCloseHandler = (e: any, val:any)=>{
    setSelectedItem(selectedItem)
    setSelectedItem(e.target.HTML);
  }
  const canceledItem = () => {
  };
  const handleZipcodeSelection = (e: any, val: any) => {
    setSelectedItem(e.target.HTML);
    const element = document.getElementById(val?.name);
    setAnchorEl(element);
    setSelectedZipcode(val);
    handleScroll(val?.name);
    setValidZip(true);
    setShowBox(true);
  };
  const handleScroll = (el: any) => {
    scroller.scrollTo(el, {
      duration: 1000,
      delay: 0,
      offset: -80,
      smooth: true,
    });
  };
  return (
    <SimpleModal
      isShowModal={modalFg}
      onCloseModal={closeHandler}
      showClose={true}
      sx={{
        width: { xs: "95%", sm: "60%", md: "55%", lg: "50%", xl: "45%" },
        top: { xs: "10%" },
      }}
      style={{ borderRadius: "4px" }}
      isRight={true}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
        width="90%"
        textAlign="center"
        marginTop="15px"
      >
        {showcheckcontent && <Box display="flex" alignItems="center">
          <img
            src={content?.img1?.data?.attributes?.url}
            alt={content?.img1?.data?.attributes?.name}
            style={{ width: "57px", height: "57px" }}
          />
        </Box>}
        {showcheckcontent && <Box
          sx={{
            fontSize: "18px",
            color: "var(--zipcode_validation_color)",
            fontWeight: "400",
            marginTop: "15px",
          }}
        >
          {content?.zp_entr}
          <span
            style={{
              color: "var(--black)",
              fontWeight: "500",
              fontFamily: "var(--font_family_Semibold)",
            }}
          >
            {address?.zip}
          </span>
        </Box>}
        {showcheckcontent && <Box
          sx={{
            fontSize: "22px",
            color: "var(--black)",
            fontWeight: "700",
            marginTop: "15px",
            marginBottom: "20px",
            fontFamily: "var(--font_family_Bold)",
          }}
        >
          {content?.des1}
        </Box>}
        <SearchBoxWithDropdown
          dropdownList={whitelistedZipcodes}
          onChange={handleZipcodeSelection}
          value={selectedItem}
          onCancel={() => canceledItem}
          placeholder={content?.des4}
          optionLabel={(option: any) => option?.zipCode}
          inputChangeHandler={inputChangeHandler}
          onCloseHandler={onCloseHandler}
          no_option={
            <span
              style={{
                fontSize: "14px",
                fontWeight: "500",
                fontFamily: "var(--font_family_Bold)",
                color: "var(--zipcode_fail)",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center "
              }}
            >
              {content?.des2}
            </span>
          }
          sx={{
            width: "70% !important",
            borderRadius: "8px !important",
          }}
        />
        {showBox && validZip && (
          <Box
            sx={{
              width: "70%",
              height: "45px",
              border: "1px solid var(--radio-color)",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            marginTop={validZip ? "8px" : "60px"}
          >
            {validZip && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "14px",
                  paddingRight: "14px",
                }}
              >
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    fontFamily: "var(--font_family_Bold)",
                    color: "var(--zipcode_validation_color)",
                  }}
                >
                  {selectedZipcode?.zipCode}
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "var(--font_family_Bold)",
                    color: "var(--zip_code_success)",
                  }}
                >
                  {content?.des6}
                </span>
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "center",
            color: "var(--zipcode_validation_color)",
          }}
          marginTop={validZip ? "25px": "75px"}
        >
          {updateFg ? content?.des3 : content?.des5}
          {updateFg && (
            <span>
              <Link
                sx={{
                  fontWeight: "700",
                  fontFamily: "var(--font_family_Bold)",
                  color: "var(--primary_color)",
                  textDecoration: "underline",
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(content?.des8, "_blank");
                }}
              >
                {content?.cl_here}
              </Link>
            </span>
          )}
        </Box>
        <Box pt={3}>
          <ButtonCust
            variantType={config.PRIMARY_BUTTON_TYPE}
            onClick={clickHandler}
            sx={{
              width: "250px",
              marginTop: "10px",
            }}
          >
            {showcheckcontent ? content?.btn_txt : content?.des8}
          </ButtonCust>
        </Box>
      </Box>
    </SimpleModal>
  );
}