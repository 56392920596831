import { Box } from "@mui/system";
import { SimpleModal, ButtonCust } from "../../../widgets";
import config from "../../../../config/env.config.json";


type Props = {
  modalFg: boolean;
  closeHandler?: any;
  content:any;
};

export function PayFailureModal(props: Props) {
  const { modalFg, closeHandler, content } = props;
  const { info, pay_fail_img, title, try_btn } =
    content?.pay_fail_md;
  return (
    <SimpleModal
      isShowModal={modalFg}
      onCloseModal={closeHandler}
      showClose={false}
      sx={{ width: { xs: "95%", sm: "60%", md: "45%", lg: "45%", xl: "45%", 
        "&.MuiGrid-root": {
          borderRadius: "30px !important"
      }, } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={1}
        pt={0.5}
        alignItems="center"
      >
         <Box display="flex" alignItems="center">
          <img
            src={pay_fail_img?.data?.attributes?.url}
            alt={pay_fail_img?.data?.attributes?.alternativeText}
            style={{ width: "48px", height: "48px", marginTop: "20px" }}
          />
        </Box>
        <Box
        component="h3"
          sx={{
            fontSize: "30px",
            color: "#000000",
            fontFamily: "var(--font_family_bold)",
            my:"26px"
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            color: "var(--text_color)",
            textAlign: "center",
            fontFamily:"var(--font_family_Medium)",
            mb:"26px",
            px:"10%"
          }}
        >
          {info}
        </Box>
        <Box>
          <ButtonCust variantType={config.PRIMARY_BUTTON_TYPE} onClick={closeHandler} sx={{ width: { md: "250px" }, mb:"20px" }}>{try_btn}</ButtonCust>
        </Box>
      </Box>
    </SimpleModal>
  );
}
