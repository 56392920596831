import React from "react";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import Config from "../../config/app.config.json";
import { SimTypes } from "../../features/checkout/Types";

const ChangeLinePreferenceCmp = isTemplateExisits(
  Config.Checkout.ChangeLinePreference
)
  ? require(`./${Config.Checkout.ChangeLinePreference.template}_ChangeLinePreferenceDialog`)
      .default
  : null;

export interface ChangeLinePreProps {
  open: boolean;
  onClose: any;
  content: any;
  onConfirm: any;
  simType: SimTypes; // "Sim" | "eSim";
  setSimType: any;
  pageName?: string;
  simCost?: any;
  isEsimAvailable?: any;
  imei?: any;
  isApnNote?: boolean;
  currentLine?: any;
  btnLoader?: boolean;
  errorMsg?: boolean;
}
export const ChangeLinePreferenceDialog = (props: ChangeLinePreProps) => {
  return <ChangeLinePreferenceCmp {...props} />;
};
