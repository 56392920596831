import Config from "../../../config/app.config.json";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";

const AddressInfoComponent = isTemplateExisits(Config.Checkout.AddressInfo)
  ? require(`./${Config.Checkout.AddressInfo.template}_AddressInfo`).default
  : null;

export type AddressFieldProps = {
  updateUserInFirebaseDatabase?: any;
  sendSimOptions?: any;
  paymentClicked?: any;
  proceedToPaymentHandler?: any;
  errorPayment?: any;
  navigate?: any;
  user?: any;
  formik?: any;
  inputRefs?: any;
  isLoggedIn: boolean;
  plan: any;
  line: any;
  setUserDetails: any;
  content: any;
  simPreference: any;
  setSimPreference: any;
  isAutoPay?: boolean;
  isSaveCard?: boolean;
  onChangeAutoPayment?: any;
  onChangeSaveCard?: any;
  autoPayContentState?: any;
  simId?: string;
  onSubmitSimHandler?: any;
  setSimId?: any;
  simInHand?: any;
  setSimInHand?: any;
  simInHandContentState?: any;
  hideBackButton?:any;
  setIsAutoSelect?: any;
};

export const AddressInfo = (props: AddressFieldProps) => {
  return <AddressInfoComponent {...props} />;
};
