import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  paymentLandingContent: any;
  errorpaymentLandingContent: any;
};

const initialState: InitialState = {
  paymentLandingContent: null,
  errorpaymentLandingContent: null,
};

// Generates pending, fulfilled and rejected action types
export const getPaymentLandingContent = createAsyncThunk(
  "paymentLanding",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.PaymentLanding.PaymentLanding.template}${StrapiApiEndPoints.paymentLanding}`,
      isStrapiApi: true,
    });
  }
);


const strapiPaymentLandingSlice = createSlice({
  name: "paymentLanding",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    builder.addCase(
      getPaymentLandingContent.fulfilled,
      (state: InitialState, action: PayloadAction<any>) => {
        state.paymentLandingContent = action.payload;
        state.errorpaymentLandingContent = null;
      }
    );
    builder.addCase(
      getPaymentLandingContent.rejected,
      (state: InitialState, action: any) => {
        state.paymentLandingContent = null;
        state.errorpaymentLandingContent =
          action.error || "Something went wrong";
      }
    );
  },
});

export default strapiPaymentLandingSlice.reducer;
