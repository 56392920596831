import { useState, createRef, useEffect } from "react";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { string, object } from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./S4_ProfileDetails.module.scss";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProfileDetailsDialog } from "./ProfileDetailsDialog";
import { DialogTypes } from "./ProfileDialogTypes";
import { emailPattern, namePattern } from "../../../utils/regexPatterns";
import { addressType } from "../../../features/checkout/Types";
import { displayPhoneNumberFormat } from "../../../utils/commonFunctions/ReusableFunctions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  customerData,
  verifyCustomerEmail,
} from "../../../features/profile/profileSlice";
import firebase from "../../../adapters/firebase";
import {
  GetCustomer,
  UpdateCustomerSource,
} from "../../../features/checkout/checkoutSlice";
import { format } from "date-fns";
import { NotificationCust, TextFieldCust, ButtonCust } from "../../widgets";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../Types/GoogleAnalytics";
import EnvConfig from "../../../config/env.config.json";
import FirebaseDBURLs from "../../../config/FirebaseDBURLs";

export const ProfileSuccessNotifications = (msg: string) => {
  NotificationCust({
    message: msg,
    duration: 5000,
    id: "profileUpdate",
    showIcon: true,
  });
};

export const ProfileErrorNotifications = (msg: string) => {
  NotificationCust({
    message: msg,
    type: "danger",
    duration: 5000,
    id: "profileUpdate",
    showIcon: true,
  });
};

const ProfileDetails = (props: any) => {
  const [state, setState] = useState<any>({
    open: false,
    getDOBDate: "",
    setNewEmail: false,
    dialogType: "",
  });
  const [dateTouched, setDateTouched] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [profileImg, setProfileImg] = useState({
    imgUrl: "",
    profileImageURL: {},
  });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingEmailBtn, setLoadingEmailBtn] = useState(false);
  const [isReachCustDisable, setReachCustDisable] = useState(false);
  const { content } = props;
  const { profileDetails } = useAppSelector(
    (state: any) => state.profileDetails
  );
  const { getCustomer, errorGetCustomer } = useAppSelector(
    (state: any) => state.checkout
  );
  const dispatch = useAppDispatch();

  const profileSchema = object({
    firstName: string()
      .trim()
      .required(content?.prf_err?.fst_req)
      .min(2, content?.prf_err?.fst_req)
      .max(24, content?.prf_err?.fst_req),
    lastName: string()
      .trim()
      .required(content?.prf_err?.lst_req)
      .min(2, content?.prf_err?.lst_req)
      .max(24, content?.prf_err?.lst_req),
  });

  const emailSchema = object({
    newEmail: string()
      .trim()
      .required(content?.prf_err?.email_req)
      .matches(emailPattern, content?.prf_err?.email_valid),
  });

  const dateref: any = createRef();
  const setDate = (date: any, val: string) => {
    if (val === "dob") {
      setState({
        ...state,
        getDOBDate: date,
      });
    }
  };
  const formik = useFormik({
    initialValues: profileDetails,
    validationSchema: profileSchema,
    onSubmit: () => {
      setLoadingBtn(true);
      handleUpdateProfileDetails();
    },
    enableReinitialize: true,
  });
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.profile,
      category: category,
      buttonValue: value,
    });
  };

  const emailForm = useFormik({
    initialValues: profileDetails,
    validationSchema: emailSchema,
    onSubmit: (values: any) => {
      setLoadingEmailBtn(true);
      handleChangeEmail(values);
    },
    enableReinitialize: true,
  });

  const handleChangeEmail = (values: any) => {
    const { newEmail, firstName, lastName } = values;
    dispatch(verifyCustomerEmail({ username: encodeURIComponent(newEmail) }))
      .then((res: any) => {
        if (res.payload.status === 200) {
          setLoadingEmailBtn(false);
          ProfileErrorNotifications(content?.notifications?.email_use);
        } else {
          const user1 = firebase.auth().currentUser;
          if (user1) {
            user1
              .updateEmail(newEmail)
              .then(async () => {
                dispatch(
                  UpdateCustomerSource({
                    customerId: profileDetails?.customerDetails?.id,
                    data: { firstName, lastName, emailId: newEmail },
                  })
                )
                  .then(() => {
                    localStorage.setItem("currentuser", JSON.stringify(user1));
                    firebase
                      .database()
                      .ref(FirebaseDBURLs.source)
                      .update(
                        { [btoa(newEmail)]: EnvConfig.OPERATOR },
                        (error) => {
                          if (error) {
                            ProfileErrorNotifications(
                              content?.notifications?.try_again
                            );
                          } else {
                            ProfileSuccessNotifications(
                              content?.notifications?.profile_updated
                            );
                          }
                          setLoadingEmailBtn(false);
                          setState({ ...state, setNewEmail: false });
                          emailForm.resetForm();
                        }
                      );
                    dispatch(
                      GetCustomer({ username: encodeURIComponent(newEmail) })
                    );
                  })
                  .catch(() => {
                    setLoadingEmailBtn(false);
                    ProfileErrorNotifications(
                      content?.notifications?.try_again
                    );
                  });
              })
              .catch(() => {
                setLoadingEmailBtn(false);
                ProfileErrorNotifications(content?.notifications?.email_use);
              });
          } else {
            ProfileErrorNotifications(content?.notifications?.try_again);
          }
        }
      })
      .catch(() => {
        setLoadingEmailBtn(false);
        ProfileErrorNotifications(content?.notifications?.try_again);
      });
  };

  const handleUpdateProfileDetails = () => {
    const { firstName, lastName, email } = formik.values;
    const user1 = firebase.auth().currentUser;
    if (user1) {
      user1
        .updateProfile({
          displayName: `${firstName} ${lastName}`,
        })
        .then(async () => {
          dispatch(
            UpdateCustomerSource({
              customerId: profileDetails?.customerDetails?.id,
              data: { firstName, lastName, emailId: email },
            })
          )
            .then((res: any) => {
              setLoadingBtn(false);
              if (res.payload.status === 200) {
                localStorage.setItem("currentuser", JSON.stringify(user1));
                ProfileSuccessNotifications(
                  content?.notifications?.profile_updated
                );
                dispatch(
                  GetCustomer({
                    username: encodeURIComponent(profileDetails.email),
                  })
                );
              } else {
                ProfileErrorNotifications(content?.notifications?.try_again);
              }
            })
            .catch(() => {
              setLoadingBtn(false);
              ProfileErrorNotifications(content?.notifications?.try_again);
            });
        })
        .catch(() => {
          setLoadingBtn(false);
          ProfileErrorNotifications(content?.notifications?.try_again);
        });
    }
    handleUpdateDOB();
    updateProfileImage();
  };

  const updateProfileImage = () => {
    let currentUser: any = localStorage.getItem("currentuser");
    currentUser = JSON.parse(currentUser);
    const uid1 = currentUser?.uid
    const uid2 = currentUser?.multiFactor?.user?.uid
    let file: any = profileImg.profileImageURL;
    if (file) {
      const storageRef = firebase
        .storage()
        .ref(
          FirebaseDBURLs.profileImgUpload + firebase.auth().currentUser?.uid
        );
      const thisRef = storageRef.child(file.name);
      thisRef.put(file).then((snapshot) => {
        firebase
          .database()
          .ref(FirebaseDBURLs.profileInfo + btoa(uid1 ? uid1 : uid2))
          .update({ profilePic: snapshot.metadata.fullPath });
      });
    }
  };

  const handleUpdateDOB = () => {
    let currentUser: any = localStorage.getItem("currentuser");
    currentUser = JSON.parse(currentUser);
    const uid1 = currentUser?.uid
    const uid2 = currentUser?.multiFactor?.user?.uid
    if (state.getDOBDate) {
      let dateFormated: any = format(state.getDOBDate, "dd/MM/yyyy");
      firebase
        .database()
        .ref(FirebaseDBURLs.profileInfo + btoa(uid1 ? uid1 : uid2))
        .update({ dateOfBirth: dateFormated }, function (error) {
          if (error) {
            //console.log('FD error', error);
          } else {
            //console.log('success');
          }
        });
    }
  };

  const handleClose = () => {
    setState({ ...state, open: false, setNewEmail: false, dialogType: "" });
    formik.setErrors({});
  };

  const handleOpenDialog = (dialogType: string) => {
    setState({
      ...state,
      open: true,
      setNewEmail: false,
      dialogType: dialogType,
    });
  };

  const handleSave = (dialogType: string) => {
    if (dialogType === DialogTypes.changeEmail) {
      setState({ ...state, open: false, setNewEmail: true, dialogType: "" });
    } else {
      setState({ ...state, open: false, setNewEmail: false, dialogType: "" });
    }
  };

  const handleBackEmailForm = () => {
    setState({ ...state, setNewEmail: false });
    emailForm.resetForm();
  };

  useEffect(() => {
    if (getCustomer) {
      const { firstName, lastName, emailId, primaryNumber, reachNumber } =
        getCustomer;
      const address = getCustomer?.addresses?.find(
        (item: any) => item.type === addressType.billing
      );
      const fullAddress = `${
        address?.address2 ? `${address?.address2},` : ""
      } ${address?.address1}, ${address?.city}, ${address?.state}, ${
        address?.country
      }, ${address?.zip}`;
      dispatch(
        customerData({
          ...profileDetails,
          firstName,
          lastName,
          email: emailId,
          phoneNumber: reachNumber ? reachNumber : primaryNumber,
          fullAddress,
          customerDetails: getCustomer,
        })
      );
    }
  }, [getCustomer]);

  useEffect(() => {
    if (errorGetCustomer && errorGetCustomer.status === "FAILURE") {
      if (EnvConfig?.IS_SHOPWARE_ENABLED) {
        let currentuser: any = localStorage.getItem("currentuser");
        currentuser = JSON.parse(currentuser);
        setReachCustDisable(true);
        dispatch(
          customerData({
            ...profileDetails,
            firstName: currentuser?.displayName?.split(" ")[0],
            lastName: currentuser?.displayName?.split(" ")[1],
            email: currentuser?.email,
            phoneNumber: "",
            fullAddress: "",
            customerDetails: "",
          })
        );
      }
    }
  }, [errorGetCustomer]);

  const onChangeImage = (e: any) => {
    if (e.target.files.length > 0) {
      const ImgURL = URL.createObjectURL(e.target.files[0]);
      setProfileImg({ profileImageURL: e.target.files[0], imgUrl: ImgURL });
    }
  };

  const stringToDate = (_date: any, _format: any, _delimiter: any) => {
    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      dateItems[yearIndex],
      month,
      dateItems[dayIndex]
    );
    return formatedDate;
  };

  useEffect(() => {
    let currentUserInfo: any = localStorage.getItem("currentuser");
    currentUserInfo = JSON.parse(currentUserInfo);
    const uid1 = currentUserInfo?.uid
    const uid2 = currentUserInfo?.multiFactor?.user?.uid
    setCurrentUser(currentUserInfo);
    // calls get customer details api
    dispatch(
      GetCustomer({ username: encodeURIComponent(currentUserInfo?.email) })
    );
    // firebase call for profile image and date of birth
    firebase
      .database()
      .ref(`${FirebaseDBURLs.profileInfo}${btoa(uid1 ? uid1 : uid2)}`)
      .once("value")
      .then((snapshot: any) => {
        let data: any = snapshot.val();
        if (data?.dateOfBirth) {
          let getDate = stringToDate(data.dateOfBirth, "dd/MM/yyyy", "/");
          setState({ ...state, getDOBDate: getDate });
        }
        if (data?.profilePic) {
          let storage: any = firebase.storage();
          storage
            .ref(snapshot.val().profilePic)
            .getDownloadURL()
            .then((url: any) => {
              if (url) {
                setProfileImg({ ...profileImg, imgUrl: url });
              } else {
                setProfileImg({
                  ...profileImg,
                  imgUrl: content?.profile_img?.data?.attributes?.url,
                });
              }
            });
        }
      })
      .catch(() => {
        setProfileImg({
          ...profileImg,
          imgUrl: content?.profile_img?.data?.attributes?.url,
        });
      });
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "var(--lightgrey)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!state?.setNewEmail ? (
        <Box
          className={styles.profile_main}
          sx={{
            m: { xs: "20px", md: "40px" },
            bgcolor: "var(--white)",
            width: { xs: "100%", md: "40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              m: "40px 0",
            }}
          >
            <Avatar
              sx={{ width: "100px", height: "100px" }}
              alt={content?.profile_img?.data?.attributes?.alternativeText}
              src={
                profileImg?.imgUrl
                  ? profileImg?.imgUrl
                  : content?.profile_img?.data?.attributes?.url
              }
            />
            <label
              htmlFor="file inp"
              style={{ marginTop: "-30px", height: "44px", zIndex: 10 }}
            >
              <Box
                component="img"
                sx={{ cursor: "pointer", ml: "65px" }}
                alt={content?.profile_img?.data?.attributes?.alternativeText}
                src={content?.edit_icon?.data?.attributes?.url}
              />
            </label>
            <input
              type="file"
              style={{ display: "none" }}
              id="file inp"
              accept="image/*"
              onChange={(e: any) => {
                onChangeImage(e);
              }}
            />
          </Grid>
          <Grid container display={"flex"} justifyContent={"center"}>
            <Grid item xs={10} className={styles.profile_row}>
              <TextFieldCust
                type="text"
                name="firstName"
                fullWidth
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                label={content?.first_name_plc}
                id="First Name"
                onChange={(e: any) => {
                  if (
                    namePattern.test(e.target.value || e.target.value === "")
                  ) {
                    formik.handleChange(e);
                  }
                }}
                error={formik.errors?.firstName ? true : false}
                helperText={<>{formik.errors?.firstName}</>}
                disabled={EnvConfig?.IS_SHOPWARE_ENABLED && isReachCustDisable}
              />
            </Grid>
            <Grid item xs={10} className={styles.profile_row}>
              <TextFieldCust
                fullWidth
                type="text"
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
                name="lastName"
                label={content?.last_name_plc}
                onChange={(e: any) => {
                  if (
                    namePattern.test(e.target.value || e.target.value === "")
                  ) {
                    formik.handleChange(e);
                  }
                }}
                error={formik.errors?.lastName ? true : false}
                helperText={<>{formik.errors?.lastName}</>}
                disabled={EnvConfig?.IS_SHOPWARE_ENABLED && isReachCustDisable}
              />
            </Grid>
            <Grid item xs={10} className={styles.profile_row}>
              <TextFieldCust
                fullWidth
                label={content?.contact_num}
                readOnly
                type="string"
                value={displayPhoneNumberFormat(formik.values.phoneNumber)}
                sx={{
                  "& .MuiInputBase-input": {
                    cursor: "not-allowed",
                  },
                }}
              />
            </Grid>
            <Grid
              item
              container
              className={styles.profile_row}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid
                item
                xs={10}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    fd: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    className={styles.form_Second_label}
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      handleOpenDialog(DialogTypes.changeEmail);
                      GAAndFBEventsHandler(
                        gaCategory.editProfile,
                        content?.change_email
                      );
                    }}
                  >
                    {EnvConfig?.IS_SHOPWARE_ENABLED &&
                      !isReachCustDisable &&
                      content?.change_email}
                  </Typography>
                </Box>
                <TextFieldCust
                  fullWidth
                  readOnly
                  label={content?.email}
                  value={formik.values.email}
                  type="email"
                  sx={{
                    "& .MuiInputBase-input": {
                      cursor: "not-allowed",
                    },
                  }}
                />
              </Grid>
            </Grid>
            {EnvConfig?.IS_SHOPWARE_ENABLED && !isReachCustDisable && (
              <Grid
                item
                container
                className={styles.profile_row}
                display={"flex"}
                justifyContent={"center"}
              >
                <Grid item xs={10}>
                  <Box
                    sx={{
                      display: "flex",
                      fd: "row",
                      justifyContent: "space-between",
                      mb: 1.5,
                    }}
                  >
                    <Typography
                      className={styles.form_address}
                      sx={{
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {content?.address}
                    </Typography>
                    <Typography
                      className={styles.form_Second_label}
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "var(--font_family_Semibold)",
                        fontWeight: "bold !important",
                      }}
                      onClick={() => {
                        handleOpenDialog(DialogTypes.changeAddress);
                        GAAndFBEventsHandler(
                          gaCategory.editProfile,
                          content?.change_address
                        );
                      }}
                    >
                      {content?.change_address}
                    </Typography>
                  </Box>
                  <Typography className={styles.form_label}>
                    {formik.values.fullAddress}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              className={styles.profile_row}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid item xs={10}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    className={styles.form_Second_label}
                    sx={{
                      textTransform: "capitalize",
                      fontFamily: "var(--font_family_Semibold)",
                      fontWeight: "bold !important",
                    }}
                    onClick={() => {
                      handleOpenDialog(DialogTypes.changePassword);
                      GAAndFBEventsHandler(
                        gaCategory.editProfile,
                        content?.change_pwd
                      );
                    }}
                  >
                    {EnvConfig?.IS_SHOPWARE_ENABLED &&
                      !isReachCustDisable &&
                      content?.change_pwd}
                  </Typography>
                </Box>
                <TextFieldCust
                  label={content?.pwd}
                  fullWidth
                  value={"12345678"}
                  readOnly
                  type="password"
                  sx={{
                    mt: 1.5,
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={styles.profile_row}
              display={"flex"}
              justifyContent={"center"}
            >
              <Grid item xs={10}>
                <Typography className={styles.form_label}>
                  {content?.dob}
                </Typography>
                <DatePicker
                  ref={dateref}
                  selected={state.getDOBDate}
                  onChange={(date: any) => {
                    setDate(date, "dob");
                    setDateTouched(true);
                  }}
                  dateFormat="MM/dd/yyyy"
                  placeholderText={content?.dob_plc}
                  value={state.getDOBDate || ""}
                  maxDate={new Date()}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              sm={6}
              md={4}
              mb={"40px"}
              container
              className={styles.profile_row}
              display={"flex"}
              justifyContent={"center"}
            >
              <ButtonCust
                sx={{ width: "60%" }}
                onClick={() => {
                  formik.handleSubmit();
                  GAAndFBEventsHandler(gaCategory.editProfile, content?.btn);
                  setDateTouched(false);
                }}
                variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                loading={loadingBtn}
                disabled={
                  (EnvConfig?.IS_SHOPWARE_ENABLED && isReachCustDisable) ||
                  !(formik?.dirty || dateTouched)
                }
              >
                {content?.btn}
              </ButtonCust>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className={styles.profile_email_main}>
          <Box className={styles.profile_email_block}>
            <Box className={styles.profile_email_head}>
              <Box className={styles.back_button} onClick={handleBackEmailForm}>
                <ArrowBackIcon className={styles.arrow_icon} />
              </Box>
              <Box className={styles.profile_email_changeHead}>
                {content?.change_email}
              </Box>
            </Box>
            <Box className={styles.profile_email_content} px={"20px"}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={7} sm={5} md={4}>
                  <TextFieldCust
                    name="newEmail"
                    value={emailForm.values.newEmail}
                    label={content?.new_email}
                    onChange={emailForm.handleChange}
                    error={emailForm.errors?.newEmail ? true : false}
                    helperText={<>{emailForm.errors?.newEmail}</>}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.profile_email_text}>
                <Grid
                  item
                  className={styles.profile_email_note}
                  xs={10}
                  sm={8}
                  md={7}
                >
                  {content?.change_email_desc1}
                  {formik.values.email}
                  {content?.change_email_desc2}
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: "30px",
                }}
              >
                <ButtonCust
                  onClick={() => {
                    emailForm.handleSubmit();
                    GAAndFBEventsHandler(gaCategory.editProfile, content?.btn1);
                  }}
                  loading={loadingEmailBtn}
                  variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                >
                  {content?.btn1}
                </ButtonCust>
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
      <ProfileDetailsDialog
        content={content}
        open={state.open}
        dialogType={state.dialogType}
        onClick={handleSave}
        onClose={handleClose}
        setState={setState}
      />
    </Box>
  );
};

export default ProfileDetails;
