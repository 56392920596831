import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { Divider, ButtonCust } from "../../../widgets";
import styles from "./PayNow.module.scss";
import { CardTypes } from "../../../../enums";
import { getIsCardExpired } from "../../../../utils/commonFunctions/ReusableFunctions";
import envConfig from "../../../../config/env.config.json";
import AutoPaySaveCard from "./AutoPaySaveCard";
import {
  showPayFormFn,
  showSaveCardAlertDialog,
} from "../../../../features/activation/activationSlice";

const ActivationPayNow = (props: any) => {
  const {
    currentLine,
    payNowConfirm,
    payBtnLoader,
    manageCardFg,
    content,
    autoPayCard,
    setPayBtnLoader,
  } = props;
  const dispatch = useAppDispatch();
  const { isSimInHand = false } = currentLine || {};

  const { monthInAdvanceData, enablePayForm } = useAppSelector(
    (state: any) => state?.activation || {}
  );

  const {
    title,
    info_txt,
    chng_btn,
    due_txt,
    fee_txt,
    discount_txt,
    pay_btn,
    visa,
    discover,
    master_card,
    jcb,
    american_ex,
    promo_img,
    num_mask,
    sub_title
  } = content?.pay_now;
  let isCardExpired = false;
  let actData: any[] = [
    {
      text: currentLine?.reachPlanDisplayName,
      value: monthInAdvanceData?.amount ?? "-",
      id: 1,
    },
    {
      text: discount_txt,
      value:
        monthInAdvanceData?.promoDiscount > 0
          ? monthInAdvanceData?.promoDiscount
          : null,
      id: 3,
    },
    {
      text: fee_txt,
      value: monthInAdvanceData?.taxAmount ?? "-",
      id: 2,
    },
    {
      text: due_txt,
      value: monthInAdvanceData?.amountToPay ?? "-",
      id: 4,
    },
  ];

  if (autoPayCard?.dateOfExpiry) {
    isCardExpired = getIsCardExpired(autoPayCard);
  }

  return (
    <Stack>
      {currentLine?.reachPlanId && (
        <>
          <Box
            className={styles.activation_paynow_card}
            m="20px auto 50px"
            sx={{ width: { xs: "90%", sm: "90%", md: "80%", lg: "80%" } }}
          >
            <Box
              className={styles.main_container}
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Typography className={styles.heading} mt="20px">
                {title}
              </Typography>
              {sub_title?<Typography className={styles.sub_title} >
                {sub_title}                
              </Typography>:null}
              <Divider
                styles={{
                  width: "75px !important",
                  height: "5px",
                  borderRadius: "25px !important",
                  m: "10px 0 35px 0",
                }}
              />
              {!monthInAdvanceData ? (
                <Box
                  className={styles.loaderCheck}
                  sx={{ marginBottom: "20px" }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {actData?.map((each) => (
                    <>
                      {each?.id === 3 && each?.value <= 0 ? null : (
                        <Box
                          className={styles.row}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p="15px"
                          m="10px"
                          sx={{
                            width: {
                              xs: "80%",
                              sm: "75%",
                              md: "60%",
                              lg: "45%",
                            },
                          }}
                          key={each.id}
                        >
                          <Typography className={styles.row_text}>
                            {each.text}
                          </Typography>
                          <Typography className={styles.row_value}>
                            {each.id === 3
                              ? `-${GetCurrencyFixedToTwo(each.value)}`
                              : GetCurrencyFixedToTwo(each.value)}
                          </Typography>
                        </Box>
                      )}
                    </>
                  ))}
                  {monthInAdvanceData?.couponName && (
                    <Box
                      className={styles.promo_coupon}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p="16px"
                      m="20px"
                      sx={{
                        width: {
                          xs: "80%",
                          sm: "75%",
                          md: "60%",
                          lg: "45%",
                        },
                      }}
                    >
                      <img
                        src={promo_img?.data?.attributes?.url}
                        alt={promo_img?.data?.attributes?.alternativeText}
                        style={{
                          width: "22px",
                          height: "22px",
                          marginRight: "10px",
                        }}
                      />
                      {monthInAdvanceData?.couponName}
                    </Box>
                  )}
                  {isSimInHand && envConfig.IS_AUTOPAY_ENABLED && (
                    <AutoPaySaveCard
                      styles={styles}
                      currentLine={currentLine}
                      setPayBtnLoader={setPayBtnLoader}
                      content={content}
                    />
                  )}

                  {!isSimInHand && (
                    <Typography
                      className={styles.text1}
                      mb="16px"
                      sx={{
                        width: { xs: "90%", sm: "75%", md: "60%", lg: "45%" },
                      }}
                    >
                      {info_txt}
                    </Typography>
                  )}
                  {autoPayCard?.ccNumberMask && (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={styles.image_text_container}
                      >
                        <img
                          src={
                            autoPayCard?.type?.toUpperCase() === CardTypes?.VISA
                              ? visa?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.MASTERCARD
                              ? master_card?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.JCB
                              ? jcb?.data?.attributes?.url
                              : autoPayCard?.type?.toUpperCase() ===
                                CardTypes?.AMERICANEXPRESS
                              ? american_ex?.data?.attributes?.url
                              : discover?.data?.attributes?.url
                          }
                          alt={
                            autoPayCard && autoPayCard.type === CardTypes.VISA
                              ? visa?.data?.attributes?.alternativeText
                              : american_ex?.data?.attributes?.alternativeText
                          }
                          style={{ width: "42px", height: "27px" }}
                        />
                        <Typography className={styles.text2} pl="10px">
                          {num_mask} {autoPayCard?.ccNumberMask}
                        </Typography>
                        <Typography
                          className={styles.text3}
                          ml="10px"
                          onClick={manageCardFg}
                        >
                          {chng_btn}
                        </Typography>
                      </Box>
                    </>
                  )}
                  {autoPayCard?.ccNumberMask || (isSimInHand && !envConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND) ? (
                    <>
                      <ButtonCust
                        variantType={envConfig.PRIMARY_BUTTON_TYPE}
                        sx={{ m: "20px 0 30px 0", padding: "13px 23px" }}
                        onClick={
                          isSimInHand && !envConfig?.ENABLE_ONE_TIME_CHARGES_PAYMENT_FOR_SIM_IN_HAND
                            ? () => {
                                if (enablePayForm) {
                                  dispatch(showPayFormFn(true));
                                  setPayBtnLoader(true);
                                } else {
                                  dispatch(showSaveCardAlertDialog(true));
                                }
                              }
                            : payNowConfirm
                        }
                        loading={payBtnLoader}
                        disabled={isCardExpired}
                      >
                        {pay_btn}
                      </ButtonCust>
                    </>
                  ) : (
                    <Box sx={{ pt: 4 }}></Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
};
export default ActivationPayNow;
