import { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addMultipleCacheData,
  getCache,
  useNonInitialEffect,
} from "../../../utils/commonFunctions";
import { getSeoDataContent } from "../../../features/strapi/componentSlice";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";
import EnvConfig from "../../../config/env.config.json";

interface HelmetProps {
  helmetPageName?: any;
  children?: ReactNode;
}

export const HelmetCust = ({ helmetPageName, children }: HelmetProps) => {
  const [cacheSeoData, setCacheSeoData] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { seoData } = useAppSelector(
    (state: any) => state?.strapi?.commonPage || {}
  );
  useEffect(() => {
    getCache("seoData", getSeoDataContent, setCacheSeoData, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("seoData", seoData);
  }, [seoData]);
  const content = cacheSeoData ? cacheSeoData : seoData;
  const pageContent = content && content[helmetPageName];
  const { title, description, keywords, canonicalUrl } = pageContent || {};
  return (
    pageContent && (
      <>
        <HelmetProvider>
          <Helmet>
            <link rel="icon" href={content?.brandLogo?.data?.attributes?.url} />
            <title>{BrandNameUpdate(title)}</title>
            <meta
              property="og:title"
              name="title"
              content={BrandNameUpdate(title)}
            />
            <meta
              property="og:description"
              name="description"
              content={BrandNameUpdate(description)}
            />
            <meta
              property="og:site_name"
              name="site_name"
              content={BrandNameUpdate(content?.brandName)}
            />
            <meta name="keywords" content={BrandNameUpdate(keywords)} />
            <meta name="description" content={BrandNameUpdate(description)} />
            <meta
              name="robots"
              content={
                EnvConfig?.IS_HUBSPOT_TEST_ENV
                  ? "NOINDEX, NOFOLLOW"
                  : "INDEX, FOLLOW"
              }
            />
            <meta name="twitter:title" content={BrandNameUpdate(title)} />
            <meta
              name="twitter:description"
              content={BrandNameUpdate(description)}
            />
            <link
              rel="canonical"
              href={window.location.pathname}
            />
            {children}
          </Helmet>
        </HelmetProvider>
        {/* <h1>{title}</h1> */}
      </>
    )
  );
};
