import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import { version } from "../../config/RoutingURLs";
import ApiEndPoints from "../../config/ApiEndPoints";


type InitialState = {
  data: any;
  errorData: any;
  nutritionLabelData: any;
  broadBandFactStatus: any;
  loading: boolean;
  errorNutritionalLabel: any;
};
const initialState: InitialState = {
  data: {},
  errorData: null,
  nutritionLabelData: {},
  broadBandFactStatus: null,
  loading: false,
  errorNutritionalLabel: null,
};

export const reservePromoCoupon = createAsyncThunk(
  "planpage/reservePromoCoupon",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: ApiEndPoints.reservePromoCoupon,
      body: action.payload.data,
    });
  }
);

export const nutritionLabels = createAsyncThunk(
  "planpage/nutritionLabels",
  async () => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.nutritionLabel}`,
      });
      return response;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

export const broadbandfactShare = createAsyncThunk(
  "planpage/broadbandfactShare",
  async (action: any) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.shareBroadBandFact}${action.id}/share-purchase-nutrition-label`,
      });
      return response;
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const planpageSlice = createSlice({
  name: "planpage",
  initialState,
  reducers: {
    // Local action to reset the state
    updateBroadBandState: (state) => {
      state.broadBandFactStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
        reservePromoCoupon.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.errorData = null;
      }
    );
    builder.addCase(reservePromoCoupon.rejected, (state, action) => {
      state.data = {};
      state.errorData = action.error || "Something went wrong";
    });
    builder.addCase(
      nutritionLabels.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.nutritionLabelData = action.payload.data;
        state.errorNutritionalLabel = null;
      }
    );
    builder.addCase(nutritionLabels.rejected, (state, action) => {
      state.nutritionLabelData = {};
      state.errorNutritionalLabel = action.payload || "Something went wrong";
    });
    builder.addCase(broadbandfactShare.pending, (state, action) => {
      state.broadBandFactStatus = null;
      state.loading = true;
    });
    builder.addCase(broadbandfactShare.fulfilled, (state, action) => {
      state.broadBandFactStatus = action.payload.data;
      state.loading = false;
    });
    builder.addCase(broadbandfactShare.rejected, (state) => {
      state.broadBandFactStatus = null;
      state.loading = false;
    });
  },
});

export const { updateBroadBandState } = planpageSlice.actions;

export default planpageSlice.reducer;
