import { Box, Link, Typography } from "@mui/material";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppSelector } from "../../../app/hooks";

import { getResolutionDetails } from "../../../utils/commonFunctions";

type NLCardProps = {
  NLContent: any;
  plan: any;
  planPerLinesCost: any;
  minShipvalue: any;
  maxShipvalue: any;
};
const T1_NLCard = (props: NLCardProps) => {
  const { configKeys } = useAppSelector((state: any) => state?.activation);

  const { NLContent, planPerLinesCost, minShipvalue, maxShipvalue } = props;
  const { plan: p } = props;
  const { mobile } = getResolutionDetails();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: { xs: "0px", sm: "20px" },
      }}
    >
      {!mobile ? (
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Bold)",
              textAlign: "center",
            }}
          >
            {" "}
            {`${p?.planData} GB`} {p?.displayName}
          </Typography>
        </Box>
      ) : null}

      <Box
        height={{ xs: "870", sm: "920" }}
        my={{ xs: 0, sm: 4 }}
        display="flex"
        p={2}
        sx={{
          border: "1px solid var(--text_color)",
          flexDirection: "column",
          width: { xs: "100%", sm: "290px" },
        }}
      >
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "18px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            {NLContent?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontFamily: "var(--font_family_Bold)",
              lineHeight: "18px",
              marginTop: "5px",
            }}
          >
            {NLContent?.brandname}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              marginTop: "5px",
              lineHeight: "22px",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {`${p?.planData} GB`} {p?.displayName}
          </Typography>
          <Typography
            sx={{
              marginTop: "5px",
              fontSize: "11px",
              lineHeight: "15px",
              fontFamily: "var(--font_family_Medium)",
              marginBottom: "5px",
            }}
          >
            {NLContent.disclosure}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            justifyContent: " space-between;",
          }}
        >
          <Box>
            <Typography
              sx={{
                marginTop: "5px",
                fontSize: "14px",
                lineHeight: "15px",
                fontFamily: "var(--font_family_Medium)",
                marginBottom: "5px",
                fontWeight: "var(--font_weight_2)",
              }}
            >
              {NLContent.mprice_heading}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                marginTop: "5px",
                fontSize: "14px",
                lineHeight: "15px",
                fontFamily: "var(--font_family_Medium)",
                marginBottom: "5px",
                fontWeight: "var(--font_weight_2)",
              }}
            >
              {GetCurrencyFixedToTwo(planPerLinesCost)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            justifyContent: " space-between;",
          }}
        >
          <Typography
            sx={{
              marginTop: "5px",
              fontSize: "11.2px",
              lineHeight: "15px",
              fontFamily: "var(--font_family_Medium)",
              marginBottom: "5px",
            }}
            dangerouslySetInnerHTML={{
              __html: NLContent.monthlyPriceInfo,
            }}
          ></Typography>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ marginTop: "5px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "var(--font_family_Bold)",
              }}
              dangerouslySetInnerHTML={{
                __html: NLContent.add_charges,
              }}
            ></Typography>
          </Box>
          <Box sx={{ marginLeft: "10px" }}>
            <Typography
              sx={{
                fontSize: "11.2px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_2)",
              }}
            >
              {NLContent.monthly_fee_heading}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.cost_rec_heading}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.cost_rec_value}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginLeft: "10px" }}>
            <Typography
              sx={{
                fontSize: "11.2px",
                fontFamily: "var(--font_family_Medium)",
                fontWeight: "var(--font_weight_2)",
              }}
            >
              {NLContent.one_time_fee_heading}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "10px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.act_fee_heading}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {GetCurrencyFixedToTwo(configKeys?.ACTIVATION_FEE_LINE[1])}{" "}
                {NLContent.act_fee_value}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.sim_ship_heading}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {GetCurrencyFixedToTwo(minShipvalue)} –
                {GetCurrencyFixedToTwo(maxShipvalue)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: "5px" }}>
            <Typography
              sx={{
                fontSize: "11.2px",
                fontFamily: "var(--font_family_Medium)",
              }}
              dangerouslySetInnerHTML={{
                __html: NLContent.add_charges_desc,
              }}
            ></Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.termination_fee_heading}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.termination_fee_value}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.govt_taxes_heading}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                }}
              >
                {NLContent.govt_taxes_value}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              marginTop: "5px",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {NLContent.discount_heading}
          </Typography>
          <Typography
            sx={{
              fontSize: "11.2px",

              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {NLContent.discount_desc}{" "}
            <Link
              sx={{
                color: "var(--text_color)",
                textDecorationColor: "var(--text_color)",
                fontSize: "11.2px",
                fontFamily: "var(--font_family_Medium)",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(configKeys?.DISCOUNTS_BUNDLE_LABEL_URL, "_blank");
              }}
            >
              {NLContent.discount_link}
            </Link>{" "}
            {NLContent.discount_more_text}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
          my="5px"
        >
          <Box>
            <Typography
              sx={{
                fontSize: "12px",

                fontFamily: "var(--font_family_Bold)",
              }}
            >
              {NLContent.speeds_title}
            </Typography>
          </Box>
          {p?.labelInfo?.speedInfos.map((speedInfo: any) => {
            return (
              <Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",

                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {speedInfo?.band}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",

                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {NLContent.download_speed_heading}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",
                        fontFamily: "var(--font_family_Bold)",
                      }}
                    >
                      {speedInfo?.dlSpeed}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",

                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {NLContent.upload_speed_heading}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",
                        fontFamily: "var(--font_family_Bold)",
                      }}
                    >
                      {speedInfo?.ulSpeed}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                    marginLeft: "10px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",

                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {NLContent.latency_heading}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "11.2px",
                        fontFamily: "var(--font_family_Bold)",
                      }}
                    >
                      {speedInfo?.latency}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ marginTop: "5px" }}>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {NLContent.data_mp_heading}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              {p?.planSubType !== "PREMIUM" ? (
                <Typography
                  sx={{
                    fontSize: "11.2px",
                    fontFamily: "var(--font_family_Bold)",
                  }}
                >
                  {p?.planData} GB
                </Typography>
              ) : null}
            </Box>
          </Box>
          {p?.planSubType === "PREMIUM" ? (
            <Typography
              sx={{
                fontSize: "11.2px",
                fontFamily: "var(--font_family_Medium)",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {NLContent.unlimitedSpeedInfo?.replace("{planData}", p?.planData)}
            </Typography>
          ) : null}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Semibold)",
                }}
              >
                {NLContent.add_charges_data_heading}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "11.2px",
              fontFamily: "var(--font_family_Medium)",
              display: "flex",
              justifyContent: "end",
            }}
          >
            {NLContent.additional_data_charges}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {NLContent.net_manage_heading}
              </Typography>
            </Box>
            <Box>
              <Link
                sx={{
                  color: "var(--text_color)",
                  textDecorationColor: "var(--text_color)",
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(
                    configKeys?.NETWORK_MANAGEMENT_LABEL_URL,
                    "_blank"
                  );
                }}
              >
                {NLContent.net_manage_url}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "11.2px",

                  fontFamily: "var(--font_family_Bold)",
                }}
              >
                {NLContent.privacy_heading}
              </Typography>
            </Box>
            <Box>
              <Link
                sx={{
                  color: "var(--text_color)",
                  textDecorationColor: "var(--text_color)",
                  fontSize: "11.2px",
                  fontFamily: "var(--font_family_Medium)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open(configKeys?.PRIVACY_LABEL_URL, "_blank");
                }}
              >
                {NLContent.privacy_url}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: " 1px solid black",
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              marginTop: "5px",
              fontFamily: "var(--font_family_Bold)",
            }}
          >
            {NLContent.cust_support_title}
          </Typography>
          <Typography
            sx={{
              fontSize: "11.2px",
              fontFamily: "var(--font_family_Medium)",
              marginBottom: "5px",
            }}
          >
            {NLContent.contact_us_heading}{" "}
            <Link
              sx={{
                color: "var(--text_color)",
                fontSize: "11.2px",
                textDecorationColor: "var(--text_color)",
                fontFamily: "var(--font_family_Medium)",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(configKeys?.BRAND_WEB_SUPPORT_LABEL_URL, "_blank");
              }}
            >
              {configKeys?.BRAND_WEB_SUPPORT_LABEL_URL}
            </Link>{" "}
            /<br />
            {configKeys?.BRAND_SUPPORT_MOBILE_NO}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
            marginBottom: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "11.2px",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {NLContent.fcc_desc}
          </Typography>
        </Box>

        <Box
          sx={{
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
          my="5px"
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontFamily: "var(--font_family_Medium)",
              textAlign: "right",
              fontWeight: "var(--font_weight_2)",
              color: "var(--text_color)",
              cursor: "pointer",
            }}
          >
            <Link
              sx={{
                color: "var(--text_color)",
                textDecoration: "none",
              }}
              onClick={() => {
                window.open("https://www." + NLContent.fcc_url, "_blank");
              }}
            >
              {" "}
              {NLContent.fcc_url}
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            height: "max-content",
            width: "100%",
            display: " flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: "11.2px",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {NLContent?.UPI}
          </Typography>
          <Typography
            sx={{
              fontSize: "11.2px",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {p?.uniqueIdentifier}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default T1_NLCard;
