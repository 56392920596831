import { useEffect, useState } from "react";
import { Box, RadioGroup, Typography } from "@mui/material";
import {
  ButtonCust,
  Divider,
  SimpleModal,
  RadioCust,
  CustToolTip,
} from "../widgets";
import { ChangeLinePreProps } from "./ChangeLinePreferenceDialog";
import classes from "./T3_ChangeLinePreferenceDialog.module.scss";
import EnvConfig from "../../config/env.config.json";
import { SimTypes } from "../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { CarrierTypes } from "../../enums";
import { BrandNameUpdate } from "../../utils";
import { PopperToolTip } from "./PopperToolTip";
import ApnNote from "../apnSettings/Apn_Note";

const T3_ChangeLinePreferenceDialog = (props: ChangeLinePreProps) => {
  const {
    open,
    onClose,
    content,
    onConfirm,
    simType,
    setSimType,
    pageName,
    simCost,
    imei,
    isApnNote,
    isEsimAvailable,
    currentLine,
    btnLoader,
    errorMsg,
  } = props;
  const [openEsimToolTipIndex, setOpenEsimToolTipIndex] = useState(false);
  const [displayImeiModal, setDisplayImeiModal] = useState(true);
  const closeImeiModal = () => {
    setDisplayImeiModal(false);
  };
  // const closeCompImeiModal = () =>{
  //   hideCheckIMEIModal(false)
  // }
  useEffect(() => {
    setSimType(
      currentLine?.esim && isEsimAvailable ? SimTypes?.eSim : SimTypes?.sim
    );
  }, []);
  const handleSimTypeChange = (e: any) => {
    setSimType(e.target.value);
  };

  const onCloseEsimToolTip = () => {
    setOpenEsimToolTipIndex(false);
  };
  return (
    <SimpleModal
      isShowModal={open}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "60%", md: "82%", lg: "38%", xl: "35%" },
        top: "17%",
      }}
      style={{ display: displayImeiModal ? "inline" : "none" }}
    >
      <Box
        style={{ width: "100%", alignItems: "center" }}
        sx={{ mt: { md: "12px" } }}
      >
        <Box
          className="h4"
          sx={{
            fontFamily: "var(--font_family_Bold)",
            fontSize: "24px",
            mb: "15px",
          }}
          textAlign="center"
        >
          {content?.Preferred_sim}
        </Box>
        {/* <Box style={{ margin: "15px auto 30px auto" }}>
          <Divider />
        </Box> */}

        {pageName === "activation" && content?.act_desc_txt ? (
          <Box
            style={{
              fontFamily: "var(--font_family_Medium)",
              fontSize: "18px",
              color: "var(--text-color)",
            }}
          >
            {BrandNameUpdate(content?.act_desc_txt)} {pageName}
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: { xs: "330px", sm: "350px" },
            margin: "auto",
          }}
        >
          <Box className={classes.label_txt}>
            {pageName === "activation"
              ? content?.single_line
              : content?.add_line_lbl}
          </Box>
          <Box sx={{ marginLeft: { sm: "auto", xs: "15px" } }}>
            <RadioGroup
              value={simType}
              onChange={(e: any) => handleSimTypeChange(e)}
              style={{ display: "flex" }}
              row
            >
              <RadioCust value={SimTypes.sim} label={content?.sim_lbl} />
              <RadioCust
                value={SimTypes.eSim}
                label={content?.esim_lbl}
                disabled={
                  isEsimAvailable != "undefined" ? !isEsimAvailable : false
                }
              />
              {content?.res?.esim_tooltip_msg &&
              content?.sim_info_ico?.data?.attributes?.url ? (
                <CustToolTip
                  className={classes.esim_tooltip_container}
                  title={
                    openEsimToolTipIndex ? (
                      <PopperToolTip
                        text={content?.res?.esim_tooltip_msg}
                        onClose={onCloseEsimToolTip}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "12px",
                        }}
                      />
                    ) : null
                  }
                  open={openEsimToolTipIndex}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <Box
                    component="img"
                    className={classes.info_icon}
                    sx={{ ml: "-15px" }}
                    src={content?.sim_info_ico?.data?.attributes?.url}
                    onClick={() => {
                      setOpenEsimToolTipIndex(true);
                    }}
                  ></Box>
                </CustToolTip>
              ) : null}
            </RadioGroup>
          </Box>
        </Box>
        {/* {pageName === "activation" && simType === SimTypes.sim && (
          <Box
            style={{
              display: "flex",
              alignItems: "baseline",
              margin: "auto",
              marginTop: "15px",
            }}
          >
            <Box className={classes.note_style}>{content?.note_txt}</Box>
            <Box pl={1}>
              {content?.note_desc1 &&
                content?.note_desc1?.map((val: any) => {
                  return (
                    <Box className={classes.note_style}>
                      {" "}
                      {val?.item.replace(
                        "${cost}",
                        GetCurrencyFixedToTwo(simCost)
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        )} */}
        <Box className={classes.btn_container}>
          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            sx={{
              my: "10px",
              width: {
                xs: "95%",
                md: "240px",
              },
              height: "45px",
            }}
            onClick={onConfirm}
            loading={btnLoader}
          >
            {EnvConfig.OPERATOR.toLowerCase() === CarrierTypes.ATT
              ? content?.cl_btn_ctx
              : simType === SimTypes.eSim && pageName !== "activation"
              ? content?.ctx_c_c_btn
              : content?.cl_btn_ctx}
          </ButtonCust>
          {errorMsg && (
            <Box
              sx={{
                color: " var(--danger)",
                textAlign: "center",
                fontFamily: "var(--font_family_Bold)",
                padding: "20px",
              }}
            >
              {content?.err_msg}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            // display: "flex",
            // justifyContent: { xs: "center", sm: "start" },
            marginTop: { xs: "10px", sm: "0px" },
          }}
        >
          {EnvConfig?.IS_APN_SETTINGS_ENABLED && imei?.os && isApnNote && (
            <ApnNote
              imeiData={{
                os: imei?.os ?? "",
                osVer: imei?.osVer ?? 0,
              }}
              isShowAsPill={false}
              currentLine={imei}
              closeCompatModal={closeImeiModal}
              closeCompImeiModal={onClose}
            />
          )}
        </Box>
      </Box>
    </SimpleModal>
  );
};

export default T3_ChangeLinePreferenceDialog;
