import get from "lodash/get";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import { strapiBannerContent } from "../../../features/strapi/componentSlice";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";

export type InternalBannerProps = {
  bannerContent: any;
};

type Props = {
  pageName: string;
  apiEndPoint: string;
  type?: string;
};
export const InternalBanner = (props: Props) => {
  const { pageName, apiEndPoint, type } = props;
  let template = get(Config, `${pageName}.Banner.template`);
  const BannerComponent = isTemplateExisits(get(Config, `${pageName}.Banner`))
    ? require(`./${template}_InternalBanner`).default
    : null;
  const dispatch = useAppDispatch();
  const [cacheInternalBanner, setCacheInternalBanner] = useState<any>(null);

  const { BannerContent } = useAppSelector(
    (state: any) => state.strapi.commonPage || {}
  );
  const bannerContent = BannerContent?.banner;
  const apiData: any = {
    pageName: pageName,
    apiEndPoint: apiEndPoint,
  };
  useEffect(() => {
    getCache(
      apiEndPoint,
      strapiBannerContent,
      setCacheInternalBanner,
      dispatch,
      apiData
    );
  }, [apiEndPoint]);

  useNonInitialEffect(() => {
    addMultipleCacheData(apiEndPoint, BannerContent?.banner);
  }, [BannerContent]);
  const onButtonClick = () => {};

  return (bannerContent || cacheInternalBanner) && BannerComponent ? (
    <BannerComponent
      bannerContent={cacheInternalBanner ? cacheInternalBanner : bannerContent}
      onButtonClick={onButtonClick}
    />
  ) : null;
};
