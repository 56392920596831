import { useEffect } from "react";
import {
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Typography,
} from "@mui/material";
import { ButtonCust, SimpleModal } from "../../widgets";
import { AddLine_ChangePlanTemplateProps } from "./AddLine_ChangePlan";
import styles from "./S1_AddLine_ChangePlan.module.scss";
import EnvConfig from "../../../config/env.config.json";
import { planModalType } from "../../../features/checkout/Types";
import unlimitedPlanDiscount from "../../../config/discountCouponMNM.json";
import { caclulatePlanCostBasedOnEligibility } from "../../../utils/commonFunctions/ReusableFunctions";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NutritionLabel } from "../../home/PlanCard/NutritionLabel/NutritionLabel";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { nutritionLabels } from "../../../features/planpage/planpageSlice";

const T1_AddLine_ChangePlan = (props: AddLine_ChangePlanTemplateProps) => {
  const {
    isShowModal,
    modalType,
    setOpenModal,
    plandata,
    index,
    formik,
    handleChangePlan,
    handleAddLine,
    content,
    currentPlan,
    amendedReachPlanId,
    initialCount = 0,
    isEsim,
  } = props;
  const {
    title,
    title2,
    gb,
    dollar,
    per_month,
    sd_stream,
    hd_stream,
    select_plan,
    gb_data,
    unlimited_data,
    stream_img,
    data_img,
    plan_features,
    hotspot_img,
    no_hotspot_img,
    unlmtd_img,
    amendedPlan_desc,
  } = content;
  const dispatch = useAppDispatch();
  const { buttonStyle } = styles;
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const onClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(nutritionLabels());
  }, []);
  
  const cardStyle = {
    borderRadius: "10px",
    boxShadow: "0px 4px 6px 0px #00000026",
    width: { xs: "285px", sm: "285px", md: "285px", lg: "285px" },
    margin: "auto",
    background: "var(--white)",
    height: "442px",
  };

  const selectedPlanStyle = {
    background: "var(--pink-red)",
    width: "293px",
    height: "473px",
    borderRadius: "10px",
    margin: { xs: "20px auto 0", sm: "0px  0", md: "0px 0px" },
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: theme.palette.mode === "light" ? "#00A8EC" : "#DDDDDD",
    },
  }));

  const selectPlanHandler = (newPlan: any) => {
    if (modalType === planModalType.changePlan) {
      handleChangePlan(index, newPlan);
    } else if (modalType === planModalType.addLine) {
      handleAddLine(index, newPlan);
    }
    onClose();
  };

  const calculateUnlimitedCostMnM = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateCost = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const priceCalculationHandler: any = (data: any, selectedLine: any = 1) => {
    let PlanDiscountCost, planPerLinesCost: any;
    if (data.isUnlimited) {
      const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    } else {
      const { planCost } = calculateCost(data, selectedLine);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data[0].discountInDollar * selectedLine
      );
    }
    planPerLinesCost = PlanDiscountCost / selectedLine;
    return planPerLinesCost;
  };

  return (
    <SimpleModal
      isShowModal={isShowModal}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "100%", md: "100%", lg: "95%", xl: "85%" },
        boxShadow:
          "0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11), 0px 4px 8px 0px rgba(79, 94, 113, 0.10), 0px 8px 16px 0px rgba(79, 94, 113, 0.09), 0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07)",
        borderRadius: "9px",
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "18px", sm: "40px" },
          fontFamily: "var(--font_family_Bold)",
          color: "var(--primary_brand_color)",
        }}
      >
        {modalType === planModalType.changePlan
          ? title2
          : `${title} ${index + initialCount + 2}`}
      </Typography>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          m: "20px auto",
          width: "100%",
        }}
      >
        {plandata?.map((p: any, i: any) => {
          const planPerLinesCost = priceCalculationHandler(p);
          return (
            <Box
              sx={
                modalType === planModalType.changePlan &&
                currentPlan?.displayName === p.displayName
                  ? selectedPlanStyle
                  : { marginTop: "26px" }
              }
              key={p.name}
            >
              {modalType === planModalType.changePlan &&
              currentPlan?.displayName === p.displayName ? (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "var(--white)",
                    fontFamily: "var(--font_family_Bold)",
                    width: "100%",
                    margin: "5px auto 0",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  Current Plan
                </Typography>
              ) : null}
              <Box sx={cardStyle}>
                <Box sx={{ p: { xs: "20px 10px 10px", sm: "30px 15px 25px" } }}>
                  <Box sx={{ height: "310px", padding: "0px 8px" }}>
                    <Box
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "23px",
                          color: "var(--primary_brand_color)",
                          fontFamily: "var(--font_family_Bold)",
                          width: "100%",
                          height: "60px",
                          margin: "auto",
                        }}
                      >
                        {p?.displayName}{" "}
                        {p?.planSubType !== "PREMIUM"
                          ? `${p?.planData} ${gb}`
                          : ""}
                      </Typography>
                      {/* <Typography
                        sx={{
                          fontSize: "16px",
                          color: "var(--primary_brand_color)",
                          fontFamily: "var(--font_family_Semibold)",
                          width: "98%",
                        }}
                      >
                        {p?.planSubType !== "PREMIUM"
                          ? plan_features?.subhead_normal
                          : p.planType === "HSD"
                          ? plan_features?.subhead_hd
                          : plan_features?.subhead_sd}
                      </Typography> */}
                    </Box>

                    {/* <BorderLinearProgress
                      variant="determinate"
                      value={(p?.planData / 25) * 100}
                    /> */}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "center",
                      }}
                    >
                      {/* {p?.planSubType !== "PREMIUM" ? ( */}
                      {/* <Typography
                        sx={{
                          fontSize: "24px",
                          // fontWeight: "var(--font_weight_4)",
                          color: "var(--primary_brand_color)",
                          fontFamily: "var(--font_family_Bold)",
                          width: "40%",
                          margin: "20px auto",
                        }}
                      >
                        {p?.planData} {gb}
                      </Typography> */}
                      {/* ) : null} */}

                      <Box
                        sx={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "28px",
                            // fontWeight: "var(--font_weight_4)",
                            fontFamily: "var(--font_family_Bold)",
                            color: "var(--pink-red)",
                          }}
                        >
                          {dollar}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "40px",
                            fontWeight: "var(--font_weight_4)",
                            color: "var(--pink-red)",
                          }}
                        >
                          {planPerLinesCost.toFixed(2)}
                          {/* {p.baseLinePrice} */}
                        </Typography>
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "12px",
                            fontWeight: "var(--font_weight_4)",
                            color: "var(--primary_brand_color)",
                            marginTop: "13px",
                          }}
                        >
                          {per_month}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        margin: "20px auto",
                      }}
                    >
                      <Box
                        component="img"
                        alt={
                          unlmtd_img?.data?.attributes?.alternativeText || ""
                        }
                        height="20px"
                        width="20px"
                        src={unlmtd_img?.data?.attributes?.url || ""}
                      />
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Bold)",
                          fontSize: "14px",
                        }}
                      >
                        {plan_features?.unlimited}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        alt={
                          stream_img?.data?.attributes?.alternativeText || ""
                        }
                        height="20px"
                        width="20px"
                        src={
                          p?.tethering
                            ? hotspot_img?.data?.attributes?.url || ""
                            : no_hotspot_img?.data?.attributes?.url || ""
                        }
                      />
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Bold)",
                          fontSize: "14px",
                        }}
                      >
                        {p?.tethering
                          ? p?.tethering + plan_features?.hotspot
                          : plan_features?.no_hotspot}
                      </Typography>
                    </Box>

                    {/* <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        marginTop: "25px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Bold)",
                          fontSize: "14px",
                        }}
                      >
                        {p.planSubType !== "PREMIUM"
                          ? plan_features?.great_for
                          : plan_features?.best_for}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                        margin: "5px 0px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                        }}
                      >
                        {p.name === "Breezeline Start"
                          ? plan_features?.wifi
                          : p.name === "Breezeline Smart"
                          ? plan_features?.customers
                          : p.name === "Breezeline Unlimited Fast"
                          ? plan_features?.music
                          : plan_features?.professional}
                      </Typography>
                    </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        component="img"
                        alt={
                          stream_img?.data?.attributes?.alternativeText || ""
                        }
                        height="20px"
                        width="20px"
                        src={stream_img?.data?.attributes?.url || ""}
                      />
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Bold)",
                          fontSize: "14px",
                        }}
                      >
                        {p.planType === "HSD"
                          ? plan_features?.premium_qlty
                          : plan_features?.standard_qlty}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <Box
                        component="img"
                        alt={data_img?.data?.attributes?.alternativeText || ""}
                        height="20px"
                        width="20px"
                        src={data_img?.data?.attributes?.url || ""}
                      />
                      <Typography
                        sx={{
                          color: "var(--text_color_12)",
                          fontFamily: "var(--font_family_Bold)",
                          fontSize: "14px",
                        }}
                      >
                        {p?.planSubType === "PREMIUM"
                          ? p?.planData + " " + p?.dataUnit + plan_features?.psd
                          : p?.planData +
                            " " +
                            p?.dataUnit +
                            plan_features?.hsd}
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box>
                    <Typography
                      sx={{
                        color: "var(--text_color_12)",
                        fontFamily: "var(--font_family_Bold)",
                        fontSize: "14px",
                      }}
                    >
                      {plan_features?.great_for}
                    </Typography>
                  </Box> */}

                  {amendedReachPlanId === p.name ? (
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "var(--text_color)",
                        textAlign: "center",
                        fontFamily: "var(--font_family_Medium)",
                        marginTop: "12px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: amendedPlan_desc,
                      }}
                    ></Typography>
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <ButtonCust
                        className={buttonStyle}
                        variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                        data-testid="selectPlanHandler"
                        onClick={(e: any) => {
                          // (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                          //   ? shareablePlans?.maxLines >= selectedLine
                          //     ? selectPlanHandler(e, "byTheGig")
                          //     : " "
                          //   : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                          selectPlanHandler(p);
                          let eventPrefix =
                            location.pathname === RoutingURLs.checkout
                              ? globalVal?.gtmEvents?.changeSelectPlan
                              : location.pathname === RoutingURLs.activation
                              ? isEsim
                                ? "mobile_dashboard_esim_"
                                : "mobile_dashboard_psim_"
                              : "";
                          gtmTagManager({
                            event: `${eventPrefix}${p.planData}${p.dataUnit}_select_plan`,
                            click_section: "checkout",
                          });
                        }}
                        sx={{
                          "&:hover": {
                            backgroundColor:
                              "var(--button_hover_color) !important",
                            border:
                              "1px solid var(--button_hover_color) !important",
                          },
                          "&:disabled": {
                            backgroundColor: "var(--grey-shade87) !important",
                            border: "none !important",
                          },
                        }}
                        disabled={
                          modalType === planModalType.changePlan &&
                          (currentPlan?.displayName === p.displayName ||
                            amendedReachPlanId === p.name)
                            ? true
                            : false
                        }
                      >
                        {modalType === planModalType.changePlan &&
                        currentPlan?.displayName === p.displayName
                          ? "Selected"
                          : select_plan}
                      </ButtonCust>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box sx={{ paddingLeft: '10px', paddingRight: '10px'}}>
      <NutritionLabel
        NLContent={content}
        plandata={plandata}
        priceCalculationHandler={priceCalculationHandler}
        selectedLine={1}
      />
      </Box>
      <Typography
        my={2}
        sx={{
          color: "var(--text_color_12)",
          fontSize: { xs: "14px", sm: "16px" },
          fontFamily: "var(--font_family_Medium)",
          textAlign: "center",
          width: { xs: "100%", sm: "80%", md: "64%" },
        }}
      >
        {plan_features?.disclaimer?.replace(
          /discountAmount/g,
          unlimitedPlanDiscount.data[0].discountInDollar || ""
        )}{" "}
        <a
          href={plan_features?.disclaimer_terms_link}
          target={plan_features?.isSameWindow ? "_self" : "_blank"}
        >
          <Box
            component="span"
            my={2}
            sx={{
              color: "var(--text_color_12)",
              fontSize: { xs: "14px", sm: "16px" },
              fontFamily: "var(--font_family_Medium)",
              textDecoration: "underline",
            }}
          >
            {plan_features?.disclaimer_terms}
          </Box>
        </a>
      </Typography>
    </SimpleModal>
  );
};
export default T1_AddLine_ChangePlan;
