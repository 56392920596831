import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
    NMData: any;
    errorNMData: any;
};

const initialState: InitialState = {
    NMData: null,
    errorNMData: null
};

// Generates pending, fulfilled and rejected action types
export const NMContent = createAsyncThunk(
    "terms/NMContent",
    () => {
        return apiJunction.makeRequest({
            method: "get",
            url: `/${StrapiApiEndPoints.networkManagement}`,
            isStrapiApi: true,
        });
    }
);

const strapiNMSlice = createSlice({
    name: "NetworkManagement",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder.addCase(
            NMContent.fulfilled,
            (state: InitialState, action: PayloadAction<any>) => {
                state.NMData = action?.payload?.data?.data?.attributes;
                state.errorNMData = null;
            }
        );
        builder.addCase(NMContent.rejected, (state: InitialState, action: any) => {
            state.NMData = null;
            state.errorNMData = action.error || "Something went wrong";
        });
    },
});

export default strapiNMSlice.reducer;