import { Box } from "@mui/material";
import "./T1_PlanItem.scss";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import Config from "../../config/env.config.json";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../Types/GoogleAnalytics";
import { GetCurrencyFixedToTwo } from "../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useLocation } from "react-router-dom";
import { RoutingURLs } from "../../config/RoutingURLs";
import { useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { CustomerStatus } from "../../enums";

interface T1_PlanItemProps {
  selectedPlan?: any;
  plan?: any;
  activePlanName?: any;
  key?: string | number;
  handlePlanSelection: any;
  content: any;
  currentPlanBorder: boolean;
  user: any;
  planIndex: string | number;
}
export const T1_PlanItem = ({
  selectedPlan,
  plan,
  activePlanName,
  key,
  handlePlanSelection,
  content,
  currentPlanBorder,
  user,
  planIndex,
}: T1_PlanItemProps) => {
  const [totalActiveLines, setTotalActiveLines] = useState<number>(0)
  const { group } = useAppSelector(
    (state: any) => state?.account || {}
  );
  const location = useLocation();
  const isCheckoutPage = location.pathname === RoutingURLs.checkout;

  useEffect(()=> {
    group?.data?.map((el: any) => {
      if(el.status === CustomerStatus.ACTIVE) {
        setTotalActiveLines((count) => count + 1)
      }
    })
  },[])
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const planData = content?.pl_data
    ? content?.pl_data?.replace("{plan}", `${plan?.planData}${plan?.dataUnit}`)
    : null;
  const planFeature = content?.p_i_feature?.find(
    (fItem: any, fIndex: number) => {
      return fIndex === planIndex;
    }
  )?.item;

  return (
    <Box>
      {!user?.reachPlanChangeDate && user?.amendedReachPlanId === plan?.name ? (
        <Box className="move_next_month_label">{content?.move_next_month}</Box>
      ) : activePlanName === plan?.name ? (
        <Box className="active_item_label">{content?.active_label}</Box>
      ) : null}
      <Box
        key={key}
        onClick={
          activePlanName !== plan?.name &&
          !(user?.amendedReachPlanId === plan?.name)
            ? () => {
                handlePlanSelection(plan);
                GAAndFBEventsHandler(gaCategory.changePlan, plan?.name);
              }
            : () => null
        }
        className={
          activePlanName === plan?.name
            ? "active_plan"
            : !user?.reachPlanChangeDate &&
              user?.amendedReachPlanId === plan?.name
            ? "cursor_block"
            : "in_active_plan"
        }
        id={currentPlanBorder ? "actvie_card_border" : "none"}
      >
        <Box
          className={
            JSON.stringify(selectedPlan) === JSON.stringify(plan)
              ? "CheckoutCard ActiveCard"
              : "CheckoutCard"
          }
          sx={{ mb: { md: "25px", borderRadius: "8px" } }}
        >
          <Box className="leftDiv">
            <Box className="cardTitle">
              {plan?.displayNameWeb}&nbsp;{plan?.planData}
              {plan?.dataUnit}
            </Box>
            {Config?.IS_SHOW_PLAN_DATA_IN_CHANGE_PLAN && isCheckoutPage ? (
              <Box className="cardSubTitle">
                {plan?.isUnlimited ? (
                  content?.unlimited
                ) : (
                  <>
                    {plan?.planData}
                    {plan?.dataUnit}
                  </>
                )}
              </Box>
            ) : null}
          </Box>
          <Box className="rightDiv">
            <Box className="gbData">
              {`$${plan?.baseLinePrice.toFixed(2)}`} {totalActiveLines > 1 && `(${totalActiveLines} Lines)`}
              {/* <span
                className={
                  JSON.stringify(selectedPlan) === JSON.stringify(plan)
                    ? "monthDataActive"
                    : "monthData"
                }
              >
                {content?.line_unit}
              </span> */}
            </Box>
            {/* <Box className="sharable">
              {planFeature?<Box sx={{lineHeight:"15px", paddingY:"5px"}}>{planFeature}</Box>:null}
              {content?.person_icon?.data?.attributes?.url &&
                generateImageContainer({
                  data: plan?.isUnlimited
                    ? content?.person_icon?.data?.attributes?.url
                    : content?.ppl_icon?.data?.attributes?.url,
                  attrs: { height: "18px", width: "22px" },
                  overrides: { sx: { mx: "8px" } },
                })}
              {content?.sharable_lbl && plan?.isUnlimited
                ? content?.fixed_lable2
                  ? `${content?.fixed_lable1} ${plan?.maxLines} ${content?.fixed_lable2}`
                  : content?.fixed_lable1
                : content?.sharable_lbl}
              {planData ? <Box>{planData}</Box> : null}
            </Box> */}
            {content?.add_line_label ? (
              plan?.addLineSplitPrice ? (
                content?.save_text && (
                  <Box className="addLine">
                    {content?.save_text}{" "}
                    <span className="perMonth">{content?.per_line_price}</span>
                    {content?.every_line}
                  </Box>
                )
              ) : (
                <Box className="addLine">
                  {content?.add_line_label}{" "}
                  <span className="perMonth">
                    {GetCurrencyFixedToTwo(plan?.additionalLinePrice)}
                    {content?.line_unit}
                  </span>
                </Box>
              )
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default T1_PlanItem;
