import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import appConfig from "../../../../config/app.config.json";
import { getAccountLockContent } from "../../../../features/account-lock/accountLockSlice";
import {
  addMultipleCacheData,
  getCache,
  isTemplateExisits,
  useNonInitialEffect,
} from "../../../../utils/commonFunctions";
const AccountInfo = isTemplateExisits(appConfig.FCCAccountLock.AccountInfo)
  ? require(`./${appConfig.FCCAccountLock.AccountInfo.template}_AccountInfo`)
      .default
  : null;

const AccountInfoComponent = (props: any) => {
  const [content, setContent] = useState<any>(null);

  const { fccAccountContent, fccAccountContentLoading, fccAccountImages } =
    useAppSelector((state: any) => state?.strapi?.accountLockSlice || {});
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCache("FccAccountContent", getAccountLockContent, setContent, dispatch);
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("FccAccountContent", fccAccountContent);
  }, [fccAccountContent]);
  if (fccAccountContentLoading) {
    return (
      <Box
        sx={{
          mt: "36px",
          mb: "36px",
          display: { xs: "block", sm: "flex" },
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mx: { xs: "auto", sm: "16px", md: "auto" },
          width: { xs: "95%", sm: "95%", md: "800px" },
        }}
      >
        <Skeleton variant="rounded" width="100%" />
        <Skeleton animation="wave" width="100%" />
        <Skeleton animation="wave" width="100%" />
      </Box>
    );
  }
  return (
    <AccountInfo
      content={content ? content : fccAccountContent}
      fccAccountImages={fccAccountImages}
    />
  );
};
export default AccountInfoComponent;
