import React from "react";
import { TextFieldCust, ButtonCust } from "../widgets";
import { SignUpProps } from ".";
import {
  Box,
  Divider,
  Grid,
  Typography,
  Stack,
  Container,
  useMediaQuery,
  useTheme,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { SwitchCust } from "../";
import { numberInStringPattern } from "../../utils/regexPatterns";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../config/env.config.json";
import { RoutingURLs } from "../../config/RoutingURLs";
import { Store } from "react-notifications-component";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";
import { gtmTagManager } from "../../utils/commonFunctions/GtmTagManager";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Cursor = "pointer";

const SignUpT1 = (props: SignUpProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const {
    content,
    onAppleSignUp,
    onFacebookSignUp,
    onGoogleSignUp,
    isSignUpClicked,
    formik,
    handleFormikChange,
    showLogin,
    formSubmit,
  } = props;

  const {
    heading_logo,
    heading_signup,
    signup_logos,
    fname_label,
    lname_label,
    email_label,
    pwd_label,
    pass_info_main,
    pass_info_1,
    pass_info_2,
    btn_signup,
    terms,
    already_acc1,
    already_acc2,
    terms_url,
    note,
    note_txt,
  } = content || {};

  const passwordLengthCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    formik.values.password.length >= 8;

  const passwordPatternCheck = () =>
    formik.values.password &&
    formik.values.password !== "" &&
    numberInStringPattern.test(formik.values.password.toString());

  const getLogo = (index: number) => signup_logos?.data[index].attributes.url;
  const getAltText = (index: number) =>
    signup_logos?.data[index].attributes.alternativeText;
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.signup,
      category: category,
      buttonValue: value,
    });
  };
  const handleSignup = (category: any, value: any) => {
    Store.removeNotification("SignUpErrorNotification");
    GAAndFBEventsHandler(category, value);
    gtmTagManager({
      event: gaScreenNames.signup,
      signup_method: "Email",
    });
  };

  const handleTermsNavigation = () => {
    if (EnvConfig.IS_TERMS_EXTERNAL_LINK) {
      window.open(terms_url, "_blank");
    } else {
      window.open(RoutingURLs.terms, "_blank");
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const VisibilityOffIcon = content?.v_off_img?.data?.attributes?.url;
  const VisibilityOnIcon = content?.v_on_img?.data?.attributes?.url;
  const VisibilityOffAltText = BrandNameUpdate(
    content?.v_off_img?.data?.attributes?.alternativeText
  );
  const VisibilityOnAltText = BrandNameUpdate(
    content?.v_on_img?.data?.attributes?.alternativeText
  );
  return (
    <Box>
      <Typography
        component={"h5"}
        variant={"h5"}
        fontSize="24px"
        lineHeight={"36px"}
        fontFamily={"var(--font_family_Bold)"}
        fontWeight={"var(--font_weight_4)"}
        // pt={{ xs: "25px", sm: 0 }}
        // pb={{ xs: "25px" }}
        // pl={{ xs: "10px", md: "unset" }}
        p={{ xs: "30px 20px", sm: "30px" }}
        textAlign={{ xs: "center", sm: "unset" }}
        display={"flex"}
        color={"var(--black)"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {heading_logo?.data?.attributes?.url && (
          <Box
            component="img"
            alt={heading_logo?.data?.attributes?.alternativeText}
            src={heading_logo?.data?.attributes?.url}
            width="26px"
            height="26px"
            mr="10px"
          />
        )}
        {heading_signup}
      </Typography>

      <Divider
        sx={{
          bgcolor: "var(--ford-grey-color)",
          opacity: "0.5",
        }}
      />

      {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          pt={{ xs: 0, sm: 3 }}
        >
          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(0)}
            src={getLogo(0)}
            width="162px"
            height="50px"
            onClick={onAppleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(1)}
            src={getLogo(1)}
            width="162px"
            height="50px"
            onClick={onFacebookSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "none", sm: "block", cursor: Cursor } }}
            alt={getAltText(2)}
            src={getLogo(2)}
            width="162px"
            height="50px"
            onClick={onGoogleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(3)}
            src={getLogo(3)}
            width="fit-content"
            height="50px"
            onClick={onAppleSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(4)}
            src={getLogo(4)}
            width="fit-content"
            height="50px"
            onClick={onFacebookSignUp}
          />

          <Box
            component="img"
            sx={{ display: { xs: "block", sm: "none", cursor: Cursor } }}
            alt={getAltText(5)}
            src={getLogo(5)}
            width="fit-content"
            height="50px"
            onClick={onGoogleSignUp}
          />
        </Stack>
      )}

      <Container disableGutters={!useMediaQuery(theme.breakpoints.only("xs"))}>
        {EnvConfig?.SIGNUP_AND_LOGIN_THROUGH_SOCIAL_MEDIA && (
          <Divider
            sx={{
              "&.MuiDivider-root": {
                "&::before, &::after": {
                  borderTop: `dotted 1px var(--ford-grey-color)`,
                },
              },
              padding: "20px 0px 0px",
            }}
          >
            <Typography
              component={"div"}
              fontFamily={"var(--font_family_Bold)"}
            >
              or
            </Typography>
          </Divider>
        )}
        <form
          noValidate
          onKeyDown={(e) => {
            /* form will be submitted on press enter key  */
            if (e.key.toLocaleLowerCase() === "enter") formik.handleSubmit(e);
          }}
          className="signin-form-fields"
          data-testid="location-input"
          onSubmit={(e) => formSubmit(e)}
        >
          <Box
            sx={{ p: { xs: "30px 10px 10px 10px", sm: "30px 30px 10px 30px" } }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.firstName}
                  label={fname_label}
                  name="firstName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.firstName && formik?.errors?.firstName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.firstName && formik?.errors?.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik?.values?.lastName}
                  label={lname_label}
                  name="lastName"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik?.touched?.lastName && formik?.errors?.lastName
                      ? true
                      : false
                  }
                  helperText={
                    formik?.touched?.lastName && formik?.errors?.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik?.values?.email}
                  label={email_label}
                  name="email"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.email && formik?.errors?.email
                      ? true
                      : false
                  }
                  helperText={formik.touched?.email && formik.errors.email}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextFieldCust
                  value={formik.values.password}
                  label={pwd_label}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e: any) => handleFormikChange(e)}
                  error={
                    formik.touched?.password && formik.errors.password
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched?.password && formik.errors.password
                  }
                  endAdornment={
                    EnvConfig.ENABLE_PASSWORD_VISIBILITY &&
                    (VisibilityOffIcon || VisibilityOnIcon) && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <Tooltip title={content?.helper_txt?.off}>
                              <img
                                src={VisibilityOffIcon}
                                alt={VisibilityOffAltText}
                                style={{ width: "22px", height: "18px" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={content?.helper_txt?.on}>
                              <img
                                src={VisibilityOnIcon}
                                alt={VisibilityOnAltText}
                                style={{ width: "22px", height: "18px" }}
                              />
                            </Tooltip>
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </Grid>
            </Grid>
            {pass_info_main && (
              <Stack
                display={"flex"}
                alignItems={{ xs: "unset", sm: "center" }}
                mt={"22px"}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <Typography
                  component={"div"}
                  sx={{
                    height: "20px",
                    fontSize: "14px",
                    fontFamily: "var(--font_family_Medium)",
                    color: "var(--black)",
                  }}
                >
                  {pass_info_main}
                </Typography>
                <Typography
                  component={"div"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "14px",
                      ml: "5px",
                      color: `${
                        passwordLengthCheck()
                          ? "var(--primary_brand_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                  >
                    {passwordLengthCheck() ? "✓" : "✕"}
                  </Typography>
                  <Typography
                    component={"div"}
                    sx={{
                      marginLeft: "7px",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Regular)",
                      color: `${
                        passwordLengthCheck()
                          ? "var(--primary_brand_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                  >
                    {pass_info_1}
                  </Typography>
                </Typography>
                <Typography
                  component={"div"}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    component="div"
                    sx={{
                      fontSize: "11px",
                      ml: "5px",
                      color: passwordPatternCheck()
                        ? "var(--primary_brand_color)"
                        : "var(--account_create_criteria_un_match_txt_color)"
                        ? "var(--account_create_criteria_un_match_txt_color)"
                        : "var(--ford-grey-color)",
                    }}
                  >
                    {passwordPatternCheck() ? "✓" : "✕"}
                  </Typography>

                  <Typography
                    component={"div"}
                    sx={{
                      marginLeft: "7px",
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Regular)",
                      color: `${
                        passwordPatternCheck()
                          ? "var(--primary_brand_color)"
                          : "var(--account_create_criteria_un_match_txt_color)"
                          ? "var(--account_create_criteria_un_match_txt_color)"
                          : "var(--ford-grey-color)"
                      }`,
                    }}
                  >
                    {pass_info_2}
                  </Typography>
                </Typography>
              </Stack>
            )}
            {note && (
              <Typography
                component={"div"}
                sx={{
                  fontSize: "14px",
                  lineHeight: "25px",
                  fontWeight: "var(--font_weight_2)",
                  fontFamily: "var(--font_family_Semibold)",
                  marginTop: "20px",
                  color: "var(--dark-charcoal-fade)",
                }}
              >
                {note}
              </Typography>
            )}
            {note_txt && (
              <Typography
                component={"div"}
                sx={{
                  fontSize: "14px",
                  lineHeight: "25px",
                  fontWeight: "var(--font_weight_0)",
                  fontFamily: "var(--font_family_Regular)",
                  color: "var(--text_color)",
                }}
              >
                {BrandNameUpdate(note_txt)}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "15px",
              }}
            >
              <SwitchCust
                handleSwitchToggle={(
                  e: React.ChangeEvent<HTMLInputElement>
                ) => {
                  formik.setFieldValue("checked", e.target.checked);
                  formik.setTouched({
                    ...formik.touched,
                    ["checked"]: false,
                  });
                }}
                checked={formik.values.checked}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "16px",
                        color: "var(--text_color_13)",
                        fontFamily: "var(--font_family_Semibold)",
                        mr: "4px",
                      }}
                    >
                      {content?.helper_txt?.agree_to}{" "}
                    </Typography>
                    <Typography
                      component={"span"}
                      onClick={handleTermsNavigation}
                      sx={{
                        cursor: Cursor,
                        fontSize: "16px",
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {BrandNameUpdate(terms)}
                    </Typography>
                  </Box>
                }
              />
            </Box>
          </Box>

          {formik.touched?.checked && formik.errors.checked && (
            <Typography
              component={"div"}
              sx={{
                color: "var(--danger)",
                textAlign: "center",
                fontFamily: "var(--font_family_Bold)",
                fontSize: "0.75rem",
              }}
            >
              {formik.errors.checked}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
              },
            }}
          >
            <ButtonCust
              sx={{ my: "15px" }}
              loading={!isSignUpClicked}
              disabled={!formik.values.checked}
              type={"submit"}
              variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
              onClick={() => handleSignup(gaCategory.signup, btn_signup)}
            >
              {btn_signup}
            </ButtonCust>
          </Box>
        </form>
        <Typography
          component={"div"}
          fontSize={"14px"}
          fontFamily={"var(--font_family_Medium)"}
          pb="30px"
          sx={{
            textAlign: {
              xs: "center",
            },
            color: "var(--text_color_14)",
          }}
        >
          {already_acc1}&nbsp;
          <Typography
            component={"span"}
            fontSize={"inherit"}
            color={"var(--primary_color)"}
            fontFamily={"inherit"}
            fontWeight={"var(--font_weight_2)"}
            sx={{ cursor: Cursor }}
            onClick={showLogin}
          >
            {already_acc2}
          </Typography>
        </Typography>
      </Container>
    </Box>
  );
};

export default SignUpT1;
