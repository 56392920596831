import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import { Box, Grid } from "@mui/material";
import styles from "./S5_LeadGeneration.module.scss";
import { LeadGenProps } from "./LeadGeneration";
import { InputBaseCust } from "../../InputBaseCust";
import { BrandNameUpdate } from "../../../utils/commonFunctions/BrandNameUpdate";

const LeadGenerationComponent = (props: LeadGenProps) => {
  const {
    heading,
    sub_heading,
    place_holder,
    button_text,
    success_text,
    intouch_text,
  } = props.leadContent || {};
  return (
    props.leadContent && (
      <Grid
        container
        className={styles.lead_generation}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: {
            xs: "calc(var(--mobile_section_padding)/2) 0 var(--mobile_section_padding)",
            sm: "calc(var(--ipad_section_padding)/2) 0 var(--ipad_section_padding)",
            md: "calc(var(--desktop_section_padding)/2) 0 var(--desktop_section_padding)",
          },
          minHeight: { xs: "274px", sm: "182px", md: "198px" },
        }}
      >
        <Box
          data-testid="title"
          className={styles.lead_generation_title}
          sx={{ fontSize: { xs: "22px", md: "30px" } }}
        >
          {heading}
        </Box>

        <Box className={styles.lead_generation_line}></Box>
        <Box
          className={styles.lead_generation_subtitle}
          sx={{ fontSize: { xs: "16px", md: "18px" } }}
        >
          {BrandNameUpdate(sub_heading)}
        </Box>
        {props.emailSharedCoverage ? (
          <>
            <Grid
              container
              xs={11}
              sm={6}
              md={4.5}
              lg={3.8}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.email_success}
              >
                <Box
                  className={styles.email_success_text}
                  sx={{ fontSize: "18px", lineHeight: 1.5 }}
                >
                  {success_text}
                </Box>
              </Box>
              <Box
                className={styles.keep_in_touch_text}
                sx={{ fontSize: "14px", textAlign: "center", lineHeight: 1.5 }}
              >
                {intouch_text}
              </Box>
            </Grid>
          </>
        ) : (
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "column" },
              justifyContent: "space-evenly",
              width: { xs: "100%", sm: "70%", md: "50%" },
              alignItems: { xs: "center", md: "center" },
              paddingX: { xs: "15px", md: 0 },
            }}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                props.submitHandler(e);
              }
            }}
          >
            <InputBaseCust
              submitHandler={props.submitHandler}
              submitClick={props.submitClick}
              content={props.leadContent}
              value={props.email}
              label={place_holder}
              onChange={props.emailHandler}
              error={
                (props.email.length >= 1 && !validateEmail(props.email)) ||
                (props.submitBtnClick && !props.email) ||
                (props.submitBtnClick &&
                  props.email !== "" &&
                  !validateEmail(props.email))
              }
              helperText={
                (props.email.length >= 1 || props.submitBtnClick) &&
                props.helperTextHandler()
              }
            />
          </Grid>
        )}
      </Grid>
    )
  );
};

export default LeadGenerationComponent;
