import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Config from "../../../config/app.config.json";
import EnvConfig from "../../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import { updateToHubspot } from "../../../utils/commonFunctions/hubspot";
import { leadGenerationContent } from "../../../features/strapi/homePageSlice";
import {
  getInTouchApi,
  resetGetInTouch,
} from "../../../features/homepage/homepageSlice";
import { validateEmail } from "../../../utils/commonFunctions/ValidateEmail";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
const LeadGenerationComponent = isTemplateExisits(Config.Home.LeadGeneration)
  ? require(`./${Config.Home.LeadGeneration.template}_LeadGeneration`).default
  : null;

export type LeadGenProps = {
  emailSharedCoverage: boolean;
  email: string;
  submitClick: boolean;
  leadContent?: any;
  submitBtnClick?: boolean;
  updateEmailSharedStorage: () => {};
  submitHandler: (e: Event) => any;
  emailHandler: () => {};
  helperTextHandler: () => any;
};

export const LeadGeneration = () => {
  const dispatch = useAppDispatch();
  const [emailSharedCoverage, setemailSharedCoverage] = useState(false);
  const [submitClick, setsubmitClick] = useState(false);
  const [submitBtnClick, setSubmitBtnClick] = useState(false);
  const [email, setEmail] = useState("");
  const { getInTouch, getInTouchError } = useAppSelector(
    (state: any) => state.homepage
  );
  const { leadGenContent } = useAppSelector(
    (state: any) => state.strapi.homepage
  );
  const [cacheDataLead, setCacheDataLead] = useState<any>(null);
  const updateEmailSharedStorage = (val: boolean) => {
    setemailSharedCoverage(val);
  };
  const submitHandler = (e: any) => {
    setSubmitBtnClick(true);
    if (validateEmail(email)) {
      setsubmitClick(true);
      updateToHubspot(e, email);
      dispatch(
        getInTouchApi({
          data: {
            emailId: email,
            hubspotMap: {
              email: email,
              is_test: EnvConfig?.IS_HUBSPOT_TEST_ENV,
              is_qa: EnvConfig?.IS_HUBSPOT_QA_ENV,
            },
          },
        })
      );
    }
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.leadGeneration,
      buttonValue: leadGenContent?.button_text,
    });
    gtmTagManager({
      event: gaCategory.leadGeneration,
      email_address_submitted: email,
    });
  };
  const emailHandler = (e: any) => {
    setEmail(e.target.value);
  };

  const helperTextHandler = () => {
    let content: any = cacheDataLead ? cacheDataLead : leadGenContent;
    if (validateEmail(email)) setSubmitBtnClick(false);
    if (email === "") {
      return content?.email_error1;
    }
    return email !== "" && !validateEmail(email) ? content?.email_error2 : "";
  };

  useEffect(() => {
    getCache("LeadGenHome", leadGenerationContent, setCacheDataLead, dispatch);
    dispatch(resetGetInTouch());
    return () => {
      dispatch(resetGetInTouch());
    };
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("LeadGenHome", leadGenContent);
  }, [leadGenContent]);

  useEffect(() => {
    if (!isEmpty(getInTouch) && getInTouch.data.status === "SUCCESS") {
      setEmail("");
      setsubmitClick(false);
      setemailSharedCoverage(true);
    } else {
      setEmail("");
      setsubmitClick(false);
      setemailSharedCoverage(false);
    }
  }, [getInTouch]);

  useEffect(() => {
    if (!isEmpty(getInTouchError)) {
      setEmail("");
      setsubmitClick(false);
    }
  }, [getInTouchError]);
  return (
    (leadGenContent || cacheDataLead) && (
      <LeadGenerationComponent
        emailSharedCoverage={emailSharedCoverage}
        submitClick={submitClick}
        email={email}
        leadContent={cacheDataLead ? cacheDataLead : leadGenContent}
        updateEmailSharedStorage={updateEmailSharedStorage}
        submitHandler={submitHandler}
        emailHandler={emailHandler}
        helperTextHandler={helperTextHandler}
        submitBtnClick={submitBtnClick}
      />
    )
  );
};
