import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";
import { getDataUsageContent } from "../../../../features/strapi/postActivationHomeSlice";
import { CustomerStatus } from "../../../../enums";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../../utils/commonFunctions/getCacheFunction";
import { addMultipleCacheData } from "../../../../utils/commonFunctions/addMultipleCacheData";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import EnvConfig from "../../../../config/env.config.json";
import { Disconnected } from "../../../Disconnected";
import { UserStatusNotifications } from "../../../common";
import { DataUsageFailure } from "../../../widgets";

const DataUsageComponent = isTemplateExisits(
  Config.PostActivationHome.DataUsage
)
  ? require(`./${Config.PostActivationHome.DataUsage.template}_DataUsage`)
      .default
  : null;

export type dataUsageProps = {
  content?: any;
};

export const DataUsage = () => {
  const dispatch = useAppDispatch();

  const [cacheContent, setCacheContent] = useState<any>(null);
  const { dataUsage } = useAppSelector(
    (state: any) => state.strapi.postActivation
  );

  useEffect(() => {
    getCache("DataUsageHome", getDataUsageContent, setCacheContent, dispatch);
  }, []);

  useNonInitialEffect(() => {
    addMultipleCacheData("DataUsageHome", dataUsage);
  }, [dataUsage]);

  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const {customerBillCycleDataUsage,errorCustomerBillCycleDataUsage} = useAppSelector((state:any) => state.customer);
  useEffect(() => {
    fetchCustomerDetails();
  }, []);
  const fetchCustomerDetails = () => {
    let loggedUser: any = JSON.parse(localStorage.getItem("currentuser")|| "null");
    if (loggedUser?.email) {
      dispatch(GetCustomer({ username: encodeURIComponent(loggedUser.email) }));
    }
  };

  return (
    <>
      <UserStatusNotifications />
      {getCustomer?.status === CustomerStatus.ACTIVE ||
      (EnvConfig.IS_ALLOWED_TO_DISPLAY_DATA_USAGE &&
        getCustomer?.status === CustomerStatus.SUSPENDED) ||
      getCustomer?.status === CustomerStatus.HOTLINED ||
      getCustomer?.status === CustomerStatus.REJECTED ||
      getCustomer?.status === CustomerStatus.DISABLED ?
        (
          <DataUsageComponent
            content={cacheContent ? cacheContent : dataUsage}
          />
        )
        : EnvConfig.SUSPENDED_DISCONNECTED_LINE_TEXT &&
        (getCustomer?.status === CustomerStatus.DISCONNECTED ||
          getCustomer?.status === CustomerStatus.SUSPENDED) ? (
        <Disconnected content={cacheContent ? cacheContent : dataUsage} />
      ) : null}
    </>
  );
};
