import { styled, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

export const CustToolTip_T2 = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--white)",
    color: "var(--white)",
    // maxWidth: "285px",
    boxShadow: theme.shadows[1],
    padding: "0px",
    borderRadius: "5px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&::before": {
      backgroundColor: "var(--white)", 
      boxShadow: "rgba(0,0,0,0.8)",
      fontSize: "17px",
      borderRadius: "none",
    },
  },
}));
