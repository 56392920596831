import { Box } from "@mui/material";
import styles from "./LinesTab.module.scss";
import { LazyLoadImageCust } from "../../widgets";
import EnvConfig from "../../../config/env.config.json";
import { RoutingURLs } from "../../../config/RoutingURLs";
type Props = {
  value: any;
  handleChange: any;
  totalCount: number;
  linesTabContent?: any;
};

export const LinesTab = (props: Props) => {
  const { value, handleChange, totalCount, linesTabContent } = props;
  const list: any = Array.from(Array(totalCount).keys());
  const altText = (index: any, imagesrc?: any) => {
    if (imagesrc)
      return (
        linesTabContent && linesTabContent[index]?.image?.data?.attributes.url
      );
    return linesTabContent && linesTabContent[index]?.alt_image_text;
  };
  const { tab_component, tab_div, tab_detail_active, tab_detail } = styles;

  const displayLinesTab = () => {
    if (EnvConfig?.IS_LINES_TAB_REQD_IN_HOME_PAGE) return true;
    else {
      if (location.pathname.includes(RoutingURLs.cellPhonePlans)) return true;
      else return false;
    }
  };

  return (
    <>
      {displayLinesTab() && (
        <Box
          sx={{
            mx: "auto",
            mt: linesTabContent?.length > 0 ? 5.7 : "15px",
            // px: 3.8,
            py: 1,
            borderRadius: 1,
            width: "fit-content",
            display: "flex",
          }}
          className={tab_component}
        >
          {list.map((number: number) => {
            return (
              <Box
                key={number}
                className={tab_div}
                onClick={() => handleChange(number + 1)}
              >
                {altText(number, "imagesrc") ? (
                  <LazyLoadImageCust
                    src={altText(number, "imagesrc")}
                    alt={altText(number)}
                    style={{
                      position: "absolute",
                      top: "-52px",
                      display: value !== number + 1 ? "none" : "",
                      width: "auto",
                      height: "45px",
                    }}
                  />
                ) : null}

                <Box
                  sx={{
                    p: 0.25,
                    fontFamily: "var(--font_family_Medium)",
                    fontSize: "14px",
                  }}
                  className={
                    value === number + 1 ? tab_detail_active : tab_detail
                  }
                >
                  {number + 1}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};
