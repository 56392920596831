import { Box, Typography } from "@mui/material";
import styles from "./S1_updateCardDialog.module.scss";
import { useEffect, useState } from "react";
import {
  ButtonCust,
  SimpleModal,
  NotificationCust,
  TextFieldCust,
} from "../../../widgets";
import { useNonInitialEffect } from "../../../../utils/commonFunctions/UseNonInitialEffectHook";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { manageCardsData } from "../../../../features/billing/billingSlice";
import EnvConfig from "../../../../config/env.config.json";

type Props = {
  display: boolean;
  closeHandler?: any;
  dialogData?: any;
  cardData?: any;
};
export const UpdateCardDialog = (props: Props) => {
  const { display, closeHandler, cardData, dialogData } = props;
  const [updateExpiry, setUpdateExpiry] = useState<any>("");
  const dispatch = useAppDispatch();
  const { creditCardData, updateCardExpiry, errorUpdateCardExpiry } =
    useAppSelector((state: any) => state.billing);
  useEffect(() => {
    setUpdateExpiry(
      `${cardData?.dateOfExpiry?.slice(0, 2)}/${cardData?.dateOfExpiry?.slice(
        2
      )}`
    );
  }, [cardData]);
  useNonInitialEffect(() => {
    if (errorUpdateCardExpiry?.status === "FAILURE") {
      NotificationCust({
        message: errorUpdateCardExpiry?.message,
        type: "danger",
        duration: 3000,
        id: "UpdateCardExpiryFailure",
        showIcon: true,
      });
    } else if (updateCardExpiry) {
      dialogData?.exp_updte_notifi &&
        NotificationCust({
          message: dialogData?.exp_updte_notifi,
          type: "info",
          duration: 3000,
          id: "UpdateCardExpirySuccess",
          showIcon: true,
        });
    }
    dispatch(
      manageCardsData({
        ...creditCardData,
        errorExpiryDate: "",
        isUpdateCard: false,
        ccuuid: null,
        expiryDate: null,
      })
    );
    closeHandler();
  }, [errorUpdateCardExpiry, updateCardExpiry]);
  const handleChange = (e: any) => {
    let val;
    if (/^[0-9/]+$/.test(e.target.value) || e.target.value === "") {
      val =
        e.target.value.length > 2 && /^[0-9]+$/.test(e.target.value)
          ? e.target.value.slice(0, 2) + "/" + e.target.value.slice(2)
          : e.target.value;
      setUpdateExpiry(val);
      let date: string = val.replace(/[^0-9]/, "");
      if (date.length === 4) {
        validateCardExpiry(val);
      } else {
        dispatch(manageCardsData({ ...creditCardData, errorExpiryDate: "" }));
      }
    }
  };
  const validateCardExpiry = (val: any) => {
    let date: string = val.replace(/[^0-9]/, "");
    if (date.length === 4) {
      let month = parseInt(date && date.slice(0, 2));
      let year = parseInt(date && date.slice(2));
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = parseInt(
        new Date().getFullYear().toString().substr(-2)
      );
      if (
        (year === currentYear && month <= currentMonth) ||
        year < currentYear
      ) {
        dispatch(
          manageCardsData({
            ...creditCardData,
            errorExpiryDate: dialogData?.expiry_date_err,
          })
        );
      } else if (month < 1 || month > 12) {
        dispatch(
          manageCardsData({
            ...creditCardData,
            errorExpiryDate: dialogData?.expiry_date_err,
          })
        );
      }
    }
  };
  const handleUpdate = () => {
    if (creditCardData?.errorExpiryDate === "") {
      const expiryDate = updateExpiry.replace(/[^0-9]/, "");
      if (expiryDate?.length !== 4) {
        dispatch(
          manageCardsData({
            ...creditCardData,
            errorExpiryDate: dialogData?.expiry_date_err,
          })
        );
      } else {
        dispatch(
          manageCardsData({
            ...creditCardData,
            errorExpiryDate: "",
            isUpdateCard: true,
            ccuuid: cardData?.uuid,
            expiryDate: expiryDate,
          })
        );
      }
    }
  };
  const closeDialog = () => {
    setUpdateExpiry(
      `${cardData?.dateOfExpiry?.slice(0, 2)}/${cardData?.dateOfExpiry?.slice(
        2
      )}`
    );
    closeHandler();
  };

  return (
    <>
      <SimpleModal
        isShowModal={display}
        onCloseModal={closeDialog}
        className={styles.update_card_dialog}
        showClose={true}
        sx={{
          width: "500px !important",
          borderRadius: "4.8px",
          maxWidth: { xs: "95% !important", sm: "500px !important" },
          padding: {
            xs: "25px 25px 25px !important",
            sm: "25px 50px 25px !important",
          },
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={"100%"}
        >
          <Typography className={styles.update_card_heading}>
            {dialogData?.update_card}
          </Typography>
          {creditCardData?.errorExpiryDate && (
            <Typography
              sx={{
                textAlign: "center",
                color: "var(--danger)",
                fontSize: "12px",
                fontFamily: "var(--font_family_Medium)",
                lineHeight: " 24px",
                mb: "15px",
              }}
            >
              {creditCardData?.errorExpiryDate}
            </Typography>
          )}
          <Box width={"100%"}>
            <TextFieldCust
              className={styles.text_field1}
              value={`${dialogData?.num_mask}${cardData?.ccNumberMask}`}
              label={dialogData?.field_labels?.card_num}
              disabled
              fullWidth={true}
            />
            <TextFieldCust
              className={styles.text_field1}
              value={cardData?.ccNumberMask}
              label={dialogData?.field_labels?.name}
              disabled
              fullWidth={true}
            />
            <TextFieldCust
              className={styles.text_field}
              value={updateExpiry}
              label={dialogData?.field_labels?.exp_date}
              fullWidth={true}
              onChange={handleChange}
              maxlength={/[^0-9]/.test(updateExpiry) ? 5 : 4}
            />
            <TextFieldCust
              className={styles.text_field1}
              value={cardData && "***"}
              label={dialogData?.field_labels?.cvv}
              disabled
              fullWidth={true}
            />
          </Box>
          <ButtonCust
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            loading={creditCardData?.isUpdateCard}
            sx={{
              height: "45px !important",
              padding: "13px 23px",
              width: "250px",
            }}
            onClick={handleUpdate}
            disabled={creditCardData?.errorExpiryDate !== ""}
          >
            {dialogData?.update_btn}
          </ButtonCust>
        </Box>
      </SimpleModal>
    </>
  );
};
